<template>
    <div>
      <dynamic-chart v-show="showChart" :chart-option="option" :chart-width="'1000px'" :chart-height="'500px'" ></dynamic-chart>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import DynamicChart from '../../../components/charts/DynamicChart.vue';
  
  export default {
    components: {
      DynamicChart
    },
    data() {
      return {
        option: {},
        showChart: false
      }
    },
    created() {
      this.fetchData();
    },
    methods: {
      fetchData() {
        const API_URL = process.env.VUE_APP_API_BASE_URL + `/dashboard/grafico-va-cnae-6-meses`;
        axios.get(API_URL)
          .then(response => {
            if (response.data) {
              this.createBarChart(response.data);
              this.showChart = true;
            }
          })
          .catch(error => {
            console.error('Erro ao obter os dados:', error);
          });
      },
      createBarChart(data) {
        var xAxisData = Object.keys(data);
        var seriesData = [];

        Object.values(data).forEach(items => {
            items.forEach(item => {
            if (!seriesData.some(series => series.name === item.secao)) {
                seriesData.push({
                name: item.desc_secao,
                sigla: item.secao,
                type: 'bar',
                data: new Array(xAxisData.length).fill(0)
                });
            }
            });
        });

        xAxisData.forEach((date, index) => {
            Object.values(data[date]).forEach(item => {
            const seriesIndex = seriesData.findIndex(series => series.sigla === item.secao);
            seriesData[seriesIndex].data[index] = item.valoradicionado;
            });
        });

        this.option = {
            xAxis: {
              type: 'category',
              data: xAxisData
            },
            yAxis: {
              type: 'value'
            },
            legend: {
              bottom: 0,
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'shadow'
              }
            },
            series: seriesData,
            title : {
              text: 'Valor Adicionado por Seção CNAE nos últimos 6 meses',
              x:'center'
            }
        };
        }

    }
  };
  </script>
  