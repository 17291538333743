import Swal from "sweetalert2";

export default {
  methods: {
    showSwal(options) {
      const typeToClass = {
        success: "bg-success",
        danger: "bg-danger",
        info: "bg-info",
        warning: "bg-warning",
        primary: "bg-primary",
        secondary: "bg-secondary",
        dark: "bg-dark",
        light: "bg-light",
      };
      const customClass = typeToClass[options.type] || "bg-danger";

      new Swal({
        toast: true,
        position: "top-right",
        iconColor: "white",
        width: options.width ? options.width : 300,
        text: options.message,
        customClass: {
          popup: customClass,
          htmlContainer: "text-white",
        },
        showConfirmButton: false,
        showCloseButton: true,
        timer: options.timer ? options.timer : 3000,
        timerProgressBar: true,
      });
    },
    showSwalConfirmationDelete() {
      const swalDelete = new Swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      });

      return swalDelete;
    },
  },
};
