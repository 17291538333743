<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="w-100"> 
                <div class="row">
                    
                    <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 div-filter">
                        <SoftDropdown2 v-model="selected.contribuinte" :placeholderText="'Selecione um Contribuinte'" :minCharacters="4" :columnFilter="'empresa'" :rota="'/arquivos/select2Empresas'"/>
                    </div> 
                    <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 div-filter">
                        <SoftDropdown2 v-model="selected.cnae" :placeholderText="'CNAE'" :minCharacters="3" :columnFilter="'cnae_completo'" :rota="'/cnae/select2_personalizado'"/>
                    </div>   
                </div>
                <br>
                <div class="row">
                    <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-3 div-filter">
                        <SoftDropdown v-model="selected.competencia_ano" :placeholderText="'Competência'" :data="dataCompetencia"></SoftDropdown>
                    </div>
                
                  <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                    <div class="d-sm-block d-flex justify-content-center">
                      <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="filtrar">Filtrar</button>
                    </div>
                  </div>
                </div>
                <AddModal @update-dad="filtrar" :corClass="corDinamica"/>
              </div>
              <div class="pb-0 card-body position-relative">
                <div v-if="isLoading" class="loading_gif">
                  <img src="../assets/img/loading.gif" >
                </div>
                <TableComponent v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading" :tableName="tableName" :columns="columns" :data="data" :corClass="corDinamica"
                   :nome_filtros="nome_filtros" :filtros="filtros" :key="chave" :preHiddenColumns="hiddenColumns"/>
                  <div v-if="data.length === 0" >
                    <h3 class="text-center">Buscando dados</h3>
                    <h3 class="text-center">Com esses filtros</h3>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
    
  <script>
  import axios from 'axios';
  import TableComponent from "./components/DataTables";
  import AddModal from "./components/ModalsAdd/acoesAdd";
  import SoftDropdown from "../components/SoftDropdown";
  import SoftDropdown2 from "../components/SoftDropdown2";
  import permissionsmixin from '../mixins/permissions.js';
  import verificacoesmixin from '../mixins/verificacoes.js';
  import makeTable from '../mixins/makeTable.js';
  
  export default {
    mixins: [permissionsmixin, verificacoesmixin, makeTable],
    name: "ConsultaVAView",
    components: {
        TableComponent,
        AddModal,
        SoftDropdown,
        SoftDropdown2
    },
    data() {
      return {
        tableName: "VALOR ADICIONADO",
        columns: ['Id', 'Competência', 'Cnpj', 'Inscrição Estadual', 'Razão Social', 'Cnae', 'Atividade', 'Saidas', 'Entradas', 'Valor Adicionado'],
        getColumnsData: {
            "Id": "id",
            "Competência": "competencia_ano",
            "Cnpj": "cnpj",
            "Inscrição Estadual": "ie",
            "Razão Social": "razao_social",
            "Cnae": "cnae",
            "Atividade": "denominacao",
            "Saidas": "basecalculo_s",
            "Entradas": "basecalculo_e",
            "Valor Adicionado": "valoradicionado"
        },
        dataCompetencia: [],
        hiddenColumns: [0],
        filtros: {
          competencia_ano: null,
          contribuinte: null,
          cnae: null,
        },
        nome_filtros: {
          competencia_ano: 'Competência',
          contribuinte: 'Contribuinte',
          cnae: 'Cnae'
        },
        selected: {
            competencia_ano: null,
            contribuinte: null,
            cnae: null,
        },
        chave: 0,
        isLoading: true,
      };
    },
    watch: {
      permissoes: {
        handler() {
          this.verificarPermissaoDaPagina('Listar eDIPAMs');
        },
        deep: true,
      },
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
      permissoes() {
        return this.$store.state.permissoesUser;
      },
    },
    methods: {
      stopLoading(){
        this.isLoading = false;
      },
      async filtrar() {
        this.isLoading = true;
        this.filtros.contribuinte = this.obterDadosSelect(this.selected.contribuinte, 'value');
        this.filtros.competencia_ano = this.obterDadosSelect(this.selected.competencia_ano, 'value');
        this.filtros.cnae = this.obterDadosSelect(this.selected.cnae, 'value');
        if (this.filtros.competencia_ano === null) {
            this.showMessage('warning', 'Selecione uma competência!');
            this.isLoading = false;
            return;
        }
        await this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/consulta-va/tabela`, this.filtros, this.getColumnsData);
        this.filtros.contribuinte = this.obterDadosSelect(this.selected.contribuinte, 'text');
        this.filtros.cnae = this.obterDadosSelect(this.selected.cnae, 'text');
      },
      getSelectDataCompetencia() {
        const API_URL = process.env.VUE_APP_API_BASE_URL + `/gia/select2-anos`;
        axios.get(API_URL, {
            params: {
                id_cidade: localStorage.getItem('id_cidade')
            }
        }).then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            this.dataCompetencia.push({text: response.data[i], value: response.data[i]});
          }
        });
      }
    },
    created() {
      if (this.permissoes.length) {
        this.verificarPermissaoDaPagina('Listar eDIPAMs');
      }
      const cor = localStorage.getItem('corDinamica');
      this.$store.commit("atualizarCorDinamica", cor);
      this.verificarPermissoes('Listar eDIPAMs')
    },
    mounted() {
        this.getSelectDataCompetencia();
        this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/consulta-va/tabela`, this.filtros, this.getColumnsData);
    }
  };
  </script>
  