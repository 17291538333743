<template>
    <div>
        <div class="modal fade" id="vincularContadorModalAdd" ref="giaImportModalAdd">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <div class="row">
                <div class="col-11">
                    <h5 class="modal-title-perfil">Vincular Contador</h5>
                </div>
                <div class="col-1">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Fechar">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 div-filter">
                    <SoftDropdown2 v-model="object.cnpj" :placeholderText="'CNPJ'" :minCharacters="3" :columnFilter="'cnpj'" :rota="'/contador/select2'" ref="cnpjDropdown"/>
                </div>
                
            </div>
            <div class="modal-footer">
                <soft-button class="my-4 mb-2" variant="gradient" color="secondary" data-bs-dismiss="modal">
                <span>Cancelar</span>
                </soft-button>
                <div style="width: 110px;">
                <soft-button class="my-4 mb-2" variant="gradient" :color="corClass" full-width @click="Vincular()"
                    :is-disabled="loading ? true : false">
                    <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                    <span v-else>Vincular</span>
                </soft-button>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </template>
        
    <script>
    import SoftButton from '../../../components/SoftButton.vue';
    import axios from "axios";
    import verificacoesmixin from '../../../mixins/verificacoes.js';
    import SoftDropdown2 from '../../../components/SoftDropdown2.vue';
    
    export default {
        mixins: [verificacoesmixin],
        name: 'VincularContadorView',
        props:{
            corClass: String
        },
        data() {
        return {
            loading: false,
            object: {
                cnpj: null,
            },
            selected: {
                cnpj: null,
            },
        };
        },
        components: {
            SoftButton,
            SoftDropdown2
        },
        methods: {
            Vincular() {
                this.loading = true;
                this.selected.cnpj = this.obterDadosSelect(this.object.cnpj, 'value');
                
                const apiUrl = process.env.VUE_APP_API_BASE_URL + '/rel_contribuinte_contador/adicionar';
                axios
                .post(apiUrl, {
                    contador: this.selected.cnpj,
                    contribuinte: localStorage.getItem('login'),
                    id_cidade: localStorage.getItem('id_cidade'),
                })
                .then(response => {
                    if (response.data.success){
                        this.clearUpData();
                        $('#vincularContadorModalAdd').modal('hide');
                        this.showMessage('success', response.data.message);
                        this.$emit('update-dad');
                        } else {
                        this.showMessage('error', response.data.message);
                    }
                })
                .catch(error => {
                    this.showMessage('error', error.response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                });
            },
            clearUpData() {
                this.selected = {
                    cnpj: null,
                };
                this.object = {
                    cnpj: null,
                };
            },
        
        }
    };
    </script>
        
        