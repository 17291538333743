<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="w-100">
                <div class="row">
                    <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 div-filter">
                        <SoftDropdown2 v-model="object.cfop_entrada" :placeholderText="'CFOP de Entrada'" :minCharacters="4" :columnFilter="'cfop_entrada'" :rota="'/cfop/select2_personalizado/entrada'"/>
                    </div> 
                    <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 div-filter">
                        <SoftDropdown2 v-model="object.cfop_saida" :placeholderText="'CFOP de Saida'" :minCharacters="4" :columnFilter="'cfop_saida'" :rota="'/cfop/select2_personalizado/saida'"/>
                    </div>                     
                </div>
                <br>
                <div class="row">
                    <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-5 div-filter">
                        <SoftDropdown2 v-model="object.contribuinte" :placeholderText="'Contribuinte'" :minCharacters="4" :columnFilter="'empresa'" :rota="'/arquivos/select2Empresas'"/>
                    </div> 
                    <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <SoftDropdown v-model="object.exercicio" :placeholderText="'Exercício'" :data="[{text: '2024', value: 2024}, {text: '2023', value: 2023}, {text: '2022', value: 2022}, {text: '2021', value: 2021}, {text: '2020', value: 2020}, {text: '2019', value: 2019}]" :showClear="false"/>
                    </div>
                    <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                      <div class="d-sm-block d-flex justify-content-center">
                        <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="gerarRelatorio">Gerar Relatório</button>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
      
  <script>
    import axios from 'axios';
    import DataTables from './components/DataTables';
    import permissionsmixin from '../mixins/permissions.js';
    import SoftDropdown from '../components/SoftDropdown.vue';
    import SoftDropdown2 from '../components/SoftDropdown2.vue';
    import verificacoesmixin from '../mixins/verificacoes.js';
  
    export default {
      mixins: [verificacoesmixin],
      name: "logsView",
      components: {
        SoftDropdown,
        SoftDropdown2,
      },
      data() {
        return {
          object: {
            contribuinte: null,
            exercicio: {text: '2024', value: 2024},
            cfop_entrada: null,
            cfop_saida: null,
          },
          selected: {
            contribuinte: '',
            exercicio: '',
            cfop_entrada: '',
            cfop_saida: '',
          },
          text: {
            contribuinte: '',
            cfop_entrada: '',
            cfop_saida: '',
          },
        };
      },
      computed: {
        corDinamica() {
          return this.$store.state.corDinamica;
        },
      },
      methods: {
        async gerarRelatorio() {
          
          const user = localStorage.getItem('nome');
          const id_cidade = localStorage.getItem('id_cidade');
          const API_URL = process.env.VUE_APP_API_BASE_URL + `/gerar_relatorio_va_cfop`;
          this.selected.contribuinte = this.obterDadosSelect(this.object.contribuinte, 'value');
          this.selected.exercicio = this.obterDadosSelect(this.object.exercicio, 'value');
          this.selected.cfop_entrada = this.obterDadosSelect(this.object.cfop_entrada, 'value');
          this.selected.cfop_saida = this.obterDadosSelect(this.object.cfop_saida, 'value');
          this.text.contribuinte = this.obterDadosSelect(this.object.contribuinte, 'text');
          this.text.cfop_entrada = this.obterDadosSelect(this.object.cfop_entrada, 'text');
          this.text.cfop_saida = this.obterDadosSelect(this.object.cfop_saida, 'text');
          try {
              const response = await axios.post(API_URL, {
                user: user,
                id_cidade: id_cidade,
                contribuinte: this.selected.contribuinte,
                exercicio: this.selected.exercicio,
                cfop_entrada: this.selected.cfop_entrada,
                cfop_saida: this.selected.cfop_saida,
                text_contribuinte: this.text.contribuinte,
                text_cfop_entrada: this.text.cfop_entrada,
                text_cfop_saida: this.text.cfop_saida,
                }, {
                  responseType: 'arraybuffer',
              });
              const blob = new Blob([response.data], { type: 'application/pdf' });
              const url = URL.createObjectURL(blob);
              window.open(url, 'filename');

          } catch (error) {
            this.showMessage('danger', 'Erro ao gerar relatório!');
          }
        },
      },
      created() {
        const cor = localStorage.getItem('corDinamica');
        this.$store.commit("atualizarCorDinamica", cor);
      },
    };
  </script>
