<template>
  <!-- Navbar -->
  <nav
    class="top-0 navbar navbar-expand-lg position-absolute z-index-3"
    :class="isBlur ? isBlur : 'shadow-none my-2 navbar-transparent w-100'"
  >
    <div class="container">
      <router-link
        class="navbar-brand font-weight-bolder ms-lg-0 ms-3"
        to="/usuario/usuarios"
        v-bind="$attrs"
        :class="isBlur ? 'text-dark' : 'text-white'"
      >
        Ascedant
      </router-link>
      <button
        class="navbar-toggler shadow-none ms-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navigation"
        aria-controls="navigation"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon mt-2">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navigation">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <router-link class="nav-link me-2" :to="'/' + path_cidade + '/login'">
              <i
                class="fa fa-user opacity-6 me-1"
                aria-hidden="true"
                :class="isBlur ? 'text-dark' : 'text-white'"
              ></i>
              Login
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link me-2" :to="'/' + path_cidade + '/register'">
              <i
                class="fas fa-user-circle opacity-6 me-1"
                aria-hidden="true"
                :class="isBlur ? 'text-dark' : 'text-white'"
              ></i>
              Cadastrar
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link me-2" :to="'/' + path_cidade + '/password/email'">
              <i
                class="fas fa-key opacity-6 me-1"
                aria-hidden="true"
                :class="isBlur ? 'text-dark' : 'text-white'"
              ></i>
              Recuperar Senha
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
import axios from 'axios';

export default {
  name: "navbarLayout",
  data() {
    return {
      path_cidade: null,
      cidade: [],
    };
  },
  props: {
    btnBackground: String,
    isBlur: String,
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    darkModes() {
      return {
        "text-dark": this.darkMode,
      };
    },
  },
  created(){
    this.path_cidade = this.$route.params.path_cidade;
    if ((localStorage.getItem('path_cidade') == this.path_cidade) && (localStorage.getItem('id_cidade') != null) && (localStorage.getItem('nome_cidade') != null)){
      this.cidade['id'] = localStorage.getItem('id_cidade');
      this.cidade['path'] = localStorage.getItem('path_cidade');
      this.cidade['nome'] = localStorage.getItem('nome_cidade');
    } else {
      if (this.path_cidade) {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + `/cidades/${this.path_cidade}`)
          .then(response => {
            try {
              if (response.data['id'] == null || response.data['id'] == undefined) {
                this.$router.push("/error");
              }
              if (response.data['path'] == null || response.data['path'] == undefined) {
                this.$router.push("/error");
              }
              this.cidade = response.data;
              localStorage.setItem('id_cidade', response.data['id']);
              localStorage.setItem('path_cidade', response.data['path']);
              localStorage.setItem('nome_cidade', response.data['nome']);
            } catch (error) {
              
              this.$router.push("/error");
            }
          })
          .catch(error => {
            
            this.$router.push("/error");
          });
      }
    }
  }
};
</script>
