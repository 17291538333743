<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card ">
            <div class="pb-0 card-header">
             
              <div class="pb-0 card-body position-relative"> 
                <div v-if="isLoading" class="loading_gif">
                  <img src="../../../assets/img/loading.gif" >
                </div>
                <div class="div_table_referencias_empresas">
                  <table id="table_referencias_empresas" class="table table-bordered table-hover" style="width: 100%; font-size: 14px">
                    <thead>
                      <tr>
                        <th style="">Inscrição Estadual</th>
                        <th style="width: 17% !important;">CNPJ</th>
                        <th style="width: 46% !important;">Razão Social</th>
                        <th style="width: 20%;">Situação do Vínculo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="empresa in empresas"
                        :key="empresa.id"
                      >
                        <td><a onclick="rowClick(this)" :value="empresa.cnpj">{{ empresa.inscricao_estadual }}</a></td>
                        <td><a onclick="rowClick(this)" :value="empresa.cnpj">{{ empresa.cnpj }}</a></td>
                        <td><a onclick="rowClick(this)" :value="empresa.cnpj">{{ empresa.razao_social }}</a></td>
                        <td><a onclick="rowClick(this)" :value="empresa.cnpj">{{ empresa.situacao_vinculo }}</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="pb-0 card-header">
             
              <div class="pb-0 card-body position-relative"> 
                
                <div v-if="isLoading2" class="loading_gif">
                  <img src="../../../assets/img/loading.gif" >
                </div>
                <div class="row">
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    <SoftDropdown v-model="object.situacao" :placeholderText="'Situação'" :data="[{text: 'Sucesso', value: 1}, {text: 'Processando', value: 0}, {text: 'Com erro', value: 2}]"/>
                  </div>
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                    <input v-model="filtros.data_inicio" type="date" class="input-filter-dt form-control" id="filtro_data_inicio_logs" name="filtro_data_inicio_logs" placeholder="Data inicial">
                  </div>
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                    <input v-model="filtros.data_fim" type="date" class="input-filter-dt form-control" id="filtro_data_fim_logs" name="filtro_data_inicio_logs" placeholder="Data final">
                  </div>
                  
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                    <div class="d-md-block d-flex justify-content-center">
                      <button :class="['btn-filter', 'btn bg-gradient-' + this.$store.state.corDinamica]" @click="filtrar">Filtrar</button>
                    </div>
                  </div>
                </div> 
                <DataTables v-if="data.length > 0" @update-dad="filtrar()" @is-loading="stopLoading" :tableName="tableName" :CanDowloadArq="CanDowloadArq" :columns="columns" :data="data" :corClass="corDinamica" :filtros="filtros" :nome_filtros="nome_filtros" :key="chave" :CanExportPDF="CanExportPDF"/>
                <div v-if="data.length === 0">
                  <h3 class="text-center">Buscando dados</h3>
                  <h3 class="text-center">Com esses filtros</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
    
<script>
import axios from "axios";
import DataTable from 'datatables.net-dt';
import { DataTable as datatable} from "simple-datatables";
import DataTables from "../DataTables";
import permissionsmixin from '../../../mixins/permissions.js';
import makeTable from '../../../mixins/makeTable.js';
import verificacoesmixin from '../../../mixins/verificacoes.js';
import SoftDropdown from '../../../components/SoftDropdown';
  
  export default {
    mixins: [permissionsmixin, makeTable, verificacoesmixin],
    name: "GiaView",
    components: {
      DataTables,
      SoftDropdown,
    },
    data() {
      return {
        tableName: "GIA",
        columns: ["Arquivo Enviado", "Referência", "Situação", "Tipo", "Data envio", "Hora Envio"],
        getColumnsData: {
          'Id': 'id',
          "Data envio": "data_envio",
          "Hora Envio": "hora_envio",
          "Situação": "situacao",
          "Tipo": "tipo",
          "Arquivo Enviado": "nome_arquivo",
          "Referência": "ref",
        },
        filtros:{
          data_fim: null,
          data_fim: null,
          situacao: null,
        },
        nome_filtros:{
          data_fim: 'Data de Início',
          data_fim: 'Data de Fim',
          situacao: 'Situação',
        },
        object: {
          situacao: null,
        },
        isLoading: true,
        isLoading2: true,
        CanDowloadArq: true,
        empresas: [],
        cnpj: 1,
        CanExportPDF: true,
        
      };
    },
    watch: 
    {
      empresas: {
        handler(newData) {
          if (newData.length > 0) {
            this.createtable();
          }
        },
        deep: true,
      },
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
    },
    methods: {
      stopLoading(){
        this.isLoading2 = false;
      },
      rowClick(element) {
        this.cnpj = element.getAttribute('value')
        this.cnpj = this.cnpj.replace(/[^\d]+/g,'');
        this.filtrar();
      },
      async filtrar() {
        this.isLoading2 = true;
        
        this.filtros.situacao = this.obterDadosSelect(this.object.situacao, 'value');
        this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/gia/arquivos_enviados/tabela`, this.filtros, this.getColumnsData, this.cnpj);
      },
      async empresasVinculadas() {
        try {
          const response = await axios.get(
            process.env.VUE_APP_API_BASE_URL +
              "/gia/get_empresas_vinculadas/" +
              localStorage.getItem("id_usuario"),
            { params: { id_cidade: localStorage.getItem("id_cidade") } }
          );
          this.empresas = response.data;
          this.createtable();
        } catch (error) {
          console.error("Erro ao obter empresas vinculadas:", error);
        }
      },
      async createtable(){
        this.$nextTick(() => {
          let titulo = '<b>Empresas Vinculadas</b>'
          let buttom = `
        <div class="row dt-bottom">
        <div class="col-4">
          <div class="dt-elements dt-select">{select}linhas por página</div>
        </div>
        <div class="col-4">
          <div class="dt-elements dt-info">{info}</div>
        </div>
        <div class="col-4">
          <div class="dt-pager">{pager}</div>
        </div>
        </div> `
          this.dataTableSearch = new datatable('#table_referencias_empresas', {
            searchable: true,
            fixedHeight: true,
            perPage: 5,
            pagination: true,
            labels: {
              placeholder: "Pesquisar...",
              noRows: "Nenhum registro encontrado",
              perPage: "{select} ",
              info: "Mostrando {start} a {end} de {rows} registros",
              noResults: "Nenhum resultado correspondente encontrado",
            },
            layout: {
              top: titulo + "{search}",
              bottom: buttom,
            },
          });
        });
        
        this.isLoading = false;
      }
    },
    created() {
      const cor = localStorage.getItem('corDinamica');
      this.$store.commit("atualizarCorDinamica", cor);
      this.verificarPermissoes('Módulos');
    },
    async mounted() {
        await this.empresasVinculadas();
        this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/gia/arquivos_enviados/tabela`, this.filtros, this.getColumnsData, this.cnpj);
        window.rowClick = this.rowClick;
    },
    beforeUnmount() {
      delete window.rowClick;
    },
  
  };
</script>
  
<style scoped>
input[type=date] {
  text-align: right;
}

input[type="date"]:before {
  color: #999;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0px
}

.dataTable-wrapper .dataTable-container .table tbody tr td {
  padding: 0.3rem 1.5rem;
}

.card_referencias_empresas{
  padding: 0px !important;
}

</style>