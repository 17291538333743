<template>
  <div>
    <div class="w-100">  
      <div class="row">
        <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
          <SoftDropdown2 v-model="selected.cnae_completo" :placeholderText="'Cnae'" :minCharacters="3" :uniqueOptions="true" :columnFilter="'cnae_completo'" :rota="'/consulta_cnae/tabela_cnae/select2'" ref="acaoDropdown"/>
        </div>
        <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
          <SoftDropdown2 v-model="selected.descricao" :placeholderText="'Descrição'" :minCharacters="3" :uniqueOptions="true" :columnFilter="'descricao'" :rota="'/consulta_cnae/tabela_cnae/select2'" ref="acaoDropdown"/>
        </div>
        <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-2 div-filter">
          <SoftDropdown2 v-model="selected.iva_2010" :placeholderText="'IVA 2010'" :minCharacters="1" :uniqueOptions="true" :columnFilter="'iva_2010'" :rota="'/consulta_cnae/tabela_cnae/select2'" ref="acaoDropdown"/>
        </div>
        <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-2 div-filter">
          <SoftDropdown2 v-model="selected.risco" :placeholderText="'Risco'" :minCharacters="0" :uniqueOptions="true" :columnFilter="'risco'" :rota="'/consulta_cnae/tabela_cnae/select2'" ref="acaoDropdown"/>
        </div>
        <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-2 div-filter">
          <div class="d-sm-block d-flex justify-content-center">
            <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="filtrar">Filtrar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-0 card-body position-relative">
      <div v-if="isLoading" class="loading_gif">
        <img src="../../../assets/img/loading.gif" >
      </div>
      <DataTables v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading" :tableName="tableName" :columns="columns" :data="data" :corClass="corDinamica" :filtros="filtros" :nome_filtros="nome_filtros" :key="chave"/>
      <div v-if="data.length === 0">
        <h3 class="text-center">Buscando dados</h3>
        <h3 class="text-center">Com esses filtros</h3>
      </div>
    </div>
  </div>
</template>
  
  <script>
    import makeTable from '@/mixins/makeTable.js';
    import DataTables from "../DataTables";
    import SoftDropdown2 from "@/components/SoftDropdown2";
    import permissionsmixin from '@/mixins/permissions.js';
    import verificacoesmixin from '@/mixins/verificacoes.js';
  
  export default {
    mixins: [permissionsmixin, verificacoesmixin, makeTable],
    name: 'TabelaCnae',
    components: {
      DataTables,
      SoftDropdown2
    },
    data() {
      return {
        tableName: "CNAE",
        columns: ["Código", "Cnae", "Descrição" ,"Risco", "iva_2010"],
        getColumnsData: { 
          "Código": "codigo",
          "Cnae": "cnae_completo",
          "Descrição": "descricao",
          "Risco": "risco",
          "iva_2010": "iva_2010",
        },
        filtros: {
            codigo: null,
            cnae_completo: null,
            descricao: null,
            risco: null,
            iva_2010: null,
          },
          nome_filtros: {
            codigo: 'Código',
            cnae_completo: 'Cnae',
            descricao: 'Descrição',
            risco: 'Risco',
            iva_2010: 'iva_2010',
          },
          selected: {
            cnae_completo: null,
            descricao: null,
            risco: null,
            iva_2010: null,
          },
        chave: 0,
        isLoading: true,
      };
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
    },
    methods: {
      stopLoading(){
        this.isLoading = false;
      },
      async filtrar() {
        this.isLoading = true;
  
        this.filtros.cnae_completo = this.obterDadosSelect(this.selected.cnae_completo, 'value');
        this.filtros.descricao = this.obterDadosSelect(this.selected.descricao, 'value');
        this.filtros.risco = this.obterDadosSelect(this.selected.risco, 'value');
        this.filtros.iva_2010 = this.obterDadosSelect(this.selected.iva_2010, 'value');
  
        await this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/consulta_cnae/tabela_cnae`, this.filtros, this.getColumnsData);
        
      },
    },
    created() {
      const cor = localStorage.getItem('corDinamica');
      this.$store.commit("atualizarCorDinamica", cor);
    },
    mounted() {
      this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/consulta_cnae/tabela_cnae`, this.filtros, this.getColumnsData);
    },
  };
  </script>