<template>
  <sidenav
    v-if="showSidenav"
    :custom-class="color"
    :class="[isTransparent, isRTL ? 'fixed-end' : 'fixed-start']"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >
    <!-- nav -->
    <navbar
      v-if="showNavbar"
      :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :text-white="isAbsolute ? 'text-white opacity-8' : ''"
      :min-nav="navbarMinimize"
    />
    <router-view />
    <app-footer v-show="showFooter" />
    <configurator
      :toggle="toggleConfigurator"
      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
    />
  </main>
  <div class="modal fade" id="login_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-sx" role="document">
      <div class="modal-content">
        <div class="modal-header">

          <div class="row">
            <div class="col-11">
              <h5 class="modal-title-perfil">Login</h5>
            </div>
          </div>

        </div>
        <div class="modal-body">
          <form>
              <div class="col-12 form-group">
                <label for="login_intranet">CPF/CNPJ</label>
                <input type="text" v-maska data-maska="['###.###.###-##', '##.###.###/####-##']" class="form-control" id="login_intranet" name="login_intranet" placeholder="Login" disabled>
              </div>
              <div class=" col-12 form-group">
                <label for="password_intranet">Senha</label>
                <input type="password" class="form-control" id="password_intranet" name="password_intranet" placeholder="Senha">
              </div>      
          </form> 
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="Logout()" data-bs-dismiss="modal">Sair da sessão</button>
          <button type="button" @click="Login()" :class="'btn btn-' + corDinamica">Logar</button>
        </div>
      </div>
    </div>
  </div> 
</template>
<script>
import axios from 'axios';
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState } from "vuex";
import showSwal from "/src/mixins/showSwal.js";
import $ from 'jquery';
window.$ = $;
window.jQuery = $;

export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    Navbar,
    AppFooter,
  },
  data(){
    return{
      expirationTime: 40 * 60000,
      showModal: false,
      user: '',
    }
  },
  computed: {
    ...mapState([
      "isTransparent",
      "isRTL",
      "isNavFixed",
      "isAbsolute",
      "navbarFixed",
      "absolute",
      "color",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
    corDinamica() {
      return this.$store.state.corDinamica;
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    async logoutUser() {
      try {
        await this.$store.dispatch("auth/logout");
      } finally {
        let path_cidade = localStorage.getItem("path_cidade");
        this.$router.push(`/${path_cidade}/login`);
      }
    },
    Login(){
      let login = $('#login_intranet').val()
      let password = $('#password_intranet').val()
      let extranet = 0;
      let id_cidade = localStorage.getItem('id_cidade');
      login = login.replace(/[^\w\s]/gi, '');
      const apiUrl = process.env.VUE_APP_API_BASE_URL + '/login';
      axios
        .post(apiUrl, {
          login,
          password,
          extranet,
          id_cidade
        })
        .then(response => {
          $('.modal').modal('hide')
          localStorage.removeItem("keepmodal");
          showSwal.methods.showSwal({
            type: "success",
            message: "Login efetuado com sucesso!",
            width: 500
          });
        })
        .catch(error => {
          showSwal.methods.showSwal({
            type: "error",
            message: "Login ou senha inválido(s), tente novamente!",
            width: 500
          });
        });
    },
    Logout(){
      this.logoutUser();
      localStorage.removeItem("logout");
      showSwal.methods.showSwal({
        type: "info",
        message: "Sua sessão expirou, faça login novamente.",
        width: 500,
        timer: 5000,
      });
      try{
        localStorage.removeItem("keepmodal");
        $('.modal').modal('hide')
      } catch (error){
      }
    },
    checkTokenExpiration() {
        const currentTime = new Date().getTime();
        const tokenTime = localStorage.getItem('tokenTime');
        if (currentTime - tokenTime > this.expirationTime) {
          let logout = localStorage.getItem('logout');
          if (logout){
            let token = localStorage.getItem('token');
            if (token) {
              if (localStorage.getItem('loginmodal')){
                this.user = localStorage.getItem('user');
                $('#login_intranet').val(this.user);
                $('#password_intranet').val('');
                $('.modal').modal('hide');
                $('#login_modal').modal('show');
                localStorage.setItem('keepmodal', '1')
                localStorage.removeItem('loginmodal')
              } else{
                this.logoutUser();
                localStorage.removeItem("logout");
                showSwal.methods.showSwal({
                  type: "info",
                  message: "Sua sessão expirou, faça login novamente.",
                  width: 500,
                  timer: 5000,
                });
                try{
                   localStorage.removeItem("keepmodal");
                   $('.modal').modal('hide')
                } catch{

                }
               
              }
              
            }
          }
       
        } 
    },
    updateTokenTime() {
      localStorage.setItem('tokenTime', new Date().getTime());
    }
  },
  mounted() {
    let mouseTimer;
    let keep = localStorage.getItem('keepmodal', '1')

    if (keep){
      this.user = localStorage.getItem('user');
      $('password_intranet').val('');
      $('#login_intranet').val(this.user);
      $('#login_modal').modal('show');
      
    }
    
    const resetMouseTimer = () => {
      if (mouseTimer) {
        clearTimeout(mouseTimer);
      }

      mouseTimer = setTimeout( () => {
        localStorage.setItem('loginmodal', 'sim');
      }, this.expirationTime);
    };

    const handleMouseMove = () => {
      this.checkTokenExpiration();
      this.updateTokenTime();
      resetMouseTimer();
    };
    document.addEventListener('mousemove', handleMouseMove);
    
  },
};
</script>

<style>
#sidenav-main {
  overflow: hidden !important;
}
#sidenav-collapse-main {
  overflow-x: hidden !important;
}
.modal-backdrop.show {
  opacity: 0.6 !important;
}
</style>