<template>
    <div>
      <dynamic-chart v-show="showChart" :chart-option="option" :chart-width="'1000px'" :chart-height="'500px'" ></dynamic-chart>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import DynamicChart from '../../../components/charts/DynamicChart.vue';
  
  export default {
    components: {
      DynamicChart
    },
    data() {
      return {
        option: {},
        showChart: false
      }
    },
    created() {
      this.fetchData();
    },
    methods: {
        fetchData() {
            const API_URL = process.env.VUE_APP_API_BASE_URL + `/dashboard/grafico-ranking-empresas`;
            axios.get(API_URL)
                .then(response => {
                if (response.data) {
                    this.createBarChart(response.data);
                    this.showChart = true;
                }
                })
                .catch(error => {
                console.error('Erro ao obter os dados:', error);
                });
            },
        createBarChart(dados) {
            const dadosFormatados = dados.map((item, index) => [
                item.razao_social,
                parseFloat(item.valoradicionado.replace(/\./g, '').replace(',', '.')),
                item.ranking.replace('º', '') + 'º'
            ]);

            this.option = {
                title: {
                    text: 'Ranking de Empresas por Valor Adicionado',
                    subtext: new Date().getFullYear(),
                    left: 'center'
                },
                dataset: [
                    {
                        dimensions: ['razao_social', 'valoradicionado', 'ranking'],
                        source: dadosFormatados
                    },
                    {
                        transform: {
                            type: 'sort',
                            config: { dimension: 'valoradicionado', order: 'asc' }
                        }
                    }
                ],
                yAxis: {
                    type: 'category',
                    axisLabel: { interval: 0, rotate: 30 }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        return '<strong>'+ params[0].value[0] + ':</strong> R$ ' + params[0].value[1];
                    }
                },
                xAxis: {
                    minInterval: 1000000
                },
                series: [{
                    type: 'bar',
                    encode: { y: 'ranking', x: 'valoradicionado' },
                    datasetIndex: 1,
                    label: { 
                        show: true,
                        position: 'insideLeft', 
                        color: '#000',
                        formatter: function (params) {
                            return params.value[0]; 
                        }
                    },
                    itemStyle: {
                        color: 'purple'
                    }
                }]
            };

        },
    }
  };
  </script>
  