// permissions.js
import axios from 'axios'

export default {
  data() {
    return {
      CanAdd: false,
      CanEdit: false,
      CanDelete: false,
      CanDetail: false,
      CanPermission: false,
      CanReativar: false,
      CanHomologar: false,
      CanVincular: false,
    }
  },
  methods: {

    verificarPermissaoDaPagina(paginaDesejada) {

      for (const modulo of this.permissoes) {
        const paginaEncontrada = modulo.paginas.find(pagina => pagina.titulo_pagina === paginaDesejada);
        if (paginaEncontrada) {
          return;
        }
      }
      this.$router.push("/error500");
    },

    verificarPermissoes(paginaName) {
      const perfilId = localStorage.getItem('perfilId')
      const API_URL = process.env.VUE_APP_API_BASE_URL + '/verificar/permissoes/acoes/perfil-acesso/'

      axios.get(API_URL, {
        params: {
          id_perfil: perfilId,
          pagina: paginaName
        }  
      })
      .then(response => {
        for (const permissao of response.data) {
          if (permissao.titulo_acao == 'Adicionar' && permissao.checked) {
            this.CanAdd = true
          }
          if (permissao.titulo_acao == 'Editar' && permissao.checked) {
            this.CanEdit = true
          }
          if (permissao.titulo_acao == 'Excluir' && permissao.checked) {
            this.CanDelete = true
          }
          if(permissao.titulo_acao == 'Ver Detalhes' && permissao.checked == true){
            this.CanDetail = true;
          }
          if (permissao.titulo_acao == 'Permitir Acesso' && permissao.checked == true) {
            this.CanPermission = true;
          }
          if(permissao.titulo_acao == 'Reativar' && permissao.checked == true){
            this.CanReativar = true;
          }
          if(permissao.titulo_acao == 'Homologar' && permissao.checked == true){
            this.CanHomologar = true;
          }
          if(permissao.titulo_acao == 'Vincular' && permissao.checked == true){
            this.CanVincular = true;
          }
        }
      })
      .catch(error => {
        console.error('Erro ao buscar permissões:', error)
      })
    }
  }
}