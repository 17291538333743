import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/auth/signin/Login.vue";
import SendEmail from "../views/auth/reset/SendEmail.vue";
import ResetPassword from "../views/auth/reset/ResetPassword.vue";
import Register from "../views/auth/signup/Register.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import guest from "../middlewares/guest.js";
import auth from "../middlewares/auth.js";

import PerfilAcesso from "@/views/PerfilAcesso.vue";
import Usuarios from "@/views/UsuariosView.vue";
import Acoes from "@/views/AcoesView.vue";
import Contador from "@/views/CadastroContadorView.vue";
import Contribuinte from "@/views/CadastroContribuinteView.vue";
import Modulos from "@/views/ModulosView.vue";
import Paginas from "@/views/PaginasView.vue";
import Logs from "@/views/LogsView.vue";
import GiaContador from "@/views/GiaContadorView.vue";
import GiaContribuinte from "@/views/GiaContribuinteView.vue";
import ConsultaCnae from "@/views/ConsultaCnae.vue";
import ConsultaCfop from "@/views/ConsultaCfop.vue";
import Notificacoes from "@/views/NotificacoesView.vue";
import VincularContribuinte from "@/views/VincularContribuintesView.vue";
import VincularContador from "@/views/VincularContadorView.vue";
import OmissoesEntregaContribuinte from "@/views/OmissoesEntregaContribuinteView.vue";
import OmissoesEntregaContador from "@/views/OmissoesEntregaContadorView.vue";
import OmissoesEntregaAdmin from "@/views/OmissoesEntregaAdminView.vue";
import ValorAdicionadoCFOP from "@/views/ValorAdicionadoCFOPView.vue";
import ValorAdicionadoCNAE from "@/views/ValorAdicionadoCNAEView.vue";
import RankingValorAdicionado from "@/views/RankingValorAdicionadoView.vue";
import ComparativoValorAdicionado from "@/views/ComparativoValorAdicionadoView.vue";
import EmpresasAtividadesConjugadas from "@/views/EmpresasAtividadesConjugadasView.vue";
import IPMMunicipal from "@/views/IPMMunicipalView.vue";
import ListarEdipams from "@/views/ListarEdipamsView.vue";
import ConsultaVA from "@/views/ConsultaValorAdicionadoView.vue";
import Graficos from "@/views/GraficosView.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/usuario/usuarios",
  },
  
  {
    path: "/dashboard/graficos",
    name: "Gráficos",
    component: Graficos,
  },
  {
    path: "/usuario/perfilacesso",
    name: "Perfil de Acesso",
    component: PerfilAcesso,
  },
  {
    path: "/usuario/perfilacesso",
    name: "Perfil de Acesso",
    component: PerfilAcesso,
  },
  {
    path: "/usuario/usuarios",
    name: "Usuários",
    component: Usuarios,
  },
  {
    path: "/sistema/acoes",
    name: "Ações",
    component: Acoes,
  },
  {
    path: "/cadastro/contador",
    name: "Contador",
    component: Contador,
  },
  {
    path: "/cadastro/contribuinte",
    name: "Contribuinte",
    component: Contribuinte,
  },
  {
    path: "/sistema/modulos",
    name: "Módulos",
    component: Modulos,
  },
  {
    path: "/sistema/paginas",
    name: "Páginas",
    component: Paginas,
  },
  {
    path: "/sistema/logs",
    name: "Logs",
    component: Logs,
  },
  {
    path: "/sistema/notificacoes",
    name: "Notificações",
    component: Notificacoes,
  },
  {
    path: "/contador/gia-contador",
    name: "Gia Contador",
    component: GiaContador, 
  },
  {
    path: "/contador/vincular-contribuintes",
    name: "Vincular Contribuintes",
    component: VincularContribuinte, 
  },
  {
    path: "/contribuinte/vincular-contador",
    name: "Vincular Contador",
    component: VincularContador,
  },
  {
    path: "/contribuinte/gia-contribuinte",
    name: "Gia Contribuinte",
    component: GiaContribuinte, 
  },
  {
    path: "/contribuinte/omissoes_entrega",
    name: "Omissões de Entrega Contribuinte",
    component: OmissoesEntregaContribuinte, 
  },
  {
    path: "/contador/omissoes_entrega",
    name: "Omissões de Entrega Contador",
    component: OmissoesEntregaContador, 
  },
  {
    path: "/administrativo/omissoes_entrega",
    name: "Omissões de Entrega Admin",
    component: OmissoesEntregaAdmin, 
  },
  {
    path: "/relatorios/valor_adicionado_cfop",
    name: "Valor adicionado por CFOPs",
    component: ValorAdicionadoCFOP, 
  },
  {
    path: "/relatorios/valor_adicionado_cnae",
    name: "Valor adicionado por CNAEs",
    component: ValorAdicionadoCNAE, 
  },
  {
    path: "/relatorios/ranking_valor_adicionado",
    name: "Ranking de Valor Adicionado",
    component: RankingValorAdicionado, 
  },
  {
    path: "/relatorios/comparativo_valor_adicionado",
    name: "Comparativo de Valor Adicionado",
    component: ComparativoValorAdicionado, 
  },
  {
    path: "/relatorios/empresas_atividades_conjugadas",
    name: "Empresas com Atividades Conjugadas",
    component: EmpresasAtividadesConjugadas, 
  },
  {
    path: "/administrativo/consulta-cnae",
    name: "Consulta de CNAEs",
    component: ConsultaCnae, 
  },
  {
    path: "/administrativo/consulta-cfop",
    name: "Consulta de CFOPs",
    component: ConsultaCfop, 
  },
  {
    path: "/administrativo/ipm-municipal",
    name: "IPM Municipal",
    component: IPMMunicipal, 
  },
  {
    path: "/administrativo/listar-edipams",
    name: "Listar eDIPAMs",
    component: ListarEdipams, 
  },
  {
    path: "/administrativo/consulta-va",
    name: "Consulta Valor Adicionado",
    component: ConsultaVA, 
  },
  {
    path: "/error",
    name: "Error",
    component: Error404,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/error",
  },
  
  {
    path: "/error500",
    name: "Error500",
    component: Error500,
  },
  {
    path: "/:path_cidade?/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/:path_cidade?/register",
    name: "Register",
    component: Register,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/:path_cidade?/password/email",
    name: "SendEmail",
    component: SendEmail,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/:path_cidade?/password/reset",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      middleware: [guest],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});


function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}


router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    to.meta.middleware = [auth];
  }

  const middleware = to.meta.middleware;

  const context = {
    from,
    next,
    router,
    to,
  };
  const nextMiddleware = nextFactory(context, middleware, 1);

  return middleware[0]({ ...context, next: nextMiddleware });
});

export default router;