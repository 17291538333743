<template>
    
      <DropdownSoft v-model="selectedOption"
      :showClear="showClear"
      :options="options"
      optionLabel="text"
      :placeholder="placeholderText"
      :class="'w-full md:w-14rem dropdownsoft2 '+ customClass"/>
   
  </template>
  
<script>

export default {
    name: 'SoftDropdown',
    props: {
        data: {
            type: Array,
            required: true,
        },
        placeholderText: {
            type: String,
            default: 'Selecione uma opção',
        },
        showClear: {
            type: Boolean,
            default: true,
        },
        customClass: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            selectedOption: null,
            options: [],

        };
    },
    watch: {
        selectedOption(newValue) {
            this.$emit('input', newValue);
        },
    },
    mounted() {
        this.options = this.data;
    },
};
</script>
  
<style>
.dropdownsoft2 {
  /* height: 75%; */
  width: 100%;
  border-radius: 0.5rem;;
  background-color: #fff;
  font-size: 0.875rem;
  border-color: #DDE0E3 !important;
  margin-bottom: 3%;
  
}

.dropdownsoft2 .p-dropdown-label.p-inputtext.p-placeholder {
  margin-top: -5px; 
  font-size: 0.875rem;
}
.p-dropdown-items {
    font-size: 0.875rem;
}

.dropdownsoft2 .p-dropdown-label {
    margin-top: -4px;
  font-size: 0.875rem; 
  
}

.ol,
ul {
  padding-left: 0 !important;
}

.dropdown_no_hpercent {
  height: 40px !important;

}


</style>
  