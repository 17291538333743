var gradientColors = {
  "primary": "310deg, #9F55FF, #893E90",
  "secondary": "310deg, #627594, #A8B8D8",
  "success": "310deg, #17ad37, #98ec2d",
  "info": "310deg, #2152ff, #21d4fd",
  "warning": "310deg, #f53939, #fbcf33",
  "danger": "310deg, #ea0606, #ff667c",
  "light": "310deg, #CED4DA, #EBEFF4",
  "dark": "310deg, #141727, #3A416F"
};

export default gradientColors;