<template>
    <div>
      <dynamic-chart v-show="showChart" :chart-option="option" :chart-width="'600px'" :chart-height="'500px'" ></dynamic-chart>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import DynamicChart from '../../../components/charts/DynamicChart.vue';
  
  export default {
    components: {
      DynamicChart
    },
    data() {
      return {
        option: {},
        showChart: false
      }
    },
    created() {
      this.fetchData();
    },
    methods: {
        fetchData() {
            const API_URL = process.env.VUE_APP_API_BASE_URL + `/dashboard/grafico-comparacao-ipm`;
            axios.get(API_URL)
                .then(response => {
                if (response.data.data.length > 4) {
                    this.createBarChart(response.data);
                    this.showChart = true;
                }
                })
                .catch(error => {
                console.error('Erro ao obter os dados:', error);
                });
            },
        createBarChart(dados) {
            this.option = {
                title: {
                    text: 'Evolução do IPM nos últimos 5 anos em %',
                    subtext: 'Média IPM: ' + dados.media_ipm + ' %',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: '<strong>{b0}:</strong> {c0} %'

                },  
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: dados.data.map(item => item.competencia_ano),
                    axisTick: {
                        alignWithLabel: true
                    }
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                {
                    data: dados.data.map(item => item.ipm),
                    type: 'bar',
                    showBackground: true,
                    itemStyle: {
                        color: 'purple',
                    },
                    barWidth: 50,
                    backgroundStyle: {
                    color: 'rgba(220, 220, 220, 0.8)'
                    }
                }
                ]
            };
        },
    }
  };
  </script>
  