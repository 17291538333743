<template>
  <div>
    <div class="w-100">
      <div class="row">
        <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
          <SoftDropdown2 v-model="selected.secao" :placeholderText="'Seção'" :minCharacters="0" :uniqueOptions="true" :columnFilter="'secao'" :rota="'/consulta_cnae/tabela_secao/select2'" ref="acaoDropdown"/>
        </div>
        <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
          <SoftDropdown2 v-model="selected.denominacao" :placeholderText="'Denominação'" :minCharacters="2" :uniqueOptions="true" :columnFilter="'denominacao'" :rota="'/consulta_cnae/tabela_secao/select2'" ref="acaoDropdown"/>
        </div>
        <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
          <div class="d-sm-block d-flex justify-content-center">
            <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="filtrar">Filtrar</button>
          </div>
        </div>
      </div>
    </div> 
    <div class="pb-0 card-body position-relative">
      <div v-if="isLoading" class="loading_gif">
        <img src="../../../assets/img/loading.gif" >
      </div>
      <DataTables v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading" :tableName="tableName" :columns="columns" :data="data" :corClass="corDinamica" :filtros="filtros" :nome_filtros="nome_filtros" :key="chave"/>
      <div v-if="data.length === 0">
        <h3 class="text-center">Buscando dados</h3>
        <h3 class="text-center">Com esses filtros</h3>
      </div>
    </div>  
  </div>
</template>

<script>
  import DataTables from "../DataTables";
  import makeTable from '@/mixins/makeTable.js';
  import SoftDropdown2 from "@/components/SoftDropdown2";
  import permissionsmixin from '@/mixins/permissions.js';
  import verificacoesmixin from '@/mixins/verificacoes.js';

  export default {
    mixins: [permissionsmixin, verificacoesmixin, makeTable],
    name: 'TabelaSecao',
    components: {
      DataTables,
      SoftDropdown2
    },
    data() {
      return {
        tableName: "SEÇÃO",
        columns: ["Código", "Seção", "Denominação"],
        getColumnsData: { 
          "Código": "codigo",
          "Seção": "secao",
          "Denominação": "denominacao",
        },
        filtros: {
          codigo: null,
          secao: null,
          denominacao: null,
        },
        nome_filtros: {
          codigo: 'Código',
          secao: 'Seção',
          denominacao: 'Denominação',
        },
        selected: {
          codigo: null,
          secao: null,
          denominacao: null,
        },
        chave: 0,
        isLoading: true,
      };
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
    },
    methods: {
      stopLoading(){
        this.isLoading = false;
      },
      async filtrar() {
        this.isLoading = true;

        this.filtros.codigo = this.obterDadosSelect(this.selected.codigo, 'value');
        this.filtros.secao = this.obterDadosSelect(this.selected.secao, 'value');
        this.filtros.denominacao = this.obterDadosSelect(this.selected.denominacao, 'value');

        await this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/consulta_cnae/tabela_secao`, this.filtros, this.getColumnsData);
      },
    },
    created() {
      const cor = localStorage.getItem('corDinamica');
      this.$store.commit("atualizarCorDinamica", cor);
    },
    mounted() {
      this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/consulta_cnae/tabela_secao`, this.filtros, this.getColumnsData);
    },
  };
</script>