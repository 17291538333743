<template>       
    <input type="text" class="form-control iconpicker" ref="iconpicker" id="iconpicker_component" name="iconpicker_component" placeholder="Escolha um icone" aria-label="Icone Picker"
      aria-describedby="basic-addon1" autocomplete="off">
</template>

<script>
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import  '/src/assets/js/bootstrapicons-iconpicker.js';
import '/src/assets/css/bootstrapicons-iconpicker.css';

export default {
mounted() {
const iconpickerOptions = {
  title: 'Seletor de Ícones',
  selected: false,
  defaultValue: false,
  collision: "none",
  animation: true,
  hideOnSelect: true,
  showFooter: true,
  searchInFooter: false,
  mustAccept: false,
  fullClassFormatter: function (e) {
    return e;
  },
  input: "input,.iconpicker-input",
  inputSearch: false,
  container: false,
  component: ".input-group-addon,.iconpicker-component",
  templates: {
    popover: '<div class="iconpicker-popover popover" role="tooltip"><div class="arrow"></div>' + '<div class="popover-title"></div><div class="popover-content"></div></div>',
    footer: '<div class="popover-footer"></div>',
    search: '<input type="search" class="form-control iconpicker-search" placeholder="Pesquise um ícone" />',
    iconpicker: '<div class="iconpicker"><div class="iconpicker-items"></div></div>',
    iconpickerItem: '<a role="button" href="javascript:;" class="iconpicker-item"><i></i></a>'
  }
};

$(this.$refs.iconpicker).iconpicker(iconpickerOptions);
}
};
</script>

