<template>
  <!-- -------- START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- -->
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <div class="mx-auto mt-2 mb-4 text-center col-lg-8">
          <a
            href=""
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-facebook"></span>
          </a>
          <a
            href=""
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-twitter"></span>
          </a>
          <a
            href=""
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-instagram"></span>
          </a>
          <a
            href=""
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-github"></span>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
          <p class="mb-0 text-secondary">
            {{ new Date().getFullYear() }}, Ascendant Project {{ versao }} by
            <a
              href="https://iibrasil.com.br/home"
              class="font-weight-bold"
              target="_blank"
              >iiBrasil</a
            >
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios';

export default {
  name: "app-footer",
  data() {
    return {
      versao: null
    };
  },
  mounted() {
    this.versao = localStorage.getItem('versao')
    if (this.versao) return
    const API_URL = process.env.VUE_APP_API_BASE_URL + '/versao';
    axios.get(API_URL)
      .then(response => {
        this.versao = response.data
        localStorage.setItem('versao', this.versao)
      })
      .catch(error => {
        console.error('Erro ao buscar os dados:', error);
      });
  },
};
</script>
