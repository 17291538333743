<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="pb-0 card-header">
                Calcular e Cadastrar IPM
                <div class="pb-0 card-body position-relative">
                    
                    <form>
                        <div class="row">
                            <div class="col-2">
                                <label for="cal_ipm_competencia_ano">Ano de Competência</label>
                                <input type="number" class="form-control" v-model="edipam.competencia_ano" id="cal_ipm_competencia_ano" maxlength="4">
                            </div>
                            <div class="col-3">
                                <label for="cal_ipm_tipo">Tipo</label>
                                <SoftDropdown v-model="object.tipo" :placeholderText="'Selecione um Tipo'" :data="[{text: 'Provisório', value:'P'}, {text: 'Definitivo', value:'D'}]"/>
                            </div>
                            <div class="col-4">
                                <label for="cal_ipm_cidade">Cidade</label>
                                <input type="text" class="form-control" id="cal_ipm_cidade" v-model="edipam.municipio" style="background-color: white" disabled>
                            </div>
                            <div class="col-3">
                                <label for="cal_ipm_data_publicacao">Data de Publicação</label>
                                <input type="date" class="form-control" id="cal_ipm_data_publicacao" v-model="edipam.data_publicacao" style="background-color: white">
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-3">
                                <label for="cal_ipm_va_estado_anterior">Valor Adicionado Estado Anterior (R$)</label>
                                <input type="text" class="form-control" v-model="edipam.va_estado_anterior" id="cal_ipm_va_estado_anterior" @input="maskMoney($event, 'va_estado_anterior')">

                            </div>
                            <div class="col-3">
                                <label for="cal_ipm_va_estado">Valor Adicionado Estado (R$)</label>
                                <input type="text" class="form-control" v-model="edipam.va_estado" id="cal_ipm_va_estado" @input="maskMoney($event, 'va_estado')">
                            </div>
                            <div class="col-3">
                                <label for="cal_ipm_pop_estado">População Estado (IBGE)</label>
                                <input type="text" class="form-control" v-model="edipam.pop_estado" id="cal_ipm_pop_estado" @input="maskNumber($event, 'pop_estado')">
                            </div>
                            <div class="col-3">
                                <label for="cal_ipm_rt_estado">Receita Tributária Estado (R$)</label>
                                <input type="text" class="form-control" v-model="edipam.rt_estado" id="cal_ipm_rt_estado" @input="maskMoney($event, 'rt_estado')">
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label for="cal_ipm_ac_estado">Área Cultivada Estado (ha)</label>
                                <input type="text" class="form-control" v-model="edipam.ac_estado" id="cal_ipm_ac_estado">
                            </div>
                            <div class="col-3">
                                <label for="cal_ipm_ai_estado">Área Inundada Estado (km)</label>
                                <input type="text" class="form-control" v-model="edipam.ai_estado" id="cal_ipm_ai_estado">
                            </div>
                            <div class="col-3">
                                <label for="cal_ipm_va_municipio_anterior">Valor Adicionado Município Anterior (R$)</label>
                                <input type="text" class="form-control" id="cal_ipm_va_municipio_anterior" v-model="edipam.va_municipio_anterior" disabled>
                            </div>
                            <div class="col-3">
                                <label for="cal_ipm_va_municipio">Valor Adicionado Município (R$)</label>
                                <input type="text" class="form-control" v-model="edipam.va_municipio" id="cal_ipm_va_municipio" disabled >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label for="cal_ipm_pop_municipio">População Município (IBGE)</label>
                                <input type="text" class="form-control" v-model="edipam.pop_municipio" id="cal_ipm_pop_municipio" @input="maskNumber($event, 'pop_municipio')">
                            </div>
                            <div class="col-3">
                                <label for="cal_ipm_rt_municipio">Receita Tributária Município (R$)</label>
                                <input type="text" class="form-control" v-model="edipam.rt_municipio" id="cal_ipm_rt_municipio" @input="maskMoney($event, 'rt_municipio')">
                            </div>
                            <div class="col-3">
                                <label for="cal_ipm_ac_municipio">Área Cultivada Município (ha)</label>
                                <input type="text" class="form-control" v-model="edipam.ac_municipio" id="cal_ipm_ac_municipio">
                            </div>
                            <div class="col-3">
                                <label for="cal_ipm_ai_municipio">Área Inundada Município (km)</label>
                                <input type="text" class="form-control" v-model="edipam.ai_municipio" id="cal_ipm_ai_municipio">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label for="cal_ipm_iap_municipio">Índice de Área Protegida Município (%)</label>
                                <input type="text" class="form-control" v-model="edipam.iap_municipio" id="cal_ipm_iap_municipio">
                            </div>
                            <div class="col-3">
                                <label for="cal_ipm_cf_municipio">Componente Fixo Município (%)</label>
                                <input type="text" class="form-control" v-model="edipam.cf_municipio" id="cal_ipm_cf_municipio" disabled>
                            </div>
                            <div class="col-4">
                                <label for="cal_ipm_ipm">ÍNDICE DE PARTICIPAÇÃO DO MUNICÍPIO (IPM  %)</label>
                                <input type="text" class="form-control" v-model="edipam.ipm" id="cal_ipm_ipm" value="Á Calcular" disabled>
                            </div>
                        </div>
                        <div class="row" id="div-btns" style="margin-left: -10px; margin-right: -10px;margin-top: 30px; text-align: left; display: block">
                            <div class="col-sm-3">
                              
                              <soft-button class="my-4 mb-3" variant="gradient" type="button" :color="this.$store.state.corDinamica" full-width @click="calcularIpm()"
                                :is-disabled="isLoading ? true : false">
                                <span v-if="isLoading" class="spinner-border spinner-border-sm"></span>
                                <span v-else>CALCULAR E CADASTRAR IPM</span>
                              </soft-button>
                              
                            </div>

                            <div class="col-sm-1">
                                <button id="btn-reset" type="button" class="btn btn-secondary" @click="clearUpdate">LIMPAR</button>
                            </div>
                        </div>

                    </form> 
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import SoftDropdown from '../components/SoftDropdown.vue';
  import SoftButton from '../components/SoftButton.vue';
  import permissionsmixin from '../mixins/permissions.js';
  import verificacoesmixin from '../mixins/verificacoes.js';
  import makeTable from '../mixins/makeTable.js';
  
  export default {
    mixins: [permissionsmixin, verificacoesmixin, makeTable],
    name: "IPMMunicipal",
    components: {
        SoftDropdown,
        SoftButton,
    },
    data() {
      return {
        object: {
          tipo: null,
        },
        chave: 0,
        isLoading: false,
        // edipam: {
        //   competencia_ano: 2024,
        //   municipio: null,
        //   data_publicacao: '2024-06-01',
        //   va_estado_anterior: 'R$ 9.442.872.388,43',
        //   va_estado: 'R$ 9.801.246.005,49',
        //   pop_estado: '41.262.999',
        //   rt_estado: 'R$ 528.973.226,19',
        //   ac_estado: '17807257.12',
        //   ai_estado: '5489.33',
        //   va_municipio_anterior: 'Á Calcular',
        //   va_municipio: 'Á Calcular',
        //   pop_municipio: '31.253.503',
        //   rt_municipio: 'R$ 290.462.562,15',
        //   ac_municipio: '2404.07',
        //   ai_municipio: '37.27',
        //   iap_municipio: '23.18695',
        //   cf_municipio: "0.00310077 %",
        //   id_cidade: localStorage.getItem('id_cidade'),
        //   ipm: 'Á Calcular',
        //   tipo: "P",
        // },
        edipam: {
          competencia_ano: null,
          municipio: null,
          data_publicacao: null,
          va_estado_anterior: null,
          va_estado: null,
          pop_estado: null,
          rt_estado: null,
          ac_estado: null,
          ai_estado: null,
          va_municipio_anterior: 'Á Calcular',
          va_municipio: 'Á Calcular',
          pop_municipio: null,
          rt_municipio: null,
          ac_municipio: null,
          ai_municipio: null,
          iap_municipio: null,
          cf_municipio: "0.00310077 %",
          ipm: 'Á Calcular',
          id_cidade: localStorage.getItem('id_cidade'),//
          tipo: null,
        }
      };
    },
    watch: {
      permissoes: {
        handler() {
          this.verificarPermissaoDaPagina('Ações');
        },
        deep: true,
      },
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
      permissoes() {
        return this.$store.state.permissoesUser;
      },
    },
    methods: {
      async calcularIpm() {
        this.isLoading = true;

        this.edipam.tipo = this.obterDadosSelect(this.object.tipo, 'value');

        const requiredFields = [
          { field: 'competencia_ano', label: 'Ano de Competência' },
          { field: 'tipo', label: 'Tipo' },
          { field: 'data_publicacao', label: 'Data de Publicação' },
          { field: 'va_estado_anterior', label: 'Valor Adicionado Estado Anterior' },
          { field: 'va_estado', label: 'Valor Adicionado Estado' },
          { field: 'pop_estado', label: 'População Estado' },
          { field: 'rt_estado', label: 'Receita Tributária Estado' },
          { field: 'ac_estado', label: 'Área Cultivada Estado' },
          { field: 'ai_estado', label: 'Área Inundada Estado' },
          { field: 'pop_municipio', label: 'População Município' },
          { field: 'rt_municipio', label: 'Receita Tributária Município' },
          { field: 'ac_municipio', label: 'Área Cultivada Município' },
          { field: 'ai_municipio', label: 'Área Inundada Município' },
          { field: 'iap_municipio', label: 'Índice de Área Protegida Município' },
        ];

        for (const fieldData of requiredFields) {
          const fieldValue = this.edipam[fieldData.field];
          if (!fieldValue || fieldValue === 0 || fieldValue === '') {
            this.showMessage('danger', `O campo ${fieldData.label} é obrigatório!`);
            this.isLoading = false;
            return;
          }
        }

        const edipamData = {
          competencia_ano: this.edipam.competencia_ano,
          municipio: this.edipam.municipio,
          data_publicacao: this.edipam.data_publicacao,
          va_estado_anterior: this.unMask(this.edipam.va_estado_anterior),
          va_estado: this.unMask(this.edipam.va_estado),
          pop_estado: this.unMask(this.edipam.pop_estado),
          rt_estado: this.unMask(this.edipam.rt_estado),
          ac_estado: this.edipam.ac_estado,
          ai_estado: this.edipam.ai_estado,
          va_municipio_anterior: this.edipam.va_municipio_anterior,
          va_municipio: this.edipam.va_municipio,
          pop_municipio: this.unMask(this.edipam.pop_municipio),
          rt_municipio: this.unMask(this.edipam.rt_municipio),
          ac_municipio: this.edipam.ac_municipio,
          ai_municipio: this.edipam.ai_municipio,
          iap_municipio: this.edipam.iap_municipio,
          cf_municipio: this.edipam.cf_municipio,
          ipm: this.edipam.ipm,
          id_cidade: this.edipam.id_cidade,
          tipo: this.edipam.tipo,
        };

        const apiUrl = process.env.VUE_APP_API_BASE_URL + '/ipm_municipal/cadastrar';
          axios.post(apiUrl,{
            edipam: edipamData,
            id_cidade: localStorage.getItem('id_cidade'),
            user: localStorage.getItem('user'),
        })
        .then(response => {
          if (response.data.status == true){
            this.edipam.ipm = response.data.ipm;
            this.maskMoney(null, 'va_municipio_anterior', response.data.va_municipio_anterior);
            this.maskMoney(null, 'va_municipio', response.data.va_municipio);
            this.showMessage('success', 'IPM calculado e cadastrado com sucesso!');
            this.isLoading = false;
          } else {
            this.showMessage('danger', response.data.message);
            this.isLoading = false;
          }
        })
      },
      clearUpdate() {
        this.edipam = {
          competencia_ano: null,
          municipio: this.edipam.municipio,
          data_publicacao: null,
          va_estado_anterior: null,
          va_estado: null,
          pop_estado: null,
          rt_estado: null,
          ac_estado: null,
          ai_estado: null,
          va_municipio_anterior: 'Á Calcular',
          va_municipio: 'Á Calcular',
          pop_municipio: null,
          rt_municipio: null,
          ac_municipio: null,
          ai_municipio: null,
          iap_municipio: null,
          cf_municipio: "0.00310077 %",
          ipm: 'Á Calcular',
          id_cidade: this.edipam.id_cidade,
          tipo: null,
        }
        this.object.tipo = null;
      },
      getCidade() {
        const apiUrl = process.env.VUE_APP_API_BASE_URL + '/cidades_id/' + localStorage.getItem('id_cidade');
        axios.get(apiUrl)
        .then(response => {
          this.edipam.municipio = response.data.nome;
        })
      },
      maskMoney(event, field, valor = null) {
        let value; 

        if (valor == null)  {
          value = event.target.value;
        } else {
          value = valor;
        }
        value = String(value);      
        value = value.replace(/\D/g, '');
        value = (parseInt(value) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        this.edipam[field] = value;
      },
      unMask(value) {
        value = String(value);
        value = value.replace(/\D/g, '');
        return value;
      },
      maskNumber(event, field, valor = null) {
        let value;

        if (valor == null) {
          value = event.target.value;
        } else {
          value = valor;
        }
        if (value == '' || value == null) {
          this.edipam[field] = null;
          return;
        }
        value = String(value);
        value = value.replace(/\D/g,'');

        let reversed = value.split('').reverse().join(''); 
        let blocks = reversed.match(/\d{1,3}/g);
        let formatted = blocks.join('.');

        this.edipam[field] = formatted.split('').reverse().join(''); 
      },
    },
    created() {
      if (this.permissoes.length) {
        this.verificarPermissaoDaPagina('IPM Municipal');
      }
      const cor = localStorage.getItem('corDinamica');
      this.$store.commit("atualizarCorDinamica", cor);
      this.verificarPermissoes('IPM Municipal')
    },
    mounted() {
      this.getCidade();
    }
  };
  </script>
  