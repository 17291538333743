<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            ©
            {{ new Date().getFullYear() }}, Ascendant Project {{ versao }} by
            <a
              href="https://iibrasil.com.br/home"
              class="font-weight-bold"
              target="_blank"
              >iiBrasil </a
            >
          </div>
        </div>
        
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios';

export default {
  name: "AppFooterComponent",
  data() {
    return {
      versao: null
    };
  },
  mounted() {
    this.versao = localStorage.getItem('versao')
    if (this.versao) return
    const API_URL = process.env.VUE_APP_API_BASE_URL + '/versao';
    axios.get(API_URL)
      .then(response => {
        this.versao = response.data
        localStorage.setItem('versao', this.versao)
      })

      .catch(error => {
        console.error('Erro ao buscar os dados:', error);
      });
  },
};
</script>
