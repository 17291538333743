
import showSwal from "/src/mixins/showSwal.js";

export default {
  methods: {
    verificarCamposIguais(variavel, varOld) {
      for (const key in variavel) {
        if (variavel.hasOwnProperty(key) && varOld.hasOwnProperty(key)) {
          if (variavel[key] !== varOld[key]) {
            return false;
          }
        }
      }
      this.showMessage('danger', 'Erro, altere pelo menos um campo!')
      return true;
    },
    verificarCamposVazios(variaveis) {
        for (const key in variaveis) {
          if (variaveis.hasOwnProperty(key)) {
            if (variaveis[key] === null || variaveis[key] === undefined || variaveis[key] === '') {
              this.showMessage('danger', 'Erro, preencha todos os campos!')
              return true; 
            }
          }
        }
  
        return false; 
    },
    obterDadosSelect(variavel, type) {
        if (variavel && typeof variavel == 'object') {
          return variavel[type];
        } else {
          return null;
        }
    },
    showMessage(type, msg, width = 500){
        showSwal.methods.showSwal({
          type: type,
          message: msg,
          width: width,
        });
      },
    },
  };