<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-dark"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-80">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain" style="margin-top: 30%">
                <div class="pb-0 card-header text-start">
                  <h4 class="text-primary font-weight-bolder">Login</h4>
                  <p class=" mb-0">Digite seu login e senha para entrar</p>
                </div>
                <div class="card-body pb-3">
                  <FormA
                    role="form"
                    class="text-start"
                    :validation-schema="schema"
                    @submit="handleLogin"
                  >
                    <label>CPF/CNPJ</label>
                    <soft-field
                      id="login"
                      v-model="user.login"
                      type="text"
                      placeholder="Login"
                      name="login"
                      :data-mask="`['###.###.###-##', '##.###.###/####-##']`"
                    />
                    <label>Senha</label>
                    <soft-field
                      id="password"
                      v-model="user.password"
                      type="password"
                      placeholder="Senha"
                      name="password"
                    />

                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="primary"
                        full-width
                        :is-disabled="loading ? true : false"
                      >
                        <span
                          v-if="loading"
                          class="spinner-border spinner-border-sm"
                        ></span>
                        <span v-else>Entrar</span>
                      </soft-button>
                    </div>
                  </FormA>
                </div>
                <div class="px-1 pt-0 pb-3 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-0 text-sm">
                    Não tem uma conta?
                    <router-link
                      :to="'/' + path_cidade + '/register'"
                      class="text-primary text-gradient font-weight-bold"
                      >Cadastre-se</router-link
                    >
                  </p>
                </div>
                <div class="px-1 pt-0 pb-3 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Esqueceu a senha?
                    <router-link
                      :to="'/' + path_cidade + '/password/email'"
                      class="text-primary text-gradient font-weight-bold"
                      >Recuperar Senha</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="m-3 position-relative bg-gradient-primary h-100 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                  src="@/assets/img/shapes/waves-white.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-10 start-0"
                />
                <div class="position-relative">
                  <img
                    class="max-width-500 w-80 position-relative z-index-2"
                    src="@/assets/img/illustrations/chat.png"
                    alt="chat-img"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Seja bem vindo à {{ cidade['nome']  }}
                </h4>
                <p class="text-white">
                  Ascendant Project 1.1.1 by iiBrasil made in São sebastião, SP.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="login_password_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-sx" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="row">
                <div class="col-11">
                  <h5 class="modal-title-perfil">Redefinição de senha</h5>
                </div>
              </div>
    
            </div>
            <div class="modal-body">
              <form>
                  <div class="col-12 form-group">
                    <label for="login_new_password">Senha</label>
                    <input type="password" @input="checkPasswordStrength()" @focus="isFirstTouch = true" class="form-control" id="login_new_password" name="login_new_password" placeholder="Senha">
                  </div>
                  <password-check-result :isFirstTouch="isFirstTouch" ref="PasswordChild">

                  </password-check-result>
                  <div class=" col-12 form-group">
                    <label for="login_new_password_confirm">Confirme sua senha</label>
                    <input type="password" class="form-control" id="login_new_password_confirm" name="login_new_password_confirm" placeholder="Confirme sua senha">
                  </div>      
              </form> 
            </div>
            <div class="modal-footer">
              <button type="button" @click="refedinir()" class="btn btn-primary">Refedinir</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import axios from 'axios';
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftField from "@/components/SoftField.vue";
import SoftButton from "@/components/SoftButton.vue";
import maskCpfCnpj from "@/assets/js/maskCpfCnpj.js";
import showSwal from "/src/mixins/showSwal.js";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import { Form as FormA} from "vee-validate";
import * as yup from "yup";
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import passwordCheckResult from '../../../components/PasswordStrengthChecker.vue';

export default {
  name: "LoginComponent",
  components: {
    Navbar,
    AppFooter,
    SoftField,
    SoftButton,
    FormA,
    passwordCheckResult
  },
  
  data() {
    const schema = yup.object().shape({
      login: yup
        .string()
        .required("CPF é obrigatório!"),
      password: yup.string().required("Senha é obrigatória!"),
    });
    const user = {
      login: "11111111111111",
      password: "11111111111111",
      extranet: 1,
      id_cidade: null,
    };
    return {
      loading: false,
      user,
      schema,
      isFirstTouch: false,
      path_cidade: null,
      cidade: [],
    };
  },
  
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
    this.path_cidade = this.$route.params.path_cidade;
    if ((localStorage.getItem('path_cidade') == this.path_cidade) && (localStorage.getItem('id_cidade') != null) && (localStorage.getItem('nome_cidade') != null)){
      this.cidade['id'] = localStorage.getItem('id_cidade');
      this.cidade['path'] = localStorage.getItem('path_cidade');
      this.cidade['nome'] = localStorage.getItem('nome_cidade');
      this.user.id_cidade = this.cidade['id'];
    } else {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/cidades/${this.path_cidade}`)
        .then(response => {
          try {
            if (response.data['id'] == null || response.data['id'] == undefined) {
              this.$router.push("/error");
            }
            if (response.data['path'] == null || response.data['path'] == undefined) {
              this.$router.push("/error");
            }
            this.cidade = response.data;
            this.user.id_cidade = this.cidade['id'];
            localStorage.setItem('id_cidade', response.data['id']);
            localStorage.setItem('path_cidade', response.data['path']);
            localStorage.setItem('nome_cidade', response.data['nome']);
          } catch (error) {
            
            this.$router.push("/error");
          }
        })
        .catch(error => {
          this.$router.push("/error");
        });
    }
    

  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async handleLogin() {
      this.user.login = maskCpfCnpj.pop(this.user.login);
      this.loading = true;
      try {
        await this.$store.dispatch("auth/login", this.user);
        let active = localStorage.getItem('first_login');
        if (active){
          this.loading = false;
          localStorage.removeItem('first_login', 'active');
          this.isFirstTouch = false;
          $('#login_password_modal').modal('show');
        } else{
          this.$router.push("/usuario/usuarios");
        }
        
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Login ou senha inválido(s), tente novamente!",
          width: 500
        });
        this.loading = false;
      }
    },
    refedinir(){
      let password = $('#login_new_password').val();
      let password_confirm = $('#login_new_password_confirm').val();
      let login = localStorage.getItem('user');

      const passwordCheckResult = this.$refs.PasswordChild.checkPassword(password);
      if (passwordCheckResult) {
        this.loading = false;
        showSwal.methods.showSwal({
          type: "error",
          message: `A senha não atende ao critério: ${passwordCheckResult}.`,
          width: 500,
        });
        return;
      }

      if (password != password_confirm){
        showSwal.methods.showSwal({
          type: "error",
          message: "As senhas não conferem, tente novamente!",
          width: 500
        });
        return;
      }


      const apiUrl = process.env.VUE_APP_API_BASE_URL + '/new_password';
      axios
        .post(apiUrl, {
          password,
          login,
        })
        .then(response => {
          $('#login_password_modal').modal('hide');
          showSwal.methods.showSwal({
            type: "success",
            message: "Senha redefinida com sucesso!",
            width: 500
          });
        })
        .catch(error => {
          showSwal.methods.showSwal({
            type: "error",
            message: "Erro ao redefinir senha, tente novamente!",
            width: 500
          });
        });
    },
    checkPasswordStrength() {
      this.$nextTick(() => {
          if (this.$refs.PasswordChild) {
            this.$refs.PasswordChild.checkPasswordStrength('login_new_password');
          }
      });
    },
  },
  
};
</script>
