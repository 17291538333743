<template>
    <div :class="'card bg-gradient-' + corClass">
        <div class="p-4 card-body">
            <div class="row">
            <div class="my-auto col-12">
                <div class="numbers">
                <h5 class="mb-3 text-white font-weight-bolder">
                    {{ title }}
                </h5>
                </div>
            </div>
            </div>
            <div class="row">
            <div class="my-auto col-8">
                <h3 class="mb-0 text-white text-capitalize font-weight-bold opacity-9">{{ value }}</h3>
            </div>
            <div class="col-4 mb-0 text-end" v-html="textIcon">
            </div>
            </div>
            <div class="row">
            <div class="my-auto mb-2 col-12">
                <div class="progress" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                <div :class="'progress-bar bg-' + corClass2" style="width: 25%"></div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        textIcon: {
            type: String,
            required: true
        },
        corClass: {
            type: String,
            default: 'dark'
        }
    },
    data() {
        return {
            corClass2: ''
        }
    },
    computed: {
        inverseCorClass() {
            const availableClasses = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark'];
            const index = availableClasses.indexOf(this.corClass);
            if (index !== -1) {
                const inverseIndex = (index + Math.floor(availableClasses.length / 2)) % availableClasses.length;
                return availableClasses[inverseIndex];
            } else {
                return 'secondary';
            }
        }
    },
    watch: {
        corClass(newValue) {
            this.corClass2 = this.inverseCorClass;
        }
    },
    created() {
        this.corClass2 = this.inverseCorClass;
    }
}

</script>