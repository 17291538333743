<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="w-100"> 
              <div class="row">
                <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <SoftDropdown2 v-model="selected.titulo_acao" :placeholderText="'Titulo da Ação'" :minCharacters="2" :uniqueOptions="true" :columnFilter="'titulo_acao'" :rota="'/acoes/select2'" ref="acaoDropdown"/>
                </div>
                <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <SoftDropdown2 v-model="selected.titulo_pagina" :placeholderText="'Titulo da Página'" :minCharacters="2" :uniqueOptions="true" :columnFilter="'titulo_pagina'" :rota="'/paginas/select2'" ref="paginaDropdown"/>
                </div>  
                <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <div class="d-sm-block d-flex justify-content-center">
                    <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="filtrar">Filtrar</button>
                  </div>
                </div>
              </div>
              <AddModal @update-dad="filtrar" :corClass="corDinamica"/>
            </div>
            <div class="pb-0 card-body position-relative">
              <div v-if="isLoading" class="loading_gif">
                <img src="../assets/img/loading.gif" >
              </div>
              <TableComponent v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading" :tableName="tableName" :columns="columns" :data="data" :corClass="corDinamica"
                :componentEdit="componentEdit" :routeDelete="routeDelete" :idModalAdd="idModalAdd" :idModalEdit="idModalEdit" :CanAdd="CanAdd" :TextButtonAdd="TextButtonAdd" 
                :CanEdit="CanEdit" :CanDelete="CanDelete" :nome_filtros="nome_filtros" :filtros="filtros" :key="chave"/>
                <div v-if="data.length === 0">
                  <h3 class="text-center">Buscando dados</h3>
                  <h3 class="text-center">Com esses filtros</h3>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
<script>
import axios from 'axios';
import TableComponent from "./components/DataTables";
import AddModal from "./components/ModalsAdd/acoesAdd";
import SoftDropdown2 from "../components/SoftDropdown2";
import permissionsmixin from '../mixins/permissions.js';
import verificacoesmixin from '../mixins/verificacoes.js';
import makeTable from '../mixins/makeTable.js';

export default {
  mixins: [permissionsmixin, verificacoesmixin, makeTable],
  name: "AcoesView",
  components: {
    TableComponent,
    AddModal,
    SoftDropdown2,
  },
  data() {
    return {
      tableName: "AÇÕES",
      columns: ["Id", "Titulo da Ação", "Titulo da Página"],
      getColumnsData: {
        "Id": "id", 
        "Titulo da Ação": "titulo_acao",
        "Titulo da Página": "titulo_pagina",
      },
      componentEdit: 'ModalsEdit/acoesEdit',
      routeDelete: 'acoes/delete',
      idModalAdd: '#acoesModalAdd',
      idModalEdit: '#acoesModalEdit',
      TextButtonAdd: '+ Adicionar Ação',
      filtros: {
        titulo_acao: null,
        titulo_pagina: null,
      },
      nome_filtros: {
        titulo_acao: 'Título da Ação',
        titulo_pagina: 'Título da Página',
      },
      selected: {
        titulo_acao: null,
        titulo_pagina: null,
      },
      chave: 0,
      isLoading: true,
    };
  },
  watch: {
    permissoes: {
      handler() {
        this.verificarPermissaoDaPagina('Ações');
      },
      deep: true,
    },
  },
  computed: {
    corDinamica() {
      return this.$store.state.corDinamica;
    },
    permissoes() {
      return this.$store.state.permissoesUser;
    },
  },
  methods: {
    stopLoading(){
      this.isLoading = false;
    },
    async filtrar() {
      this.isLoading = true;

      this.filtros.titulo_acao = this.obterDadosSelect(this.selected.titulo_acao, 'value');
      this.filtros.titulo_pagina = this.obterDadosSelect(this.selected.titulo_pagina, 'value');
      await this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/acoes/tabela`, this.filtros, this.getColumnsData);
    },
  },
  created() {
    if (this.permissoes.length) {
      this.verificarPermissaoDaPagina('Ações');
    }
    const cor = localStorage.getItem('corDinamica');
    this.$store.commit("atualizarCorDinamica", cor);
    this.verificarPermissoes('Ações')
  },
  mounted() {
    this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/acoes/tabela`, this.filtros, this.getColumnsData);
  }
};
</script>
