<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            
            <div class="pb-0 card-header">
              <div class="w-100">
                <div class="row">
                    <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 div-filter">
                        <label>Contribuinte</label>
                        <SoftDropdown2 v-model="object.contribuinte" :placeholderText="'Selecione um Contribuinte'" :minCharacters="4" :columnFilter="'empresa'" :rota="'/arquivos/select2Empresas'"/>
                    </div> 
                    
                    <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-2">
                      <label>Competência Inicial</label>
                      <div class="input-group">
                        <input
                          id="competencia_inicial_empresasAC_mes"
                          name="competencia_inicial_empresasAC_mes"
                          type="text"
                          class="form-control"
                          placeholder="Mês"
                          v-model="competencia_inicial.mes"
                          v-maska
                          data-maska="##"
                          
                        />
                        <input id="competencia_inicial_empresasAC_ano"
                        name="competencia_inicial_empresasAC_ano"
                        type="text"
                        class="form-control"
                        placeholder="Ano"
                        v-model="competencia_inicial.ano"
                        v-maska
                        data-maska="####">
                      </div>
                    </div>
                    <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-2">
                      <label>Competência Final</label>
                      <div class="input-group">
                        <input
                          id="competencia_final_empresasAC_mes"
                          name="competencia_final_empresasAC_mes"
                          type="text"
                          class="form-control"
                          placeholder="Mês"
                          v-model="competencia_final.mes"
                          v-maska
                          data-maska="##"
                        />
                        <input id="competencia_final_empresasAC_ano"
                        name="competencia_final_empresasAC_ano"
                        type="text"
                        class="form-control"
                        v-model="competencia_final.ano"
                        placeholder="Ano"
                        v-maska
                        data-maska="####">
                      </div>
                    </div>
                    <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-2">
                      <label>Mostrar ICMS</label>
                      <SoftDropdown v-model="object.is_icms" :placeholderText="'Selecione'" :data="[{text: 'Sim', value: true}, {text: 'Não', value: false}]" :showClear="false"/>
                    </div>
                </div>
                
                <div class="row mt-3">
                    <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                        <div class="d-sm-block d-flex justify-content-center">
                          <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="gerarRelatorio">Gerar Relatório</button>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
      
  <script>
    import axios from 'axios';
    import SoftDropdown2 from '../components/SoftDropdown2.vue';
    import SoftDropdown from '../components/SoftDropdown.vue';
    import verificacoesmixin from '../mixins/verificacoes.js';
  
    export default {
      mixins: [verificacoesmixin],
      name: "logsView",
      components: {
        SoftDropdown2,
        SoftDropdown,
      },
      data() {
        return {
          object: {
            contribuinte: null,
            is_icms: {text: 'Não', value: false},
          },
          selected: {
            contribuinte: '',
            competencia_inicial: '',
            competencia_final: '',
            is_icms: false,
          },
          competencia_inicial: {
            mes: '',
            ano: '',
          },
          competencia_final: {
            mes: '',
            ano: '',
          },
          text: {
            contribuinte: '',
          },
        };
      },
      computed: {
        corDinamica() {
          return this.$store.state.corDinamica;
        },
      },
      methods: {
        async gerarRelatorio() {

          if (!this.competencia_final.ano || !this.competencia_final.mes || !this.competencia_inicial.ano || !this.competencia_inicial.mes) {
            this.showMessage('danger', 'Preencha todos os campos de competência!');
            return;
          }
          if (this.competencia_final.ano < this.competencia_inicial.ano) {
            this.showMessage('danger', 'Competência final não pode ser menor que a inicial!');
            return;
          }

          if (this.competencia_inicial.mes.length == 1){
            this.competencia_inicial.mes = '0' + this.competencia_inicial.mes;
          }

          if (this.competencia_final.mes.length == 1){
            this.competencia_final.mes = '0' + this.competencia_final.mes;
          }

          if (this.competencia_inicial.ano.length < 4 || this.competencia_final.ano.length < 4 ) {
            this.showMessage('danger', 'Competência inválida!');
            return;
          }

          if (this.competencia_inicial.mes > 12 || this.competencia_final.mes > 12) {
            this.showMessage('danger', 'Mês inválido!');
            return;
          }

          this.selected.competencia_inicial = this.competencia_inicial.ano + '-' + this.competencia_inicial.mes + '-01';
          this.selected.competencia_final = this.competencia_final.ano + '-' + this.competencia_final.mes + '-01';
          const user = localStorage.getItem('nome');
          const id_cidade = localStorage.getItem('id_cidade');
          const API_URL = process.env.VUE_APP_API_BASE_URL + `/gerar_relatorio_empresas_atv_conjugadas`;
          this.selected.contribuinte = this.obterDadosSelect(this.object.contribuinte, 'value');
          this.text.contribuinte = this.obterDadosSelect(this.object.contribuinte, 'text');
          this.selected.is_icms = this.obterDadosSelect(this.object.is_icms, 'value');
          try {
              const response = await axios.post(API_URL, {
                user: user,
                id_cidade: id_cidade,
                contribuinte: this.selected.contribuinte,
                competencia_inicial: this.selected.competencia_inicial,
                competencia_final: this.selected.competencia_final,
                text_contribuinte: this.text.contribuinte,
                is_icms: this.selected.is_icms,
                }, {
                  responseType: 'arraybuffer',
              });
              const blob = new Blob([response.data], { type: 'application/pdf' });
              const url = URL.createObjectURL(blob);
              window.open(url, 'filename');

          } catch (error) {
            this.showMessage('danger', 'Erro ao gerar relatório!');
          }
        },
      },
      created() {
        const cor = localStorage.getItem('corDinamica');
        this.$store.commit("atualizarCorDinamica", cor);
      },
    };
  </script>
