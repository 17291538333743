import axios from 'axios';

const API_URL = process.env.VUE_APP_API_BASE_URL + '/';

async function consultaCEP(cep) {
    const url = API_URL + "consulta-cep/" + cep;

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Erro na solicitação:', error);
      throw error;
    }
  }

export default consultaCEP;