<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12"> 
        <div class="pb-0 card table">
          <div class="row gx-4">
            <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
              <div class="nav-wrapper position-relative end-0">
                <ul class="p-1 bg-transparent nav nav-pills nav-fill" role="tablist">
                  <li class="nav-item">
                    <a
                      @click="rota='1'"
                      class="px-0 py-1 mb-0 nav-link active"
                      data-bs-toggle="tab"
                      href="pagina_cnae:;"
                      role="tab"
                      aria-selected="true"
                      ><span class="ms-1">CNAE</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      @click="rota='2'"
                      class="px-0 py-1 mb-0 nav-link active"
                      data-bs-toggle="tab"
                      href="pagina_secao:;"
                      role="tab"
                      aria-selected="true"
                      ><span class="ms-1">Seção</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      @click="rota='3'"
                      class="px-0 py-1 mb-0 nav-link"
                      data-bs-toggle="tab"
                      href="pagina_divisao:;"
                      role="tab"
                      aria-selected="false"
                      ><span class="ms-1">Divisão</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      @click="rota='4'"
                      class="px-0 py-1 mb-0 nav-link"
                      data-bs-toggle="tab"
                      href="pag_grupo:;"
                      role="tab"
                      aria-selected="false"
                      ><span class="ms-1">Grupo</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a 
                      @click="rota='5'"
                      class="px-0 py-1 mb-0 nav-link"
                      data-bs-toggle="tab"
                      href="pagina_classe:;"
                      role="tab"
                      aria-selected="false"
                    ><span class="ms-1">Classe</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card table">
          <div class="pb-0 card-header">
            <TabelaCnae v-if="rota=='1'"/>
            <TabelaSecao v-if="rota=='2'"/> 
            <TabelaDivisao v-else-if="rota=='3'"/>
            <TabelaGrupo v-else-if="rota=='4'"/>
            <TabelaClasse v-else-if="rota=='5'"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import TabelaCnae from  "@/views/components/CnaeComponents/TabelaCnae.vue";
  import TabelaSecao from  "@/views/components/CnaeComponents/TabelaSecao.vue";
  import TabelaDivisao from  "@/views/components/CnaeComponents/TabelaDivisao.vue";
  import TabelaGrupo from  "@/views/components/CnaeComponents/TabelaGrupo.vue";
  import TabelaClasse from  "@/views/components/CnaeComponents/TabelaClasse.vue";
  import setNavPills from "@/assets/js/nav-pills.js";
  import permissionsmixin from '../mixins/permissions.js';
  import setTooltip from "@/assets/js/tooltip.js";

  export default {
    mixins: [permissionsmixin],
    name: "ConsultaCnae",
    components: {
      TabelaCnae,
      TabelaSecao,
      TabelaGrupo,
      TabelaDivisao,
      TabelaClasse,
     
    },
    data() {
      return {
        rota : '1',
        showMenu: false,
      
      };
    },
    watch: {
      permissoes: {
        handler() {
          this.verificarPermissaoDaPagina('Consulta de CNAEs');
        },
        deep: true,
      },
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
      permissoes() {
        return this.$store.state.permissoesUser;
      },
    },
    methods: {
      //
    },
    created() {
    if (this.permissoes.length) {
      this.verificarPermissaoDaPagina('Consulta de CNAEs');
    }
    const cor = localStorage.getItem('corDinamica');
    this.$store.commit("atualizarCorDinamica", cor);
  },
    mounted() {
      setNavPills();
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
    },
  };
</script>
  