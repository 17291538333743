import axios from "axios";
import authHeader from "./auth-header";
import showSwal from "/src/mixins/showSwal.js";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/';

export default {
  async login(user) {
    const response = await axios.post(API_URL + "login", user);
    if (response.data.access_token) { 
      localStorage.setItem("user", response.data.user.login);
      if (response.data.user.first_login == 1 && response.data.user.change_password == 1) {
        localStorage.setItem('first_login', 'active');
      } else{
        localStorage.setItem('nome', response.data.user.nome);
        localStorage.setItem("token", JSON.stringify(response.data.access_token));
        localStorage.setItem('id_usuario', response.data.user.id);
        localStorage.setItem('login', response.data.user.login);
        localStorage.setItem('perfilId', response.data.user.perfilId);
        localStorage.setItem('corDinamica', response.data.user.cor);
        localStorage.setItem('logout', 'active');
        
      }
    }
  },

  async logout() {
    try {
      const id_cidade = localStorage.getItem('id_cidade');
      const user = localStorage.getItem('user');
      try{
        await axios.post(API_URL + "logout", {user: user, id_cidade: id_cidade}, { headers: authHeader() });
      } catch{
        
      }
      
    } finally {
      try{
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("corDinamica");
        localStorage.removeItem("perfilId");
      } catch{ 
      } 
    }
  },

  async register(user) {
    try {
      const response = await axios.post(API_URL + "register", user);
      localStorage.setItem("register", JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      localStorage.setItem("register", JSON.stringify(error.response.data));
      return error.response.data;
    }
  }
};
