
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="w-100"> 
              <AddModal @update-dad="filtrar" :corClass="corDinamica" :tipo="'contador'"/>
            </div>
            <div class="pb-0 card-body position-relative">
              <div v-if="isLoading" class="loading_gif">
                <img src="../assets/img/loading.gif" >
              </div>
              <TableComponent v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading"            
                :tableName="tableName" :CanAdd="CanAdd"
                :columns="columns" :data="data" :corClass="corDinamica"
                :componentEdit="componentEdit" :routeDelete="routeDelete" :idModalAdd="idModalAdd" :idModalEdit="idModalEdit"  :TextButtonAdd="TextButtonAdd" :CanEdit="CanEdit" :CanDelete="CanDelete" :CanDetail="CanDetail" :componentDetail="componentDetail" :nome_filtros="nome_filtros" :filtros="filtros" :key="chave" :preHiddenColumns="hiddenColumns" :idModalDetail="idModalDetail"/>
                <div v-if="data.length === 0">
                  <h3 class="text-center">Buscando dados</h3>
                  <h3 class="text-center">Com esses filtros</h3>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
<script>
import TableComponent from "./components/DataTables";
import AddModal from "./components/ModalsAdd/contadorContribuinteAdd";
import permissionsmixin from '../mixins/permissions.js';
import verificacoesmixin from '../mixins/verificacoes.js';
import makeTable from '../mixins/makeTable.js';
import axios from "axios";

export default {
  mixins: [permissionsmixin, verificacoesmixin, makeTable],
  name: "CadastroContadorView",
  components: {
    TableComponent,
    AddModal,
  },
  data() {
    return {
      tableName: "CONTADORES",
      filee: null,
      columns: ["Id", "Inscrição Estadual", "CNPJ", "Razão Social", "Nome Fantasia", "Telefone", "Email", "Endereço", "Município", "UF", "CEP", "Data Abertura", "Ultima Atualização", "Natureza Jurídica", "Capital Social", "Porte", "Tipo", "Situação", "Data Situação"],
      getColumnsData: {
        "Id": "id", 
        "Inscrição Estadual": "inscricao_estadual",
        "CNPJ": "cnpj",
        "Razão Social": "razao_social",
        "Nome Fantasia": "nome_fantasia",
        "Telefone": "telefone",
        "Email": "email",
        "Endereço": "logradouro",
        "Município": "municipio",
        "UF": "uf",
        "CEP": "cep",
        "Data Abertura": "data_abertura",
        "Ultima Atualização": "ultima_atualizacao",
        "Natureza Jurídica": "natureza_juridica",
        "Capital Social": "capital_social",
        "Porte": "porte",
        "Tipo": "tipo",
        "Situação": "situacao_cadastral",
        "Data Situação": "data_situacao_cadastral",
      },
      hiddenColumns: [11, 12, 13, 14, 15, 16, 17, 18],
      componentEdit: 'ModalsEdit/contadorEdit',
      componentDetail: 'ModalsDetail/ContadorDetail',
      routeDelete: 'contador/delete',
      idModalAdd: '#contadorContribuinteModalAdd',
      idModalEdit: '#contadorModalEdit',
      idModalDetail: '#contadorModalDetail',
      TextButtonAdd: '+ Adicionar Contador',
      filtros: {
        
      },
      nome_filtros: {
        titulo_acao: 'Título da Ação',
        titulo_pagina: 'Título da Página',
      },
      selected: {
        titulo_acao: null,
        titulo_pagina: null,
      },
      chave: 0,
      isLoading: true,
    };
  },
  watch: {
    permissoes: {
      handler() {
        this.verificarPermissaoDaPagina('Contador');
      },
      deep: true,
    },
  },
  computed: {
    corDinamica() {
      return this.$store.state.corDinamica;
    },
    permissoes() {
      return this.$store.state.permissoesUser;
    },
  },
  methods: {
    stopLoading(){
      this.isLoading = false;
    },
    async filtrar() {
      this.isLoading = true;
      await this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/contador/tabela`, this.filtros, this.getColumnsData);
    },
  },
  created() {
    if (this.permissoes.length) {
      this.verificarPermissaoDaPagina('Contador');
    }
    const cor = localStorage.getItem('corDinamica');
    this.$store.commit("atualizarCorDinamica", cor);
    this.verificarPermissoes('Contador')
  },
  mounted() {
    this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/contador/tabela`, this.filtros, this.getColumnsData);
  }
};
</script>

<style>

</style>