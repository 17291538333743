<template>
  <div class="py-4 container-fluid">
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="w-100">
                <div class="row">
                  <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-4 div-filter">
                    <SoftDropdown2 v-model="selected.titulo_perfil" :placeholder-text="'Perfil'" :column-filter="'titulo_perfil'" :minCharacters="0" :rota="'/perfil-acesso/select2'"/>
                  </div>
                  <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-4 div-filter">
                    <input v-model="filtros.ordem_perfil" type="number" class="form-control" id="filtro_ordem_perfil_perfil_acesso" name="filtro_ordem_perfil_perfil_acesso" placeholder="Ordem do Perfil"> 
                  </div>
                  <div class="col-12 col-xs-12 col-sm-6 col-md-2 col-lg-2 div-filter">
                    <div class="d-md-block d-flex justify-content-center">
                      <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="filtrar">Filtrar</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="bt-dtt my-auto mt-4 ms-auto mt-lg-0">
                    <AddModal @update-dad="filtrar" :corClass="corDinamica"/>
                  </div>
                </div>  
              </div>
              <div class="pb-0 card-body position-relative">
                <div v-if="isLoading" class="loading_gif">
                  <img src="../assets/img/loading.gif" >
                </div>
                <TableComponent @update-dad="filtrar" @is-loading="stopLoading" :tableName="tableName" :corClass="corDinamica" :columns="columns" :data="data" :componentPermissionAccess="componentPermissionAccess"
                :componentEdit="componentEdit" :routeDelete="routeDelete" :permissions="CanPermission" :idModalAdd="idModalAdd" :filtros="filtros" :idModalEdit="idModalEdit" :TextButtonAdd="TextButtonAdd"
                :CanEdit="CanEdit" :CanDelete="CanDelete" :CanAdd="CanAdd" :key="chave" :nome_filtros="nome_filtros" ref="TableComponent"/>
                <div v-if="data.length === 0">
                  <h3 class="text-center">Buscando dados</h3>
                  <h3 class="text-center">Com esses filtros</h3>
                </div>
              </div>
            </div> 
        </div>
      </div>
    </div>
  </div>
</template>
  
<script> 
import axios from 'axios';
import TableComponent from "./components/DataTables";
import AddModal from "./components/ModalsAdd/perfilAdd.vue";
import SoftDropdown2 from "../components/SoftDropdown2.vue";
import verificacoesmixin from '../mixins/verificacoes.js';
import makeTable from '../mixins/makeTable.js';
import permissionsmixin from '../mixins/permissions.js';

export default {
  mixins: [verificacoesmixin, makeTable, permissionsmixin],
  name: "PerfilAcessoView",
  components: {
    TableComponent,
    AddModal,
    SoftDropdown2
  },
  data() {
    return {
      tableName: "PERFIL DE ACESSO",
      columns: ["Id", "Título Perfil", "Ordem Perfil"],
      getColumnsData: {
        "Id": "id",
        "Título Perfil": "titulo_perfil",
        "Ordem Perfil": "ordem_perfil",
      },
      componentEdit: 'ModalsEdit/perfilEdit',
      routeDelete: 'perfil-acesso/delete',
      idModalAdd: '#perfilModalAdd',
      idModalEdit: '#perfilModalEdit',
      TextButtonAdd: '+ Adicionar Perfil',
      componentPermissionAccess: 'ModalsActions/PermissionAccsessBtn',
      filtros:{
        titulo_perfil: null,
        ordem_perfil: null,
      },
      nome_filtros: {
        titulo_perfil: "Título Perfil",
        ordem_perfil: "Ordem Perfil",
      },
      selected: {
        titulo_perfil: null,
      },
      isLoading: true,
    };
  },
  watch: {
    permissoes: {
      handler() {
        this.verificarPermissaoDaPagina('Perfil de Acesso');
      },
      deep: true,
    },
  },
  computed: {
    corDinamica() {
      return this.$store.state.corDinamica;
    },
    permissoes() {
      return this.$store.state.permissoesUser;
    },
  },
  methods:{
    stopLoading(){
      this.isLoading = false;
    },
    async filtrar() {
      this.isLoading = true;
      this.filtros.titulo_perfil = this.obterDadosSelect(this.selected.titulo_perfil, 'value');
      this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/perfil-acesso/tabela`, this.filtros, this.getColumnsData);
    },
  },
  created() {
    if (this.permissoes.length) {
      this.verificarPermissaoDaPagina('Perfil de Acesso');
    }
    const cor = localStorage.getItem('corDinamica');
    this.$store.commit("atualizarCorDinamica", cor);
    this.verificarPermissoes('Perfil de Acesso');
  },
  mounted() {
    this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/perfil-acesso/tabela`, this.filtros, this.getColumnsData);
    
  },
};
</script>