<template>
    <span class="text-danger span-password" v-if="showMandatoryFields && isFirstTouch">
        * {{ mandatoryFields.join(', ') }} obrigatórios
    </span>

    <div class="progress mt-3">
        <div
          class="progress-bar"
          :class="passwordStrengthClass"
          :style="{ width: passwordStrengthPercentage + '%' }"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
    </div>
      
</template>

<script>
export default {
    props: { 
        isFirstTouch: Boolean
    },
    data(){
      return{
          passwordStrength: 0,
          password: '',
          showMandatoryFields: true,
          mandatoryFields: ["Mínimo de 8 caracteres", "Letras maiúsculas",
          "Letras minúsculas", "Números", "Caracteres especiais"],
        }
    },
    computed:{
        passwordStrengthClass() {
            if (this.passwordStrength < 30) {
                return 'bg-danger';
            } else if (this.passwordStrength < 70) {
                return 'bg-warning';
            } else {
                return 'bg-success';
            }
        },
        passwordStrengthPercentage() {
            return this.passwordStrength;
        },
    },
    methods:{
        checkPasswordStrength(input_id) {
            try{
                if (this.isFirstTouch) {
                    this.showMandatoryFields = true;
                }
                const password = document.getElementById(input_id).value;
                const criteria = [
                    {
                        name: "Mínimo de 8 caracteres",
                        regex: /.{8,}/
                    },
                    {
                        name: "Letras maiúsculas",
                        regex: /[A-Z]+/
                    },
                    {
                        name: "Letras minúsculas",
                        regex: /[a-z]+/
                    },
                    {
                        name: "Números",
                        regex: /[0-9]+/
                    },
                    {
                        name: "Caracteres especiais",
                        regex: /[$@#&!]+/
                    }
                ];

                criteria.forEach((criterion) => {
                    if (!password.match(criterion.regex)) {
                        if (!this.mandatoryFields.includes(criterion.name)) {
                            this.mandatoryFields.push(criterion.name);
                        }
                    }
                });

                this.mandatoryFields = this.mandatoryFields.filter(field => {
                if (field === "Mínimo de 8 caracteres" && password.length > 7) {
                    return false;
                } else if (field === "Letras maiúsculas" && password.match(/[A-Z]+/)) {
                    return false;
                } else if (field === "Letras minúsculas" && password.match(/[a-z]+/)) {
                    return false;
                } else if (field === "Números" && password.match(/[0-9]+/)) {
                    return false;
                } else if (field === "Caracteres especiais" && password.match(/[$@#&!]+/)) {
                    return false;
                }
                return true;
                });

                if (this.mandatoryFields.length === 0) {
                this.showMandatoryFields = false;
                } else {
                this.showMandatoryFields = true;
                }

                this.passwordStrength = (5 - this.mandatoryFields.length) * 20;
            } catch (error) {
            }
        },
        checkPassword(password) {
             if (password.length < 8) {
                return "Mínimo de 8 caracteres";
            }
            if (!/[A-Z]+/.test(password)) {
                return "Letras maiúsculas";
            }
            if (!/[a-z]+/.test(password)) {
                return "Letras minúsculas";
            }
            if (!/[0-9]+/.test(password)) {
                return "Números";
            }
            if (!/[$@#&!]+/.test(password)) {
                return "Caracteres especiais";
            }
            return null;
        },
    }
}
</script>

<style>
.bg-danger {
    background-color: red;
}
  
.bg-warning {
    background-color: yellow;
}
  
.bg-success {
    background-color: green;
}
.span-password{
    font-size: 12px;
}
</style>