<template>
  <div v-if="chartOption" :style="{ width: chartWidth, height: chartHeight }" ref="chart"></div>
  <div v-else>Loading</div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: {
    chartOption: {
      type: Object,
      required: true
    },
    chartWidth: {
      type: String,
      default: '600px'
    },
    chartHeight: {
      type: String,
      default: '400px'
    },
    chartType: {
      type: String,
      default: 'bar'
    },
  },
  data() {
    return {
      chartInstance: null
    };
  },
  watch: {
    chartOption: {
      handler() {
        this.createChart();
      },
      deep: true
    }
  },
  methods: {
    createChart() {
      if (this.chartInstance) {
        this.chartInstance.dispose();
        this.chartInstance = null;
      }

      this.$nextTick(() => {
        const chartContainer = this.$refs.chart;
        const width = chartContainer.clientWidth;
        const height = chartContainer.clientHeight;

        if (width !== 0 && height !== 0) {
          this.chartInstance = echarts.init(chartContainer);
          this.chartInstance.setOption(this.chartOption);
        } 
      });
    }
  },
  mounted() {
    this.createChart();
  }
};
</script>

<style scoped>
</style>