<template>
  <div>
    <div class="w-100">  
      <div class="row">
        <div class="up col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
          <SoftDropdown2 v-model="selected.secao" :placeholderText="'Seção'" :minCharacters="0" :uniqueOptions="true" :columnFilter="'secao'" :rota="'/consulta_cnae/tabela_classe/select2'" ref="acaoDropdown"/>
        </div>
        <div class="up col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
          <SoftDropdown2 v-model="selected.divisao" :placeholderText="'Divisão'" :minCharacters="0" :uniqueOptions="true" :columnFilter="'grupo'" :rota="'/consulta_cnae/tabela_classe/select2'" ref="acaoDropdown"/>
        </div>
        <div class="up col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
          <SoftDropdown2 v-model="selected.grupo" :placeholderText="'Grupo'" :minCharacters="0" :uniqueOptions="true" :columnFilter="'divisao'" :rota="'/consulta_cnae/tabela_classe/select2'" ref="acaoDropdown"/>
        </div>
        <div class="up col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
          <SoftDropdown2 v-model="selected.classe" :placeholderText="'Classe'" :minCharacters="1" :uniqueOptions="true" :columnFilter="'classe'" :rota="'/consulta_cnae/tabela_classe/select2'" ref="acaoDropdown"/>
        </div>
      </div>  
      <div class="row">
        <div class="up col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
          <SoftDropdown2 v-model="selected.denominacao" :placeholderText="'Denominação'" :minCharacters="2" :uniqueOptions="true" :columnFilter="'denominacao'" :rota="'/consulta_cnae/tabela_classe/select2'" ref="acaoDropdown"/>
        </div>
        <div class="up col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
          <SoftDropdown2 v-model="selected.classe_limpa" :placeholderText="'Classe Limpa'" :minCharacters="2" :uniqueOptions="true" :columnFilter="'classe_limpa'" :rota="'/consulta_cnae/tabela_classe/select2'" ref="acaoDropdown"/>
        </div>
        <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
          <div class="d-sm-block d-flex justify-content-center">
            <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="filtrar">Filtrar</button>
          </div>
        </div>
      </div>
    </div>  
    <div class="pb-0 card-body position-relative">
      <div v-if="isLoading" class="loading_gif">
        <img src="../../../assets/img/loading.gif" >
      </div>
      <DataTables v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading" :tableName="tableName" :columns="columns" :data="data" :corClass="corDinamica"
      :CanEdit="CanEdit" :CanDelete="CanDelete" :CanAdd="CanAdd" :filtros="filtros" :nome_filtros="nome_filtros" :key="chave"/>
      <div v-if="data.length === 0">
        <h3 class="text-center">Buscando dados</h3>
        <h3 class="text-center">Com esses filtros</h3>
      </div>
    </div>
  </div>
</template>

<script>
import makeTable from '@/mixins/makeTable.js';
import DataTables from "../DataTables";
import SoftDropdown2 from "@/components/SoftDropdown2";
import permissionsmixin from '@/mixins/permissions.js';
import verificacoesmixin from '@/mixins/verificacoes.js';

export default {
  mixins: [permissionsmixin, verificacoesmixin, makeTable],
  name: 'TabelaSecao',
  components: {
    DataTables,
    SoftDropdown2
  },
  data() {
    return {
      tableName: "CLASSE",
      columns: ["Código", "Seção", "Divisão", "Grupo", "Classe", "Denominação", "Classe Limpa"],
      getColumnsData: { 
        "Código": "codigo",
        "Seção": "secao",
        "Divisão": "divisao",
        "Grupo": "grupo",
        "Classe": "classe",
        "Denominação": "denominacao",
        "Classe Limpa": "classe_limpa",
      },
      filtros: {
          codigo: null,
          secao: null,
          divisao: null,
          grupo: null,
          classe: null,
          denominacao: null,
          classe_limpa: null
        },
        nome_filtros: {
          codigo: 'Código',
          secao: 'Seção',
          divisao: 'Divisão',
          grupo: 'Grupo',
          classe: 'Classe',
          denominacao: 'Denominação',
          classe_limpa: 'Classe Limpa'
        },
        selected: {
          codigo: null,
          secao: null,
          divisao: null,
          grupo: null,
          classe: null,
          denominacao: null,
          classe_limpa: null
        },
      chave: 0,
      isLoading: true,
    };
  },
  computed: {
    corDinamica() {
      return this.$store.state.corDinamica;
    },
  },
  methods: {
    stopLoading(){
      this.isLoading = false;
    },
    async filtrar() {
      this.isLoading = true;

      this.filtros.codigo = this.obterDadosSelect(this.selected.codigo, 'value');
      this.filtros.secao = this.obterDadosSelect(this.selected.secao, 'value');
      this.filtros.divisao = this.obterDadosSelect(this.selected.divisao, 'value');
      this.filtros.grupo = this.obterDadosSelect(this.selected.grupo, 'value');
      this.filtros.classe = this.obterDadosSelect(this.selected.classe, 'value');
      this.filtros.denominacao = this.obterDadosSelect(this.selected.denominacao, 'value');
      this.filtros.classe_limpa = this.obterDadosSelect(this.selected.classe_limpa, 'value');

      await this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/consulta_cnae/tabela_classe`, this.filtros, this.getColumnsData);
      
    },
  },
  mounted() {
    this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/consulta_cnae/tabela_classe`, this.filtros, this.getColumnsData);
  },
};
</script>
<style scoped>
.up{
  height: 53px;
  align-content: center;
}

</style>