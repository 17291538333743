<template>
    <div>
        <div class="modal fade" id="contadorContribuinteModalAdd">
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <div class="row">
                  <div class="col-11">
                    <h5 class="modal-title-perfil">Cadastrar {{ tipo.charAt(0).toUpperCase() + tipo.slice(1) }}</h5>
                  </div>
                  <div class="col-1">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Fechar">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>    
              </div>
              <div class="modal-body">
            
                    <div>
                        <div class="row">
                          <div class="col-4">
                            <label>Inscrição Estadual</label>
                            <input
                              id="inscricao_estadual_contador_contribuinte_add"
                              v-model="selected.inscricao_estadual"
                              class="form-control input-register"
                              type="text"
                              placeholder="Inscrição Estadual"
                              name="inscricao_estadual_contador_contribuinte_add"
                            />
                          </div>
                          <div class="col-4">
                            <label> 
                              CNPJ 
                              <span style="color: red;">*</span>
                            </label>
                            <div class="input-group">
                              <input
                                id="cnpj_contribuinte"
                                name="cnpj_contribuinte"
                                type="text"
                                class="form-control"
                                placeholder="CNPJ"
                                v-model="selected.cnpj"
                                v-maska
                                data-maska="##.###.###/####-##"
                              />
                              <button @click="buscarCnpj" class="input-group-text text-white bg-gradient-primary" type="button"
                                ><i class="fas fa-search" aria-hidden="true"></i
                              ></button>
                            </div>
                            
                          </div>
                          <div class="col-4">
                            <label>Razão Social <span style="color: red;">*</span></label>
                            <input
                              id="razao_social_contador_contribuinte_add"
                              v-model="selected.razao_social"
                              class="form-control input-register"
                              type="text"
                              placeholder="Razão Social"
                              name="razao_social_contador_contribuinte_add"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Nome Fantasia</label>
                            <input
                              id="nome_fantasia_contador_contribuinte_add"
                              v-model="selected.nome_fantasia"
                              class="form-control input-register"
                              type="text"
                              placeholder="Nome Fantasia"
                              name="nome_fantasia_contador_contribuinte_add"
                            />
                          </div>
                         
                          <div class="col-4">
                            <label>Telefone </label>
                            <input
                              id="telefone_contador_contribuinte_add"
                              v-model="selected.telefone"
                              class="form-control input-register"
                              type="text"
                              placeholder="Telefone"
                              name="telefone_contador_contribuinte_add"
                            />
                          </div>
                          <div class="col-4">
                            <label>Email <span style="color: red;">*</span></label>
                            <input
                              id="email_contador_contribuinte_add"
                              v-model="selected.email"
                              class="form-control input-register"
                              type="email"
                              placeholder="Email"
                              name="email_contador_contribuinte_add"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3">
                            <label>CEP</label>
                            <div class="input-group">
                              <input
                                id="cep_contribuinte"
                                name="cep_contribuinte"
                                type="text"
                                class="form-control"
                                placeholder="CEP"
                                v-model="selected.cep"
                                v-maska
                                data-maska="#####-###"
                              />
                              <button @click="buscarCep" class="input-group-text text-white bg-gradient-primary" type="button"
                                ><i class="fas fa-search" aria-hidden="true"></i
                              ></button>
                            </div>
                          </div>
                          <div class="col-5">
                            <label>Endereço</label>
                            <input
                              id="endereco_contador_contribuinte_add"
                              v-model="selected.logradouro"
                              @input="converterParaMaiusculas('logradouro')"
                              class="form-control input-register"
                              type="text"
                              placeholder="Endereço"
                              name="endereco_contador_contribuinte_add"
                            />
                          </div>
                          <div class="col-1">
                            <label>Número</label>
                            <input
                              id="numero_contador_contribuinte_add"
                              v-model="selected.numero_logradouro"
                              class="form-control input-register"
                              type="number"
                              placeholder="Nº"
                              name="numero_contador_contribuinte_add"
                            />
                          </div>
                          <div class="col-3">
                            <label>Complemento</label>
                            <input
                              id="complemento_contador_contribuinte_add"
                              v-model="selected.complemento_logradouro"
                              @input="converterParaMaiusculas('complemento')"
                              class="form-control input-register"
                              type="text"
                              placeholder="Complemento"
                              name="complemento_contador_contribuinte_add"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3">
                            <label>Bairro</label>
                            <input
                              id="bairro_contador_contribuinte_add"
                              v-model="selected.bairro"
                              @input="converterParaMaiusculas('bairro')"
                              class="form-control input-register"
                              type="text"
                              placeholder="Bairro"
                              name="bairro_contador_contribuinte_add"
                            />
                          </div>
                          <div class="col-3">
                            <label>Município</label>
                            <input
                              id="municipio_contador_contribuinte_add"
                              v-model="selected.municipio"
                              @input="converterParaMaiusculas('municipio')"
                              class="form-control input-register"
                              type="text"
                              placeholder="Município"
                              name="municipio_contador_contribuinte_add"
                            />
                          </div>
                          <div class="col-1">
                            <label>UF</label>
                            <input
                              id="uf_contador_contribuinte_add"
                              v-model="selected.uf"
                              @input="converterParaMaiusculas('uf')"
                              class="form-control input-register"
                              type="text"
                              placeholder="UF"
                              name="uf_contador_contribuinte_add"
                            />
                          </div>
                          <div class="col-3">
                            <label>Capital Social</label>
                            <input
                              id="capital_social_contador_contribuinte_add"
                              v-model="selected.capital_social"
                              class="form-control input-register"
                              type="text"
                              placeholder="Capital Social"
                              name="capital_social_contador_contribuinte_add"
                            />
                          </div>
                          <div class="col-2">
                            <label>Data Situação</label>
                            <input
                              id="data_situacao_contador_contribuinte_add"
                              v-model="selected.data_situacao_cadastral"
                              class="form-control input-register"
                              type="date"
                              placeholder="Data Situação"
                              name="data_situacao_contador_contribuinte_add"
                            />
                          </div>
                        </div>
                        <div class="row">
                          
                          <div class="col-4">
                            <label>Natureza Jurídica</label>
                            <input
                              id="natureza_juridica_contador_contribuinte_add"
                              v-model="selected.natureza_juridica"
                              class="form-control input-register"
                              type="text"
                              placeholder="Natureza Jurídica"
                              name="natureza_juridica_contador_contribuinte_add"
                            />
                          </div>
                          <div class="col-2">
                            <label>Porte</label>
                            <input
                              id="porte_contador_contribuinte_add"
                              v-model="selected.porte"
                              class="form-control input-register"
                              type="text"
                              placeholder="Porte"
                              name="porte_contador_contribuinte_add"
                            />
                          </div>
                        
                          <div class="col-3">
                            <label>Abertura</label>
                            <input
                              id="abertura_contador_contribuinte_add"
                              v-model="selected.abertura"
                              class="form-control input-register"
                              type="date"
                              placeholder="Abertura"
                              name="abertura_contador_contribuinte_add"
                            />
                          </div>
                          <div class="col-3">
                            <label>Última Atualização</label>
                            <input
                              id="ultima_atualizacao_contador_contribuinte_add"
                              v-model="selected.ultima_atualizacao"
                              class="form-control input-register"
                              type="date"
                              placeholder="Última Atualização"
                              name="ultima_atualizacao_contador_contribuinte_add"
                            />
                          </div>
                          
                        </div>
                        <div class="row">
                          <div class="col-3">
                            <label>Situação</label>
                            <input
                              id="situacao_contador_contribuinte_add"
                              v-model="selected.situacao_cadastral"
                              class="form-control input-register"
                              type="text"
                              placeholder="Situação"
                              name="situacao_contador_contribuinte_add"
                            />
                          </div>
                          <div class="col-3">
                            <label>Tipo</label>
                            <input
                              id="tipo_contador_contribuinte_add"
                              v-model="selected.tipo"
                              class="form-control input-register"
                              type="text"
                              placeholder="Tipo"
                              name="tipo_contador_contribuinte_add"
                            />
                          </div>
                          
                        
                          <div class="col-6">
                            <label>Atividade Principal</label>
                            <div class="input-group">
                              <input
                                id="atividade_principal_cod_contador_contribuinte_add"
                                v-model="selected.atividade_principal_cod"
                                class="form-control input-register"
                                type="text"
                                placeholder="Código"
                                name="atividade_principal_cod_contador_contribuinte_add"
                                v-maska
                                data-maska="####-#/##"
                              />
                              <input
                                id="atividade_principal_contador_contribuinte_add"
                                v-model="selected.atividade_principal"
                                class="form-control input-register w-60"
                                type="text"
                                placeholder="Atividade Principal"
                                name="atividade_principal_contador_contribuinte_add"
                              />
                              
                            </div>
                          </div>
                          
                        </div>
                        <button class="btn bg-gradient-primary" @click="addInputAtividade" style="word-spacing: 3px;">
                          Adicionar Atividade Secundária
                        </button>
                        <div class="row">
                          <div v-for="(input, index) in selected.inputsAtividades" :key="index" class="col-6">
            
                            <label>Atividade Secundária {{ index + 1 }}</label>
                            <br>
                            <div class="input-group">
                              <input
                              id="codigo_cod_contador_contribuinte_add"
                              v-model="selected.inputsAtividadesCod[index]"
                              type="text"
                              class="form-control"
                              placeholder='Código '
                              v-maska
                              data-maska="####-#/##"
                              name="codigo_cod_contador_contribuinte_add"

                              >
                              <input
                              id="descricao_cod_contador_contribuinte_add"
                              v-model="selected.inputsAtividades[index]"
                              type="text"
                              class="form-control w-60"
                              placeholder=" Descrição "
                              name="descricao_cod_contador_contribuinte_add"
                              >
                            <button @click="removeInputAtividade(index)" class="input-group-text text-white bg-gradient-primary" type="button"
                            ><i class="fas fa-close" aria-hidden="true"></i
                            ></button>
            
                            </div>
                            
                          </div>
                        </div>
                        <button class="btn bg-gradient-primary" @click="addInputQSA" style="word-spacing: 3px; margin-top: 1%;">
                          Adicionar Sócio/Administrador
                        </button>
                        <div class="row">
                          <div v-for="(input, index) in selected.inputQsaNome" :key="index" class="col-6">
            
                            <label>Sócio / Administrador {{ index + 1 }}</label>
                            <br>
                            <div class="input-group">
                              <input
                              id="nome_cod_contador_contribuinte_add"
                              v-model="selected.inputQsaNome[index]"
                              type="text"
                              class="form-control w-50"
                              placeholder="Nome"
                              name="nome_cod_contador_contribuinte_add"
                            >
                              <input
                              id="qualificacao_cod_contador_contribuinte_add"
                              v-model="selected.inputQsaQual[index]"
                              type="text"
                              class="form-control"
                              placeholder=" Qualificação"
                              name="qualificacao_cod_contador_contribuinte_add"
                              >
                            <button @click="removeInputQSA(index)" class="input-group-text text-white bg-gradient-primary" type="button"
                            ><i class="fas fa-close" aria-hidden="true"></i
                            ></button>
            
                            </div>
            
                          </div>
                        </div>
                        <div class="row" style="margin-top: 2%">
                          <div class="col-6">
                            <label>Senha <span style="color: red;">*</span> </label>
                            <div class="input-group">
                              <input
                                id="password"
                                v-model="selected.password"
                                :type="showPassword ? 'text' : 'password'"
                                @input="checkPasswordStrength('password')" 
                                placeholder="Senha"
                                class="form-control"
                                @click="isFirstTouch = true"
                                name="password"
                              />
                              <button @click="showPassword=!showPassword" class="input-group-text text-white bg-gradient-primary" type="button"
                              ><i class="fas fa-eye" aria-hidden="true"></i
                              ></button>
                              </div>
                            <password-check-result :isFirstTouch="isFirstTouch" ref="PasswordChild">
            
                            </password-check-result>
                          </div>
                          <div class="col-6">
                            <label>Digite novamente a senha <span style="color: red;">*</span></label>
                            <div class="input-group">
                              <input
                                id="password_confirmation"
                                v-model="selected.password_confirmation"
                                :type="showPasswordConfirmation ? 'text' : 'password'"
                                class="form-control"
                                placeholder="Digite novamente a senha"
                                name="password_confirmation"
                              />
                              <button @click="showPasswordConfirmation=!showPasswordConfirmation" class="input-group-text text-white bg-gradient-primary" type="button"
                              ><i class="fas fa-eye" aria-hidden="true"></i
                              ></button>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                 
              </div>
              <div class="modal-footer">
                <soft-button class="my-4 mb-2" variant="gradient" color="secondary" data-bs-dismiss="modal">
                  <span>Cancelar</span>
                </soft-button>
                <div style="width: 100px;">
                  <soft-button class="my-4 mb-2" variant="gradient" :color="corClass" full-width @click="SalvarAcao()"
                    :is-disabled="loading ? true : false">
                    <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                    <span v-else>Salvar</span>
                  </soft-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import axios from 'axios';
import showSwal from "/src/mixins/showSwal.js";
import SoftButton from '../../../components/SoftButton.vue';
import verificacoesmixin from '../../../mixins/verificacoes.js';
import maskCpfCnpj from "@/assets/js/maskCpfCnpj.js";
import consultaCNPJ from '@/assets/js/consulta-cnpj.js';
import consultaCEP from '@/assets/js/consulta-cep.js';
import passwordCheckResult from '../../../components/PasswordStrengthChecker.vue';

  
export default {
    mixins: [verificacoesmixin],
    props:{
      corClass: String,
      tipo: String,
    },
    name: 'AddContador',
    data() {
      return {
        loading: false,
        selected: {
            inscricao_estadual: '',
            cnpj: '59546515000134',
            razao_social: null,
            nome_fantasia: null,
            telefone: null,
            email: null,
            cep: null,
            logradouro: null,
            numero_logradouro: null,
            complemento_logradouro: null,
            bairro: null,
            municipio: null,
            uf: null,
            capital_social: null,
            data_situacao_cadastral: null,
            natureza_juridica: null,
            porte: null,
            abertura: null,
            ultima_atualizacao: null,
            situacao_cadastral: null,
            tipo: null,
            atividade_principal_cod: null,
            atividade_principal: null,
            inputsAtividadesCod: [],
            inputsAtividades: [],
            inputQsaNome: [],
            inputQsaQual: [],
            password: null,
            password_confirmation: null,
        },
        showError: false,
        isFirstTouch: false,
        showPassword: false,
        showPasswordConfirmation: false,
        chave: 0,
      };
    },
    components: {
        SoftButton,
        passwordCheckResult,
    },
    methods: {
        SalvarAcao() {
            const validateField = (field, errorMessage, validationFunction = null) => {
                if (this.selected[field] == null || this.selected[field] == undefined || this.selected[field] == "") {
                showSwal.methods.showSwal({
                    type: "error",
                    message: errorMessage,
                    width: 500,
                });
                return false;
                }

                if (validationFunction && !validationFunction(this.selected[field])) {
                showSwal.methods.showSwal({
                    type: "error",
                    message: errorMessage,
                    width: 500,
                });
                return false;
                }

                return true;
            };

            if (
                !validateField("cnpj", "Por favor, informe um CNPJ para buscar.")
                || !validateField("razao_social", "Por favor, informe a Razão Social.")
                || !validateField("email", "Por favor, informe o Email.")
                || !validateField("password", "Por favor, informe a Senha.")
                || !validateField("password_confirmation", "Por favor, informe a Confirmação da Senha.")
            ) {
                return;
            }

            if (this.selected.password !== this.selected.password_confirmation) {
                showSwal.methods.showSwal({
                type: "error",
                message: "A Senha e a Confirmação da Senha não conferem.",
                width: 500,
                });
                return;
            }
            this.selected.cnpj = maskCpfCnpj.pop(this.selected.cnpj)
            if (this.selected.cnpj.length != 14) {
                showSwal.methods.showSwal({
                type: "error",
                message: "O CNPJ informado não é válido.",
                width: 500,
                });
                return;
            }

            if (this.selected.email.indexOf('@') == -1 || this.selected.email.indexOf('.') == -1){
                showSwal.methods.showSwal({
                type: "error",
                message: "'O e-mail informado não é válido.",
                width: 500,
                });
                return;
            }
            this.selected.atividade_principal_cod = maskCpfCnpj.pop(this.selected.atividade_principal_cod)
            this.selected.cep = maskCpfCnpj.pop(this.selected.cep)
            this.selected.inscricao_estadual = maskCpfCnpj.pop(this.selected.inscricao_estadual)
            this.selected.telefone = maskCpfCnpj.pop(this.selected.telefone)
            for (let index = 0; index < this.selected.inputsAtividadesCod.length; index++) {
                this.selected.inputsAtividadesCod[index] = maskCpfCnpj.pop(this.selected.inputsAtividadesCod[index])
            }
            const passwordCheckResult = this.$refs.PasswordChild.checkPassword(this.selected.password);
            if (passwordCheckResult) {
                this.loading = false;
                showSwal.methods.showSwal({
                type: "error",
                message: `A senha não atende ao critério: ${passwordCheckResult}.`,
                width: 500,
                });
                return;
            }
            this.loading = true;
            this.showError = false;
      
    
            const user = localStorage.getItem('user');
            const id_cidade = localStorage.getItem('id_cidade');
    
            const apiUrl = process.env.VUE_APP_API_BASE_URL + `/${this.tipo}/adicionar`;
            axios.post(apiUrl,{
                id_cidade: id_cidade,
                user: user,
                dados: this.selected,
            })
            .then(response => {
            if (response.data.status == 'success'){
                this.clearUpData();
                $('#contadorContribuinteModalAdd').modal('hide');
                this.showMessage('success', response.data.message);
                this.$emit('update-dad');
            } else {
                this.showMessage('danger', response.data.message);
            }
            })
            .catch(error => {
            this.showMessage('danger', error.response.data.message);
            })
            .finally(() => {
            this.loading = false;
            });
        },
        clearUpData() {
            this.selected = {
                inscricao_estadual: '',
                cnpj: null,
                razao_social: null,
                nome_fantasia: null,
                telefone: null,
                email: null,
                cep: null,
                logradouro: null,
                numero_logradouro: null,
                complemento_logradouro: null,
                bairro: null,
                municipio: null,
                uf: null,
                capital_social: null,
                data_situacao_cadastral: null,
                natureza_juridica: null,
                porte: null,
                abertura: null,
                ultima_atualizacao: null,
                situacao_cadastral: null,
                tipo: null,
                atividade_principal_cod: null,
                atividade_principal: null,
                inputsAtividadesCod: [],
                inputsAtividades: [],
                inputQsaNome: [],
                inputQsaQual: [],
                password: null,
                password_confirmation: null,
                agreed: false,
            };
            this.showError = false;
            this.isFirstTouch = false;
            this.showPassword = false;
            this.showPasswordConfirmation = false;
            this.chave++;
        },
        converterParaMaiusculas(fieldName) {
            this.selected[fieldName] = this.selected[fieldName].toUpperCase();
        },
        addInputAtividade() {
            this.selected.inputsAtividades.push('');  
            this.selected.inputsAtividadesCod.push('');
        },
        addInputQSA(){
            this.selected.inputQsaNome.push('');
            this.selected.inputQsaQual.push('');
        },
        removeInputQSA(index) {
            this.selected.inputQsaNome.splice(index, 1);
            this.selected.inputQsaQual.splice(index, 1);
        
        },
        removeInputAtividade(index) {
            this.selected.inputsAtividades.splice(index, 1);
            this.selected.inputsAtividadesCod.splice(index, 1);
        },
        formatDate2(inputDate) {
            const date = new Date(inputDate);
            if (isNaN(date.getTime())) {
                return null; 
            }
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        formatDate(inputDate) {
            const parts = inputDate.split('/');

            const year = parts[2];  
            const month = parts[1]; 
            const day = parts[0];  

            const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            return formattedDate;
        },
        buscarCep(){
            consultaCEP(this.selected.cep.replace(/\D/g, ''))
            .then(dados => {
                if (dados['cep']) {
                this.selected.logradouro = dados['logradouro'].toUpperCase();
                this.selected.bairro = dados['bairro'].toUpperCase();
                this.selected.municipio = dados['localidade'].toUpperCase();
                this.selected.uf = dados['uf'].toUpperCase();
                } else {
                showSwal.methods.showSwal({
                    type: "error",
                    message: `CEP não encontrado.`,
                    width: 500,
                });
                }
            })
        },
        buscarCnpj(){
            if (this.selected.cnpj == null || this.selected.cnpj == undefined || this.selected.cnpj == "") {
                showSwal.methods.showSwal({
                type: "error",
                message: `Por favor, informe um CNPJ para buscar.`,
                width: 500,
                });
                return;
            }
            consultaCNPJ(this.selected.cnpj.replace(/\D/g, ''))
            .then(dados => {
                if (dados['status'] == 'ERROR') {
                showSwal.methods.showSwal({
                    type: "error",
                    message: `CNPJ não encontrado.`,
                    width: 500,
                });

                return;
                }
                this.selected.razao_social = dados['nome'] ? dados['nome'] : '';
                this.selected.nome_fantasia = dados['fantasia']? dados['fantasia'] : '';
                this.selected.telefone = dados['telefone'] ? dados['telefone'] : '';
                this.selected.email = dados['email'] ? dados['email'] : '';
                this.selected.cep = dados['cep'] ? dados['cep'].replace(/\D/g, '') : '';
                this.selected.logradouro = dados['logradouro'] ? dados['logradouro'] : '';
                this.selected.numero_logradouro = dados['numero_logradouro'] ? dados['numero_logradouro'] : '';
                this.selected.complemento_logradouro = dados['complemento_logradouro'] ? dados['complemento_logradouro'] : '';
                this.selected.bairro = dados['bairro'] ? dados['bairro'] : '';
                this.selected.municipio = dados['municipio'] ? dados['municipio'] : '';
                this.selected.uf = dados['uf'] ? dados['uf'] : '';
                this.selected.capital_social = dados['capital_social'] ? dados['capital_social'] : '';
                this.selected.data_situacao_cadastral = dados['data_situacao_cadastral'] ? this.formatDate(dados['data_situacao_cadastral']) : ''; 
                this.selected.atividade_principal = dados['atividade_principal'][0]['text'] ? dados['atividade_principal'][0]['text'] : '';
                this.selected.atividade_principal_cod = dados['atividade_principal'][0]['code'] ? dados['atividade_principal'][0]['code'].replace(/\D/g, '') : '';
                this.selected.natureza_juridica = dados['natureza_juridica'] ? dados['natureza_juridica'] : '';
                this.selected.porte = dados['porte'] ? dados['porte'] : '';
                this.selected.abertura = dados['abertura'] ? this.formatDate(dados['abertura']) : '';
                this.selected.ultima_atualizacao = dados['ultima_atualizacao'] ? this.formatDate2(dados['ultima_atualizacao']) : '';
                this.selected.situacao_cadastral = dados['situacao_cadastral'] ? dados['situacao_cadastral'] : '';
                this.selected.tipo = dados['tipo'] ? dados['tipo'] : '';
                const atividades = dados['atividades_secundarias'] ? dados['atividades_secundarias'] : [];
                this.selected.inputsAtividades=[];
                this.selected.inputsAtividadesCod=[];
                for (let index = 0; index < atividades.length; index++) {
                this.selected.inputsAtividades.push(atividades[index]['text']);
                }
                for (let index = 0; index < atividades.length; index++) {
                this.selected.inputsAtividadesCod.push(atividades[index]['code'].replace(/\D/g, ''));
                }
                const QSAs = dados['qsa'];
                this.selected.inputQsaNome=[];
                for (let index = 0; index < QSAs.length; index++) {
                this.selected.inputQsaNome.push(QSAs[index]['nome']);
                }
                this.selected.inputQsaQual=[];
                for (let index = 0; index < QSAs.length; index++) {
                this.selected.inputQsaQual.push(QSAs[index]['qual']);
                }
            })
            .catch(error => {
                console.error(error);
            });
        },
        checkPasswordStrength(input_id) {
            this.$nextTick(() => {
                if (this.$refs.PasswordChild) {
                    this.$refs.PasswordChild.checkPasswordStrength(input_id);
                }
            });
        },
    }
};
  </script>
  
  
  