<template>
  <nav
    id="navbarBlur"
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    :class="[isRTL ? 'top-1 position-sticky z-index-sticky' : '']"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
        :current-directory="currentDirectory"
        :current-page="currentRouteName"
        :text-white="textWhite"
      />
      <div
        class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none"
        :class="isRTL ? 'me-3' : ''"
      >
        <a href="#" class="p-0 nav-link text-body" @click.prevent="minNav">
          <div class="sidenav-toggler-inner">
            <i
              class="sidenav-toggler-line"
              :class="textWhite ? 'bg-white' : ''"
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="textWhite ? 'bg-white' : ''"
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="textWhite ? 'bg-white' : ''"
            ></i>
          </div>
        </a>
      </div>
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          <div class="input-group">
            <span class="input-group-text text-body"
              ><i class="fas fa-search" aria-hidden="true"></i
            ></span>
            <input
              id="pesquisar_globalmente"
              name="pesquisar_globalmente"
              type="text"
              class="form-control"
              placeholder="Pesquisar"
            />
          </div>
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center">
            <router-link
              v-if="!loggedIn"
              :to="{ name: 'Login' }"
              class="px-0 nav-link font-weight-bold"
              :class="textWhite ? textWhite : 'text-body'"
            >
              <i class="fa fa-user" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'"></i>
              <span v-if="isRTL" class="d-sm-inline d-none">يسجل دخول</span>
              <span v-else class="d-sm-inline d-none">Sign In </span>
            </router-link>

            <a
              v-else
              class="px-0 nav-link font-weight-bold"
              :class="textWhite ? textWhite : 'text-body'"
              @click="logoutUser"
            >
              <i class="fa fa-user" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'"></i>
              <span v-if="isRTL" class="d-sm-inline d-none">تسجيل الخروج</span>
              <span v-else class="d-sm-inline d-none"> Sair</span>
            </a>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              id="iconNavbarSidenav"
              href="#"
              class="p-0 nav-link text-body"
              @click="toggleSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
          <li class="px-3 nav-item d-flex align-items-center">
            <a
              class="p-0 nav-link"
              :class="textWhite ? textWhite : 'text-body'"
              @click="toggleConfigurator"
            >
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li>
          <li
            class="nav-item dropdown d-flex align-items-center pe-2"
          >
            <a
              id="dropdownMenuButton"
              href="#"
              class="p-0 nav-link"
              :class="[
                textWhite ? textWhite : 'text-body',
                showMenu ? 'show' : '',
              ]"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="cursor-pointer fa fa-bell">
                <span v-if="unreadNotificationsCount > 0" class="notification-counter">{{ unreadNotificationsCount }}</span>
              </i>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
            <div v-for="notification in newNotifications" :key="notification.id" class="mb-2">
              <li>
                <router-link to="/sistema/notificacoes" class="dropdown-item border-radius-md">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">{{ notification.titulo }}</span>
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        {{ notification.diferenca_em_tempo }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
            </div>
            <div v-if="newNotifications.length == 0">
              <li>
                <router-link to="/sistema/notificacoes" class="dropdown-item border-radius-md">
                <div class="py-1 d-flex">
                  <div class="my-auto">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Nenhuma notificação</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </router-link>
              </li>
            </div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import axios from "axios";
import { mapMutations, mapActions, mapState } from "vuex";
import { create } from "yup/lib/Reference";

export default {
  name: "NavbarComponent",
  components: {
    Breadcrumbs,
  },
  props: {
    minNav: {
      type: Function,
      default: () => {},
    },
    textWhite: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState(["isRTL"]),
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
    unreadNotificationsCount() {
      return this.newNotifications.filter(notification => !notification.vizualizado).length;
    },
    newNotifications() {
      return this.$store.state.newNotifications;
    },
  },

  created() {
    this.minNav;
    const userId = localStorage.getItem("id_usuario");
    const apiUrl = process.env.VUE_APP_API_BASE_URL + '/notificacoes/new/' + userId ;
    axios.get(apiUrl)
    .then(response => {
      const newNotifications = response.data;
      this.$store.commit("atualizarNewNotifications", newNotifications);
    })
    .catch(error => {
      console.log(error);
    });

  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },

    async logoutUser() {
      try {
        await this.$store.dispatch("auth/logout");
      } finally {
        let path_cidade = localStorage.getItem("path_cidade");
        this.$router.push(`/${path_cidade}/login`);
      }
    },
  },
  
};
</script>
<style>
a:hover {
  cursor: pointer;
}
.notification-counter {
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 4px;
  font-size: 10px;
}



</style>
