
<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="pb-0 card-header">
              
              <div class="w-100"> 
                <div class="row">
                
                </div>
                <AddModal @update-dad="filtrar" :corClass="corDinamica" :tipo="'contribuinte'"/>
              </div>
              <div class="pb-0 card-body position-relative">
                <div v-if="isLoading" class="loading_gif">
                  <img src="../assets/img/loading.gif" >
                </div>
                <TableComponent v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading"  :tableName="tableName" :CanAdd="CanAdd" :columns="columns" :data="data" :corClass="corDinamica" :routeDelete="routeDelete" :TextButtonAdd="TextButtonAdd" :idModalAdd="idModalAdd" :CanDelete="CanDelete" :CanVincular="CanVincular" :nome_filtros="nome_filtros" :filtros="filtros" :key="chave" :preHiddenColumns="hiddenColumns"/>
                  <div v-if="data.length === 0">
                    <h3 class="text-center">Buscando dados</h3>
                    <h3 class="text-center">Com esses filtros</h3>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
    
  <script>
  import TableComponent from "./components/DataTables";
  import AddModal from "./components/ModalsAdd/vincularContribuintesAdd";
  import permissionsmixin from '../mixins/permissions.js';
  import verificacoesmixin from '../mixins/verificacoes.js';
  import makeTable from '../mixins/makeTable.js';
  
  export default {
    mixins: [permissionsmixin, verificacoesmixin, makeTable],
    name: "VincularContribuinteView",
    components: {
      TableComponent,
      AddModal,
    },
    data() {
      return {
        tableName: "CONTRIBUINTES VINCULADOS",
        columns: ["Id", "Inscrição Estadual", "CNPJ", "Razão Social", "Email", "Telefone", "Nome Fantasia", "Endereço", "Município", "UF", "CEP", "Data Abertura", "Ultima Atualização", "Natureza Jurídica", "Capital Social", "Porte", "Tipo", "Situação do Vínculo", "Atualizado Em"],
        getColumnsData: {
          "Id": "id", 
          "Inscrição Estadual": "inscricao_estadual",
          "CNPJ": "cnpj",
          "Razão Social": "razao_social",
          "Nome Fantasia": "nome_fantasia",
          "Telefone": "telefone",
          "Email": "email",
          "Endereço": "logradouro",
          "Município": "municipio",
          "UF": "uf",
          "CEP": "cep",
          "Data Abertura": "data_abertura",
          "Ultima Atualização": "ultima_atualizacao",
          "Natureza Jurídica": "natureza_juridica",
          "Capital Social": "capital_social",
          "Porte": "porte",
          "Tipo": "tipo",
          "Situação do Vínculo": "situacao_vinculo", 
          "Atualizado Em": "atualizado_em",
        },
        hiddenColumns: [4,5,6,7,8,9,10,11, 12, 13, 14, 15, 16,],
        routeDelete: 'rel_contador_contribuinte/delete',
        idModalAdd: '#vincularContribuintesModalAdd',
        TextButtonAdd: '+ Vincular Contribuinte',
        filtros: {
        },
        nome_filtros: {
          titulo_acao: 'Título da Ação',
          titulo_pagina: 'Título da Página',
        },
        chave: 0,
        isLoading: true,
      };
    },
    watch: {
      permissoes: {
        handler() {
          this.verificarPermissaoDaPagina('Vincular Contribuintes');
        },
        deep: true,
      },
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
      permissoes() {
      return this.$store.state.permissoesUser;
    },
    },
    methods: {
      stopLoading(){
        this.isLoading = false;
      },
      async filtrar() {
        this.isLoading = true;
        await this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/rel_contador_contribuinte/tabela`, this.filtros, this.getColumnsData);
      },
    },
    created() {
      if (this.permissoes.length) {
        this.verificarPermissaoDaPagina('Vincular Contribuintes');
      }
      const cor = localStorage.getItem('corDinamica');
      this.$store.commit("atualizarCorDinamica", cor);
      this.verificarPermissoes('Vincular Contribuintes');
    },
    mounted() {
      this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/rel_contador_contribuinte/tabela`, this.filtros, this.getColumnsData);
     
    }
  };
  </script>
  