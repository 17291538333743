window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { vMaska } from 'maska';
import Dropdown from 'primevue/dropdown';
import PrimeVue from 'primevue/config';
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import 'primevue/resources/themes/lara-light-blue/theme.css';

import 'primevue/resources/primevue.min.css';
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import SoftUIDashboard from "./soft-ui-dashboard";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(PrimeVue);
appInstance.component('DropdownSoft', Dropdown);
appInstance.use(router);
appInstance.directive('maska', vMaska);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(SoftUIDashboard);
appInstance.mount("#app");


