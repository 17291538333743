<template>
  <div id="sidenav-collapse-main" style="max-width: 250px;" class="w-auto h-auto collapse  navbar-collapse max-height-vh-100 h-100">
    <ul class="navbar-nav">
      <li v-for="modulo in menu" :key="modulo.id" class="nav-item">
        <sidenav-collapse
          :collapse-ref="modulo.titulo_modulo"
          :nav-text="modulo.titulo_modulo"
          :class="getRoute() === formatRoute(modulo.titulo_modulo) ? 'active' : ''"
        >
          <template #icon>
            <component :is="iconComponents(modulo.titulo_icone)"></component>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <sidenav-item
                v-for="pagina in modulo.paginas"
                :key="pagina.id"
                :title="pagina.titulo_pagina"
                :to="{ name: pagina.titulo_pagina }"
                :mini-icon="pagina.titulo_pagina.charAt(0).toUpperCase()"
                :text="pagina.titulo_pagina"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>

<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import BookIcon from "../../components/Icons-FontAwesome/BookIcon.vue";
import UserPlusIcon from "../../components/Icons-FontAwesome/UserPlusIcon.vue";
import CogIcon from "../../components/Icons-FontAwesome/CogIcon.vue";
import UsersCogIcon from "../../components/Icons-FontAwesome/UsersCogIcon.vue";
import ChildIcon from "../../components/Icons-FontAwesome/ChildIcon.vue";
import LaptopIcon from "../../components/Icons-FontAwesome/LaptopIcon.vue";
import UserFriendsIcon from "../../components/Icons-FontAwesome/UserFriendsIcon.vue";
import AddressBookIcon from "../../components/Icons-FontAwesome/AddressBookIcon.vue";
import AwardIcon from "../../components/Icons-FontAwesome/AwardIcon.vue";
import BanIcon from "../../components/Icons-FontAwesome/BanIcon.vue";
import BellIcon from "../../components/Icons-FontAwesome/BellIcon.vue";
import BellSlashIcon from "../../components/Icons-FontAwesome/BellSlashIcon.vue";
import BookMarkIcon from "../../components/Icons-FontAwesome/BookMarkIcon.vue";
import CarSideIcon from "../../components/Icons-FontAwesome/CarSideIcon.vue";
import ChartLineIcon from "../../components/Icons-FontAwesome/ChartLineIcon.vue";
import CheckIcon from "../../components/Icons-FontAwesome/CheckIcon.vue";
import ClipBoardIcon from "../../components/Icons-FontAwesome/ClipBoardIcon.vue";
import CommentDotsIcon from "../../components/Icons-FontAwesome/CommentDotsIcon.vue";
import CopyIcon from "../../components/Icons-FontAwesome/CopyIcon.vue";
import DonateIcon from "../../components/Icons-FontAwesome/DonateIcon.vue";
import WheelChair from "../../components/Icons-FontAwesome/WheelChair.vue";
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "SidenavList",
  components: {
    BanIcon,
    BookIcon,
    BellIcon,
    AwardIcon,
    SidenavItem,
    SidenavCollapse,
    UserPlusIcon,
    BellSlashIcon,
    CogIcon,
    UsersCogIcon,
    ChildIcon,
    BookMarkIcon,
    UserFriendsIcon,
    LaptopIcon,
    AddressBookIcon,
    CarSideIcon,
    ChartLineIcon,
    CheckIcon,
    ClipBoardIcon,
    CommentDotsIcon,
    CopyIcon,
    DonateIcon,
    WheelChair,
  },
  props: {
    cardBg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menu: [],
      longText: "pagina.titulo_pagina",
      maxLength: 15,
    };
  },
  computed: {
    abbreviatedText() {
      if (this.longText.length <= this.maxLength) {
        return this.longText;
      } else {
        return this.longText.substring(0, this.maxLength - 3) + "...";
      }
    },
    ...mapState(["isRTL"]),
    profile() {
      return this.$store.getters["profile/profile"];
    },
    requireAdmin() {
      if (this.$store.getters["auth/loggedIn"] && this.profile) {
        if (this.profile.roles[0].name == "admin") return true;
      }
      return false;
    },
    requireCreator() {
      if (this.$store.getters["auth/loggedIn"] && this.profile) {
        const aux = this.profile.roles[0].name;
        if (aux == "admin" || aux == "creator") return true;
      }
      return false;
    },
  },
  async created() {
    this.fetchMenuData();
    if (this.$store.getters["auth/loggedIn"]) {
      try {
        this.profileChange = { ...this.profile };
      } catch (error) {
        try {
          await this.$store.dispatch("auth/logout");
        } finally {
          let path_cidade = localStorage.getItem("path_cidade");
          this.$router.push(`/${path_cidade}/login`);
        }
      }
    }
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    async fetchMenuData() { 
      var perfil =  localStorage.getItem('perfilId');
      if (perfil == null || perfil == undefined) {
        return;
      }
      const API_URL = process.env.VUE_APP_API_BASE_URL + `/menu/${perfil}`;
      axios.get(API_URL)
        .then(response => {
          this.menu = response.data;
          this.$store.commit("atualizarPermissoes", this.menu);
        })
        .catch(error => {
          console.error('Erro ao buscar os dados:', error);
        });
    },
    iconComponents(icone) {
      const iconMap = {
        AddressBookIcon, 
        AwardIcon,
        BanIcon,
        BellIcon,
        BellSlashIcon,
        BookIcon,
        BookMarkIcon,
        CarSideIcon,
        ChartLineIcon,
        CheckIcon,
        ChildIcon,
        ClipBoardIcon,
        CogIcon,
        CommentDotsIcon,
        CopyIcon,
        DonateIcon,
        UserPlusIcon,
        LaptopIcon,
        UserFriendsIcon,
        UsersCogIcon,
        SidenavItem,
        SidenavCollapse,
        UserPlusIcon,
        WheelChair,
      };

      return iconMap[icone] || null;
    },
    formatRoute(str) {
      return this.removeAccents(str.toLowerCase());
    },
    removeAccents(str) {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    },
  },
};
</script>
<style>
  a.active.router-link-exact-active.nav-link {
    white-space: break-spaces;
  }
</style>