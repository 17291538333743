<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12"> 
        <div class="card table">
          <div class="pb-0 card-header">
            <div class="w-100">
              <div class="row">
                <div class="up col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <SoftDropdown2 v-model="selected.cfop" :placeholderText="'CFOP'" :minCharacters="0" :uniqueOptions="true" :columnFilter="'cfop'" :rota="'/consulta_cnae/tabela_cfop/select2'" ref="acaoDropdown"/>
                </div>
                <div class="up col-12 col-xs-12 col-sm-6 col-md-4 col-lg-6 div-filter">
                  <SoftDropdown2 v-model="selected.descricao_resumida" :placeholderText="'Descrição resumida'" :minCharacters="4" :uniqueOptions="true" :columnFilter="'descricao_resumida'" :rota="'/consulta_cnae/tabela_cfop/select2'" ref="acaoDropdown"/>
                </div>
                <div class="up col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <SoftDropdown v-model="selected.tipo_movimentacao" :placeholderText="'Tipo de Movimentação'" :minCharacters="0" :uniqueOptions="true" :columnFilter="'tipo_movimentacao'" ref="acaoDropdown" :data="[{text: '1 - Entradas', value: '1'}, {text: '2 - Saídas', value: '2'}, {text: '0 - Não Aplicado', value: '0'}]"/>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                <div class="d-sm-block d-flex justify-content-center">
                  <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="filtrar">Filtrar</button>
                </div>
              </div>
              </div>
              <div class="pb-0 card-body position-relative">
              <div v-if="isLoading" class="loading_gif">
                <img src="../assets/img/loading.gif" >
              </div>
              <DataTables v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading" :tableName="tableName" :columns="columns" :data="data" :corClass="corDinamica"
                :CanEdit="CanEdit" :CanDelete="CanDelete" :CanAdd="CanAdd" :filtros="filtros" :nome_filtros="nome_filtros" :key="chave"/>
              <div v-if="data.length === 0">
                <h3 class="text-center">Buscando dados</h3>
                <h3 class="text-center">Com esses filtros</h3>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
    import makeTable from '@/mixins/makeTable.js';
    import DataTables from "./components/DataTables";
    import SoftDropdown2 from "@/components/SoftDropdown2";
    import SoftDropdown from "@/components/SoftDropdown";
    import permissionsmixin from '@/mixins/permissions.js';
    import verificacoesmixin from '@/mixins/verificacoes.js';
  
  export default {
    mixins: [permissionsmixin, verificacoesmixin, makeTable],
    name: 'ConsultaCfop',
    components: {
      DataTables,
      SoftDropdown2,
      SoftDropdown
    },
    data() {
      return {
        tableName: "VAF_CFOP",
        columns: ["Código", "Cfop", "Descrição Resumida" ,"Indnfe", "indcomunica", "indtransp", "inddevol", "Tipo de Movimentação"],
        getColumnsData: { 
          "Código": "id",
          "Cfop": "cfop",
          "Descrição Resumida": "descricao_resumida",
          "Indnfe": "indnfe",
          "indcomunica": "indcomunica",
          "indtransp": "indtransp",
          "inddevol": "inddevol",
          "Tipo de Movimentação": "tipo_movimentacao",
        },
        filtros: {
          cfop: null,
          descricao_resumida: null,
          tipo_movimentacao: null,
        },
        nome_filtros: {
          cfop: 'Código',
          descricao_resumida: 'Descrição Resumida',
          tipo_movimentacao: 'Tipo de Movimentação',
        },
        selected: {
          cfop: null,
          descricao_resumida: null,
          tipo_movimentacao: null,
        },
        chave: 0,
        isLoading: true,
      };
    },
    watch: {
      permissoes: {
        handler() {
          this.verificarPermissaoDaPagina('Consulta deCFOPs');
        },
        deep: true,
      },
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
      permissoes() {
        return this.$store.state.permissoesUser;
      },
    },
    methods: {
      stopLoading(){
        this.isLoading = false;
      },
      async filtrar() {
        this.isLoading = true;

        this.filtros.cfop = this.obterDadosSelect(this.selected.cfop, 'value');
        this.filtros.descricao_resumida = this.obterDadosSelect(this.selected.descricao_resumida, 'value');
        this.filtros.tipo_movimentacao = this.obterDadosSelect(this.selected.tipo_movimentacao, 'value');
        
        await this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/consulta_cfop/tabela_cfop`, this.filtros, this.getColumnsData);
      },
    },
    created() {
      if (this.permissoes.length) {
        this.verificarPermissaoDaPagina('Consulta de CFOPs');
      }
    },
    mounted() {
      this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/consulta_cfop/tabela_cfop`, this.filtros, this.getColumnsData);
    },
  };
  </script>
  