<template>
  <ImportModal @update-dad="filtrar" :corClass="corDinamica" :key="chave"/>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="w-100">
              <div class="row">
                <div class="col-2">
                  <button type="button" :class="'btn bg-gradient-'+corDinamica+' btn-sm ml-3'" data-bs-toggle="modal" data-bs-target="#giaImportModalAdd">
                    <i class="fa-solid fa-cloud-arrow-up text-white" style="font-size: 50px"></i>Importar GIA
                  </button>
                </div>
                <div class="col-6">
                  <div class="d-flex flex-column">
                    <strong class="text-center">DADOS DO CONTADOR</strong>
                  </div>
                  
                  <hr style="margin-bottom: 5px; margin-top: 0px;">
                  <b>Nome</b><br>
                  {{ userName }} | {{ userLogin }}

                </div>
                
                <div class="col-4">
                  <div class="d-flex flex-column">
                    <strong class="text-center">{{ arquivosTotal }} Arquivo(s) Enviado(s)</strong>
                    <hr style="margin-bottom: 5px; margin-top: 0px;">
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <div class="d-flex flex-column">
                        <span class="text-center">Sucesso</span>
                        <span class="text-success text-center">{{ arquivosSucessos }}</span>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="d-flex flex-column">
                        <span class="text-center">Processando</span>
                        <span class="text-warning text-center">{{ arquivosProcessando }}</span>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="d-flex flex-column">
                        <span class="text-center">Com erro</span>
                        <span class="text-danger text-center">{{ arquivosErro }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ImportModal @update-dad="filtrar" :corClass="corDinamica"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-0 container-fluid">
    <div class="row ">
      <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto">
    <div class="nav-wrapper position-relative end-0">
      <ul class="p-1 bg-transparent nav nav-pills nav-fill" role="tablist">
        <li class="nav-item">
          <a
            @click="route('1')"
            class="px-0 py-1 mb-0 nav-link active"
            data-bs-toggle="tab"
            role="tab"
            aria-selected="true"
            ><span class="ms-1">Arquivos enviados</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            @click="route('2')"
            class="px-0 py-1 mb-0 nav-link active"
            data-bs-toggle="tab"
            role="tab"
            aria-selected="true"
            ><span class="ms-1">Referência por Empresa</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
</div>
  
  <ArquivosEnviados v-if="rota=='1'" :key="chave"/>
  <ReferenciaEmpresas v-if="rota=='2'" :key="chave"/>
       
</template>
  
<script>
import axios from "axios";
import ArquivosEnviados from "./components/GiaComponents/ArquivosEnviados.vue";
import ReferenciaEmpresas from "./components/GiaComponents/ReferenciaEmpresas.vue";
import ImportModal from "./components/ModalsAdd/giaAddImport.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import permissionsmixin from '@/mixins/permissions.js';

export default {
  mixins: [permissionsmixin],
  name: "GiaView",
  components: {
    ImportModal,
    ArquivosEnviados,
    ReferenciaEmpresas,
  },
  data() {
    return {
      rota: '1',
      chave: 0,
      userName: null,
      userLogin: null,
      arquivosTotal: null,
      arquivosSucessos: null,
      arquivosProcessando: null,
      arquivosErro: null,
    };
  },
  watch: {
    permissoes: {
      handler() {
        this.verificarPermissaoDaPagina('Gia Contador');
      },
      deep: true,
    },
  },
  computed: {
    corDinamica() {
      return this.$store.state.corDinamica;
    },
    permissoes() {
      return this.$store.state.permissoesUser;
    },
  },
  methods: {
    filtrar() {
      this.chave++;
      this.getArquivos();
    },
    route(value){
      this.getArquivos();
      this.rota = value;
    },
    getArquivos() {
      const API_URL = process.env.VUE_APP_API_BASE_URL + '/gia/check_arquivos/';
      axios.get(API_URL, {params: {
       cnpj: localStorage.getItem('login'),
       id_cidade: localStorage.getItem('id_cidade') 
      }
        
      }).then((response) => {
        this.arquivosTotal = response.data.total;
        this.arquivosSucessos = response.data.sucesso;
        this.arquivosProcessando = response.data.processando;
        this.arquivosErro = response.data.erro;
      });
    },
  },
  created() {
   
    this.getArquivos();
    const cor = localStorage.getItem('corDinamica');
    this.$store.commit("atualizarCorDinamica", cor);
    this.userName = localStorage.getItem('nome');
    this.userLogin = localStorage.getItem('login');
    if (this.userLogin.length > 11){
      //mask cnpj
      this.userLogin = this.userLogin.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }else{
      //mask cpf
      this.userLogin = this.userLogin.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }
    if (this.permissoes.length) {
      this.verificarPermissaoDaPagina('Gia Contador');
    }
  },
  
  mounted() {
    setNavPills();
  },
};
</script>
