<template>
  <div class="row p-5">
    <div class="col-xl-6">
      <TotalizadorComponent title="Gias Importadas" :value="totalGiasImportadas" :text-icon="textIcon1" :cor-class="corDinamica"/>
    </div>
    <div class="col-xl-6 ms-auto">
      <TotalizadorComponent title="Valor Adicionado" :value="totalVa" :text-icon="textIcon2" :cor-class="corDinamica"/>
    </div>
  </div>
  <div class="row p-5">
    <div class="col-6">
      <VaRelacaoAnoAnteriorChart/>
    </div>
    <div class="col-6">
      <ComparacaoIpm5anosChart/>
    </div>
  </div>
  <div class="row p-5">
    <div class="col-12">
      <RankingEmpresasChart/>
    </div>
  </div>
  <div class="row p-5">
    <div class="col-6">
      <VaCnae6MesesChart/>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import TotalizadorComponent from '../components/TotalizadorComponent.vue';
  import permissionsmixin from '../mixins/permissions.js';
  import VaRelacaoAnoAnteriorChart from './components/Charts/VaRelacaoAnoAnteriorChart.vue';
  import ComparacaoIpm5anosChart from './components/Charts/ComparacaoIpm5anosChart.vue';
  import RankingEmpresasChart from './components/Charts/RankingEmpresasChart.vue';
  import VaCnae6MesesChart from './components/Charts/VaCnae6MesesChart.vue';
  
  export default {
    mixins: [permissionsmixin],
    components: {
      TotalizadorComponent,
      VaRelacaoAnoAnteriorChart,
      ComparacaoIpm5anosChart,
      RankingEmpresasChart,
      VaCnae6MesesChart
    },
    data() {
      return {
        totalGiasImportadas: '',
        totalVa: '',
        textIcon1: '<span class="text-white"><b>Total </b></span><i class="fas fa-arrow-up text-white mb-0"></i>',
        textIcon2: `<span class="text-white"><b>${new Date().getFullYear()} </b><i class="fa-regular fa-calendar-days"></i></span>`
      }
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
      permissoes() {
        return this.$store.state.permissoesUser;
      },
    },
    created() {
      if (this.permissoes.length) {
      this.verificarPermissaoDaPagina('Módulos');
    }
      const cor = localStorage.getItem('corDinamica');
      this.$store.commit("atualizarCorDinamica", cor);
      this.verificarPermissoes('Gráficos');
      this.fetchDataGiaImportadas();
      this.fetchDataValorAdicionado();
    },
    methods: {
      fetchDataGiaImportadas() {
        const API_URL = process.env.VUE_APP_API_BASE_URL + `/gia/total_gias_importadas`;
        axios.get(API_URL, {params: {id_cidade: localStorage.getItem('id_cidade')}})
          .then(response => {
            this.totalGiasImportadas = String(response.data.total);
          })
          .catch(error => {
            console.error('Erro ao obter os dados:', error);
          });
      },
      fetchDataValorAdicionado() {
        const API_URL = process.env.VUE_APP_API_BASE_URL + `/va/total_p_ano`;
        axios.get(API_URL, {params: {id_cidade: localStorage.getItem('id_cidade')}})
          .then(response => {
            this.totalVa = String(response.data.valoradicionado);
          })
          .catch(error => {
            console.error('Erro ao obter os dados:', error);
          });
      },
      
    }
  };
  </script>
  