<template>
    <div>
        <div class="modal fade" id="perfilModalAdd" tabindex="-1" role="dialog">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">

                <div class="row">
                  <div class="col-11">
                    <h5 class="modal-title-perfil">Perfil de Acesso</h5>
                  </div>
                  <div class="col-1 text-end">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Fechar">
                      &times;
                    </button> 
                  </div>
                </div>

              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-6 form-group">
                      <label for="titulo_perfil_perfil_acesso_add">Titulo do perfil</label>
                      <input v-model="selected.titulo_perfil" type="text" class="form-control" id="titulo_perfil_perfil_acesso_add" name="titulo_perfil_perfil_acesso_add" placeholder="Titulo do Perfil">
                    </div>
                 
                    <div class=" col-6 form-group">
                      <label for="ordem_perfil_perfil_acesso_add">Ordem do perfil</label>
                      <input type="number" v-model="selected.ordem_perfil" class="form-control" id="ordem_perfil_perfil_acesso_add" name="ordem_perfil_perfil_acesso_add" placeholder="Digite a ordem">
                    </div>
                  </div>
                             
                </form> 
              </div>
              <div class="modal-footer">
                <soft-button class="my-4 mb-2" variant="gradient" color="secondary" data-bs-dismiss="modal">
                  <span>Cancelar</span>
                </soft-button>
                <div style="width: 100px;">
                  <soft-button class="my-4 mb-2" variant="gradient" :color="corClass" full-width @click="SalvarPerfil()"
                    :is-disabled="loading ? true : false">
                    <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                    <span v-else>Salvar</span>
                  </soft-button>
                </div>
              </div>
            </div>
          </div>
        </div> 
        
      </div>
</template>
<script>
import axios from 'axios';
import SoftButton from '../../../components/SoftButton.vue';
import verificacoesmixin from '../../../mixins/verificacoes.js';

export default {
  mixins: [verificacoesmixin],
  props:{
    corClass: String
  },
  name: 'AddPerfilAcesso',
  data() {
    return {
      loading: false,
      selected: {
        titulo_perfil: null,
        ordem_perfil: null,
      },
    };
  },
  components: {
    SoftButton
  },
  methods: {
    SalvarPerfil() {
      this.loading = true;

      if (this.verificarCamposVazios(this.selected)) {
        this.loading = false;
        return;
      }

      const user = localStorage.getItem('user');
      const id_cidade = localStorage.getItem('id_cidade');

      const apiUrl = process.env.VUE_APP_API_BASE_URL + '/perfil-acesso/adicionar';
        axios.post(apiUrl,{
          perfil_title: this.selected.titulo_perfil,
          profile_order: this.selected.ordem_perfil,
          id_cidade: id_cidade,
          user: user
      })
      .then(response => {
        if (response.data.success){
          this.clearUpData();
          $('#perfilModalAdd').modal('hide');
          this.showMessage('success', 'Perfil adicionado com sucesso!')
          this.$emit('update-dad');
        } else {
          this.showMessage('danger', 'Erro ao adicionar perfil!')
        }
      })
      .catch(() => {
        this.showMessage('danger', 'Erro ao adicionar perfil!')
      })
      .finally(() => {
        this.loading = false;
      })
    },
    clearUpData() {
      this.selected = {
        titulo_perfil: null,
        ordem_perfil: null,
      };
    },
  },
};
</script>

<style scoped>

</style>
