<template>      
  
    <div class="table-responsive">
     
      <table id="myTable" class="table table-flush">
        <thead class="thead-light">
          <tr>
            <th v-for="column in columns" :key="column">{{ column }}</th>
            <th v-if="permissions || CanHomologar || CanReativar || CanEdit || CanDelete || CanDetail || CanDeleteUser || CanEditUser || CanDowloadArq || CanVincular">Ação</th>
          </tr>
        </thead>
        <tbody class="text-sm">
          <tr v-for="dados in data" :key="dados.Id" :class="{'bg-danger': dados['Status'] == 'Inativo'}">
            <td v-for="column in columns" :key="column" v-html="dados[column] !== null ? dados[column] : ' - '"></td>
            <td v-if="(permissions || CanHomologar || CanReativar || CanEdit || CanDelete || CanDetail || CanDeleteUser || CanEditUser || CanDowloadArq || CanVincular) && dados.Id !== '-'">
              <a v-if="permissions" onclick="PermissionAccess(this)" :value="dados.Id" :perfil="dados['Título Perfil']" class="actionButton cursor-pointer me-3" data-bs-toggle="modal" data-bs-target="#PermissionAccess" title="Permissão de Perfil de Acesso">
                <i class="fas fa-lock text-secondary"></i>
              </a>
              <a v-if="CanHomologar && dados.Homologado === 'Não'" onclick="Homologar(this)" :value="dados.Id" class="actionButton cursor-pointer me-3"  title="Homologar">
                <i class="fa fa-check text-secondary"></i>
              </a>

              <a v-if="CanVincular && (dados['Situação do Vínculo'] == 'Aguardando contribuinte' && tableName != 'CONTRIBUINTES VINCULADOS' || dados['Situação do Vínculo'] == 'Aguardando contador' && tableName == 'CONTRIBUINTES VINCULADOS')" onclick="Vincular(this)" :value="dados.Id" :tipo="dados['Situação do Vínculo']" class="actionButton cursor-pointer me-3"  title="Vincular">
                <i class="fa fa-check text-secondary"></i>
              </a>

              <a v-if="CanReativar && dados['Status'] === 'Inativo'" onclick="Reativar(this)" :value="dados.Id" class="actionButton cursor-pointer me-3"  title="Reativar">
                <i class="fa fa-arrow-rotate-left text-white"></i>
              </a>

              <a v-if="CanEdit || (tableName === 'USUÁRIOS' && CanEditUser && dados['Status'] === 'Ativo')" onclick="editModal(this)" id="value_edit" :value="dados.Id" class="actionButton cursor-pointer me-3" data-bs-toggle="modal" :data-bs-target="idModalEdit" title="Editar">
                <i class="fas fa-edit text-secondary"></i>
              </a>
              <a v-if="CanDelete && tableName !='CONTRIBUINTES VINCULADOS' && tableName !='CONTADOR VINCULADO' || 
              ((tableName === 'CONTRIBUINTES VINCULADOS' || tableName === 'CONTADOR VINCULADO') && CanDelete && dados['Situação do Vínculo'] != 'Inativo') ||
              (tableName === 'USUÁRIOS' && CanDeleteUser  && dados['Status'] === 'Ativo')"
              onclick="deleteModal(this)" :value="dados.Id" class="actionButton cursor-pointer" :title="TextDelete ? TextDelete : 'Deletar'">
                 <i class="fas fa-trash text-secondary"></i>
              </a>
              <a v-if="CanDetail && !AcaoIncludes.includes(dados['Ação'])" onclick="detailModal(this)" :value="dados.Id" class="actionButton cursor-pointer" data-bs-toggle="modal" :data-bs-target="idModalDetail" title="Detalhes">
                <i class="fas fa-eye text-secondary" style="margin-left: 30%;"></i>
              </a>
              <a v-if="CanDowloadArq" onclick="downloadArq(this)" :value="dados.Id" :valorName="dados['Arquivo Enviado']" class="actionButton cursor-pointer" title="Fazer download">
                 <i class="fas fa-download text-secondary"></i>
              </a>
              <a v-if="CanExportPDF" onclick="exportArqPDF(this)" :value="dados.Id" class="actionButton cursor-pointer" title="Exportar comprovante">
                <i class="fas fa-file-pdf text-secondary" style="margin-left: 30%;"></i>
              </a>
              

            </td>
            <td v-else-if="dados.Id === '-'">-</td>
          </tr>
        </tbody>
      </table>
    </div>

  <div class="d-flex justify-content-center justify-content-sm-between flex-wrap" style="padding: 24px 24px 0px"></div>
  
  <div v-if="permissions">
    <component :is="dynamicComponentPermissionAccess" :id="selectedId" :perfil="selectedPerfil" :corClass="corClass" ref="childComponentAccess" />
  </div>
  <component v-if="CanEdit || tableName === 'USUÁRIOS' && CanEditUser" :is="dynamicComponentEdit" @update-dad="updateDad" :corClass="corClass" :id="selectedId" ref="childComponent" :key="chave"/>
  <component v-if="CanDetail" :is="dynamicComponentDetail" @update-dad="updateDad" :corClass="corClass" :id="selectedId" ref="childDetail" :key="chave"/>
 
</template>
  
<script>
import DataTable from 'datatables.net-dt';
import { DataTable as datatable} from "simple-datatables";
import gradientColors from '@/assets/js/cores-gradientes.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import { defineAsyncComponent } from 'vue';
import axios from 'axios';
import showSwal from "/src/mixins/showSwal.js";
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
  emits: ['updateDad', 'is-loading', 'update-dad'],
  props: {
    tableName: String,
    columns: Array,
    data: Array,
    componentPermissionAccess: String,
    componentEdit: String,
    componentDetail: String,
    routeDelete: String,
    corClass: String,
    permissions: Boolean,
    CanReativar: Boolean,
    CanHomologar: Boolean,
    CanAdd: Boolean,
    CanEdit: Boolean,
    CanEditUser: Boolean,
    CanDelete: Boolean,
    CanDeleteUser: Boolean,
    CanDetail: Boolean,
    CanVincular: Boolean,
    CanDowloadArq: Boolean,
    CanExportPDF: Boolean,
    routeHomologar: String,
    idModalAdd: String,
    idModalEdit: String,
    idButtonImport: String,
    idModalDetail: String,
    TextButtonAdd: String,
    TextButtonImport: String,
    filtros: Object,
    nome_filtros: Object,
    preHiddenColumns: Array,
    TextDelete: String,
    },
    data() {
      return {
        selectedId: null,
        selectedPerfil: null,
        dataTableSearch: null,
        loading: false,
        hiddencolumns: [],
        chave: 0,
        AcaoIncludes: ['Logout', 'Login'],
      };
    },
    watch: {
      data: {
        handler(newData) {
          if (newData.length > 0) {
            this.initializeDataTable();
          }
        },
        deep: true,
      },
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
      dynamicComponentPermissionAccess() {
        if (this.permissions) {
          return defineAsyncComponent(() => import(`./${this.componentPermissionAccess}.vue`));
        }
        return null;
      },
      dynamicComponentEdit() {
        return (defineAsyncComponent(() => import(`./${this.componentEdit}.vue`)));
      },
      dynamicComponentDetail() {
        return (defineAsyncComponent(() => import(`./${this.componentDetail}.vue`)));
      },
    },
    methods: {
      async hidecolumns(colunaId){
        const index = this.hiddencolumns.indexOf(colunaId);
        if (index > -1) {
            this.hiddencolumns.splice(index, 1);
        } else {
            this.hiddencolumns.push(colunaId);
        }
        this.initializeDataTable(this.hiddencolumns);
      },
      async pdf() {
        const tableData = this.data.map(dados => {
            const row = {};
            this.columns.forEach(column => {
                if (!this.hiddencolumns.includes(this.columns.indexOf(column))) {
                    row[column] = dados[column];
                }
            });
            return row;
        });
        if (this.tableName === 'GIA') {
          tableData.forEach(element => {
            const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
            months.forEach(month => {
                if (element[month].includes('GIA omissa')) {
                    element[month] = 'Omissa';
                } else if (element[month].includes('GIA entregue')) {
                    element[month] = 'Entregue';
                } else if (element[month].includes('GIA não disponivel')) {
                    element[month] = 'Não disponível';
                }
            });
          });
        }
        let filename = "tabela-" + this.tableName.toLowerCase() + ".pdf";
        const user = localStorage.getItem('nome');
        const id_cidade = localStorage.getItem('id_cidade');
        const API_URL = process.env.VUE_APP_API_BASE_URL + `/tabela.pdf`;
        try {
            const response = await axios.post(API_URL, {
              dados: tableData,
              user: user,
              id_cidade: id_cidade,
              filtros: this.filtros,
              nome_filtros: this.nome_filtros,
              nome_relatorio: this.tableName,
              }, {
                responseType: 'arraybuffer',
            });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            window.open(url, filename);

        } catch (error) {
            console.error('Erro ao baixar o PDF:', error);
          }
        },

      async csv() {
        let length = this.columns.length;
        await this.hidecolumns(length);
        let tableEl = document.getElementById('myTable');
        let rows = tableEl.querySelectorAll('tr');
        let data = [];
        let headerRow = rows[0];
        let headerCells = headerRow.querySelectorAll('th');
        let headers = [];
        headerCells.forEach(cell => {
            headers.push(cell.textContent);
        });
        data.push(headers);

        for (let i = 1; i < rows.length; i++) {
            let cells = rows[i].querySelectorAll('td');
            let rowData = [];
            cells.forEach(cell => {
                let cellValue = /^\d+$/.test(cell.textContent) ? parseFloat(cell.textContent) : cell.textContent;
                rowData.push(cellValue);
            });
            data.push(rowData);
        }

        const csvString = data.map(row => row.join(';')).join('|');
        const lines = csvString.split('|').filter(Boolean);

        const result = lines.map(line => [line, '']);

        const API_URL = process.env.VUE_APP_API_BASE_URL + `/tabela.csv`;
        axios.post(API_URL, {
          dados: result,
        }, {
          responseType: 'arraybuffer', 
        })
        .then(response => { 
           const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            saveAs(blob, 'tabela-' + this.tableName.toLowerCase() +'.xlsx');
        })

          await this.hidecolumns(length);
        },
      async excel() {
        let length = this.columns.length;
        await this.hidecolumns(length);
        let tableEl = document.getElementById('myTable');
        let rows = tableEl.querySelectorAll('tr');
        let data = [];
        let headerRow = rows[0];
        let headerCells = headerRow.querySelectorAll('th');
        let headers = [];
        headerCells.forEach(cell => {
            headers.push(cell.textContent);
        });
        data.push(headers);

        for (let i = 1; i < rows.length; i++) {
            let cells = rows[i].querySelectorAll('td');
            let rowData = [];
            cells.forEach(cell => {
                let cellValue = /^\d+$/.test(cell.textContent) ? parseFloat(cell.textContent) : cell.textContent;
                rowData.push(cellValue);
            });
            data.push(rowData);
        }

        const API_URL = process.env.VUE_APP_API_BASE_URL + `/tabela.excel`;
        let filename = "tabela-" + this.tableName.toLowerCase() + ".xlsx";
        const user = localStorage.getItem('nome');
        const id_cidade = localStorage.getItem('id_cidade');
        axios.post(API_URL, {
          dados: data,
          user: user,
          id_cidade: id_cidade,
          filtros: this.filtros,
          nome_filtros: this.nome_filtros,
          nome_relatorio: this.tableName,
        }, {
          responseType: 'arraybuffer', 
        })
        .then(response => { 
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'tabela-' + this.tableName.toLowerCase() +'.xlsx');
        })

        await this.hidecolumns(length);
      },
      updateDad(){
        this.chave += 1;
        this.$emit('update-dad');
      },
      downloadArq(element) {
        const value = element.getAttribute('value')
        const id = parseInt(value);
        const name = element.getAttribute('valorName')
        const API_URL = process.env.VUE_APP_API_BASE_URL + '/gia/arquivos_enviados/download/'+ id;
        axios.get(API_URL, {
          responseType: 'arraybuffer', 
        
        })
        .then(response => { 
          const arrayBuffer = response.data;
          const uint8Array = new Uint8Array(arrayBuffer);

          if (uint8Array.length === 51) {
            const text = new TextDecoder('utf-8').decode(uint8Array);
            if (text.includes('Erro') || text.includes('error') || text.includes('erro')) {
              showSwal.methods.showSwal({
                type: "danger",
                message: "Erro ao baixar o arquivo!",
                width: 450,
              });
              return;
            } 
          } 
          try {
            const blob = new Blob([response.data], { type: 'application/octet-stream' }); 
            saveAs(blob, name);
            showSwal.methods.showSwal({
              type: "success",
              message: "Sucesso ao baixar o arquivo!",
              width: 450,
            });
          } catch (error) {
            showSwal.methods.showSwal({
              type: "danger",
              message: "Erro ao baixar o arquivo!",
              width: 450,
            });
          }
          

        })
      },
      editModal(element) {
        const id = element.getAttribute('value')
        this.selectedId = parseInt(id);
        this.$nextTick(() => {
          if (this.$refs.childComponent) {
            this.$refs.childComponent.WhenModalOpen();
          }
        });
      },
      PermissionAccess(element) {
        const id = element.getAttribute('value')
        const perfil = element.getAttribute('perfil')
        this.selectedPerfil = perfil;
        this.selectedId = parseInt(id);
        this.$nextTick(() => {
          if (this.$refs.childComponentAccess) {
            this.$refs.childComponentAccess.WhenModalOpen();
          }
        });
        
      },
      detailModal(element) {
        const id = element.getAttribute('value')
        this.selectedId = parseInt(id);
        this.$nextTick(() => {
          if (this.$refs.childDetail) {
            this.$refs.childDetail.WhenModalOpen();
          }
        });
        
      },
      async exportArqPDF(element){
        const id = element.getAttribute('value')
        this.selectedId = parseInt(id);
        let filename = "tabela-" + this.tableName.toLowerCase() + ".pdf";
        const user = localStorage.getItem('nome');
        const id_cidade = localStorage.getItem('id_cidade');
        const API_URL = process.env.VUE_APP_API_BASE_URL + `/comprovante_gia.pdf`;

        try {
            const response = await axios.post(API_URL, {
              user: user,
              id_cidade: id_cidade,
              id: this.selectedId,
              }, {
                responseType: 'arraybuffer',
            });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            window.open(url, filename);

        } catch (error) {
            console.error('Erro ao baixar o PDF:', error);
        }
      },
      Homologar(element) {
        const id = element.getAttribute('value')
        const user = localStorage.getItem('user');
        const id_cidade = localStorage.getItem('id_cidade');
        
        this.selectedId = parseInt(id);
        this.$swal({
            title: "Deseja homologar esse usuário?",
            text: "Não será possivel reverter essa ação!",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: `<div style="display: inline-block; width: 80px;">
                ${this.loading ? '<span class="spinner-border spinner-border-sm"></span>' : 'Homologar'}</div>`,
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            customClass: {
              confirmButton: `btn bg-gradient-${this.corClass}`,
              cancelButton: "btn cancel-btn bg-gradient-secondary",
            },
            buttonsStyling: false,
            }).then((result) => {
            if (result.isConfirmed) {
              this.loading = true;
              const API_URL2 = process.env.VUE_APP_API_BASE_URL + `/usuarios/homologar/${id}`;
              axios.patch(API_URL2, {
                user: user,
                id_cidade: id_cidade
              })
              .then(response => { 
                  if (response.data.message !== 'Sucesso'){
                    this.loading = false;
                      showSwal.methods.showSwal({
                          type: "danger",
                          message: "Erro ao homologar o usuário!",
                          width: 450,
                      });
                      
                  }else{
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Sucesso ao homologar o usuário!",
                        width: 450,
                    });
                    this.loading = false;
                    this.$emit('update-dad');
                  
                  }
              })
            } else if (
                
                result.dismiss === this.$swal.DismissReason.cancel
            ) {
                this.$swal.dismiss;
            }
            });
      },
      Reativar(element) {
        const id = element.getAttribute('value')
        const user = localStorage.getItem('user');
        const id_cidade = localStorage.getItem('id_cidade');
        
        this.selectedId = parseInt(id);
        this.$swal({
            title: "Deseja reativar esse usuário?",
            text: "",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: `<div style="display: inline-block; width: 80px;">
                ${this.loading ? '<span class="spinner-border spinner-border-sm"></span>' : 'Reativar'}</div>`,
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            customClass: {
              confirmButton: `btn bg-gradient-${this.corClass}`,
              cancelButton: "btn cancel-btn bg-gradient-secondary",
            },
            buttonsStyling: false,
            }).then((result) => {
            if (result.isConfirmed) {
              this.loading = true;
              const API_URL2 = process.env.VUE_APP_API_BASE_URL + `/usuarios/reativar/${id}`;
              axios.patch(API_URL2, {
                user: user,
                id_cidade: id_cidade
              })
              .then(response => { 
                  if (response.data.message !== 'Sucesso'){
                    this.loading = false;
                      showSwal.methods.showSwal({
                          type: "danger",
                          message: "Erro ao reativar o usuário!",
                          width: 450,
                      });
                  }else{
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Sucesso ao reativar o usuário!",
                        width: 450,
                    });
                    this.loading = false;
                    this.$emit('update-dad');
                  }
              })
            } else if (
                
                result.dismiss === this.$swal.DismissReason.cancel
            ) {
                this.$swal.dismiss;
            }
            });
      },
      Vincular(element){
        const id = element.getAttribute('value')
        const tipo = element.getAttribute('tipo')
        const user = localStorage.getItem('user');
        const id_cidade = localStorage.getItem('id_cidade');
        let perfil = '';
        let rota = ''
        if (tipo == 'Aguardando contribuinte'){
          perfil = 'contador';
          rota = 'rel_contribuinte_contador'
        } else {
          perfil = 'contribuinte';
          rota = 'rel_contador_contribuinte'
        }
        this.selectedId = parseInt(id);
        this.$swal({
            title: `Deseja vincular a esse ${perfil}?`,
            text: "",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: `<div style="display: inline-block; width: 80px;">
                ${this.loading ? '<span class="spinner-border spinner-border-sm"></span>' : 'Vincular'}</div>`,
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            customClass: {
              confirmButton: `btn bg-gradient-${this.corClass}`,
              cancelButton: "btn cancel-btn bg-gradient-secondary",
            },
            buttonsStyling: false,
            }).then((result) => {
            if (result.isConfirmed) {
              this.loading = true;
              const API_URL2 = process.env.VUE_APP_API_BASE_URL + `/${rota}/vincular/${id}`;
              axios.patch(API_URL2, {
                user: user,
                id_cidade: id_cidade
              })
              .then(response => {  
                  if (!response.data.success){
                    this.loading = false;
                      showSwal.methods.showSwal({
                          type: "danger",
                          message: response.data.message,
                          width: 450,
                      });
                  }else{
                    showSwal.methods.showSwal({
                        type: "success",
                        message: response.data.message,
                        width: 450,
                    });
                    this.loading = false;
                    this.$emit('update-dad');
                  }
              })
              .catch(error => {
                this.loading = false;
                showSwal.methods.showSwal({
                  type: "danger",
                  message: 'Erro ao vincular!',
                  width: 450,
                });
              })
            } else if (
                
                result.dismiss === this.$swal.DismissReason.cancel
            ) {
                this.$swal.dismiss;
            }
            });
      },
      deleteModal(element) {
        const id = element.getAttribute('value')
        const user = localStorage.getItem('user');
        const id_cidade = localStorage.getItem('id_cidade');
        const id_perfil = localStorage.getItem('perfilId');
        let title =  "Realmente deseja deletar?"
        if (this.TextDelete) {
          title = "Realmente deseja " + (this.TextDelete).toLowerCase() + "?"
        }
        this.selectedId = parseInt(id);
        this.$swal({
            title: title,
            text: "Não será possivel reverter essa ação!",
            icon: "error",
            showCancelButton: true,
            confirmButtonText: `<div style="display: inline-block; width: 65px;">
                ${this.loading ? '<span class="spinner-border spinner-border-sm"></span>' : this.TextDelete ? this.TextDelete : 'Deletar'}</div>`,
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            customClass: {
                confirmButton: `btn bg-gradient-${this.corClass}`,
                cancelButton: "btn cancel-btn bg-gradient-secondary",
            },
            buttonsStyling: false,
            }).then((result) => {
            if (result.isConfirmed) {
              this.loading = true;
              const API_URL = process.env.VUE_APP_API_BASE_URL + `/${this.routeDelete}/${id}`;
              axios.delete(API_URL, {
                params: {
                user: user,
                id_cidade: id_cidade,
                id_perfil: id_perfil
              }})
              .then(response => {
                  if (response.data.message !== 'Sucesso'){
                    this.loading = false;
                      showSwal.methods.showSwal({
                          type: "danger",
                          message: "Erro ao excluir!",
                          width: 450,
                      });
                      
                  }else{
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Sucesso ao excluir!",
                        width: 450,
                    });
                    this.loading = false;
                    this.$emit('update-dad');
                  
                  }
              })
            } else if (
                
                result.dismiss === this.$swal.DismissReason.cancel
            ) {
                this.$swal.dismiss;
            }
            });
      },
      async initializeDataTable(hiddencolumns) {
        if (this.dataTableSearch) {
          this.dataTableSearch.destroy();
        }
        var addbutton = `<div>
            <button id="datatable-open-modal-add" class="mb-0 btn bg-gradient-${this.corClass} btn-sm" data-bs-toggle="modal" data-bs-target="${this.idModalAdd}"> ${this.TextButtonAdd} </button>
          </div>`

        var buttonImport = `<div>
            <button style="margin-left:1%;" id="datatable-open-modal-import" class="mb-0 btn bg-gradient-${this.corClass} btn-sm" data-bs-toggle="modal" data-bs-target="${this.idButtonImport}"> ${this.TextButtonImport} </button>
          </div>`

        if (this.idModalAdd === undefined || this.idModalAdd === null || this.idModalAdd === '' || !this.CanAdd) {
          var addbutton = "";
        }

        if (this.idButtonImport === '' || this.idButtonImport === null ||  this.idButtonImport === undefined) {
          var buttonImport = "";
        }

        var toolbar = `
          <div class="d-flex justify-content-between flex-wrap">
            <button id="button_hidecolumns" class="buttonfiltercolumn btn bg-gradient-${this.corClass} dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="filtercolumn fa fa-list"></i>
            </button>
            <ul class="dropdown-menu" style="padding-left: 10px;" id="dropdownMenu">
              <span class="spanfiltercolumn">Exibir Colunas</span>
            </ul>

            <div class="dropdown-pdf">
              <button id="button_export_datatables" class="buttonfiltercolumn btn bg-gradient-${this.corClass} dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i style="color: white; font-size: 20px" class="fa-solid fa-print"></i>
              </button>
              <ul class="dropdown-menu">
                <span class="spanfiltercolumn">Exportar</span>
                <li class="dropdown-item" id="pdfItem">PDF</li>
                <li class="dropdown-item" id="csvItem">CSV</li>
                <li class="dropdown-item" id="excelItem">Excel</li>
              </ul>
            </div>
            <div class="dt-search">{search}</div>
          </div>`;
        
        var html = addbutton + buttonImport +toolbar;

        var select = `
        <div class="row dt-bottom">
        <div class="col-4">
          <div class="dt-elements dt-select">{select}linhas por página</div>
        </div>
        <div class="col-4">
          <div class="dt-elements dt-info">{info}</div>
        </div>
        <div class="col-4">
          <div class="dt-pager">{pager}</div>
        </div>
        </div> `

        var html2 = select
        
        this.$nextTick(() => {
          
          this.dataTableSearch = new datatable('#myTable', {
            searchable: true,
            fixedHeight: false,
            perPage: 10,
            labels: {
              placeholder: "Pesquisar...",
              noRows: "Nenhum registro encontrado",
              perPage: "{select} ",
              info: "Mostrando {start} a {end} de {rows} registros",
              noResults: "Nenhum resultado correspondente encontrado",
            },
            layout: {
              top: html,
              bottom: html2,
            },
            columns: [
              {
                select: hiddencolumns,
                hidden: true
              }
            ]
          });
          this.columns.forEach((column, index) => {
            const dropdownMenu = document.getElementById('dropdownMenu');
            const li = document.createElement('li');
            const isHidden = this.hiddencolumns.includes(index);
            li.innerHTML = `
              <div class="form-check form-switch">
                ${column}
                <input
                  type="checkbox"
                  class="mt-1 form-check-input"
                  value="${column}"
                  data-index="${index}"
                  ${isHidden ? '' : 'checked'} 
                />
              </div>`;

              const checkbox = li.querySelector('.form-check-input');
              checkbox.addEventListener('change', (event) => {
                const dataIndex = parseInt(event.target.getAttribute('data-index'), 10);
                this.handleCheckboxChange(dataIndex);
                
              });
            dropdownMenu.appendChild(li);
          });
          const dropdownMenu = document.getElementById('dropdownMenu');
          const div = document.createElement('div');
          div.innerHTML = `<div class="div_aplicar"><button id="button_aplicar_colunas" class="btn btn-aplicar bg-gradient-${this.corClass}">Aplicar</button></div>`;
          dropdownMenu.appendChild(div);

          const pdfItem = document.getElementById('pdfItem');
          const csvItem = document.getElementById('csvItem');
          const excelItem = document.getElementById('excelItem');
          const hidecolumnsItem = document.getElementById('button_aplicar_colunas');

          pdfItem.addEventListener('click', () => this.pdf());
          csvItem.addEventListener('click', () => this.csv());
          excelItem.addEventListener('click', () => this.excel());
          hidecolumnsItem.addEventListener('click', () => this.hidecolumns());
        });
        this.$emit('is-loading');
      },
      async handleCheckboxChange(colunaId) {
        const index = this.hiddencolumns.indexOf(colunaId);
        if (index > -1) {
            this.hiddencolumns.splice(index, 1);
        } else {
            this.hiddencolumns.push(colunaId);
        }
      },

    },
    async mounted() {
      if (this.preHiddenColumns) {
        this.hiddencolumns = this.preHiddenColumns;
      }
      if (this.data.length > 0) {
        this.initializeDataTable(this.hiddencolumns);
      }
      

      window.editModal = this.editModal;
      window.PermissionAccess = this.PermissionAccess;
      window.Homologar = this.Homologar;
      window.deleteModal = this.deleteModal;
      window.Reativar = this.Reativar;
      window.detailModal = this.detailModal;
      window.downloadArq = this.downloadArq;
      window.Vincular = this.Vincular;
      window.exportArqPDF = this.exportArqPDF;

      let style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = '.dataTable-wrapper .dataTable-bottom .dataTable-pagination .dataTable-pagination-list .active a { background-image: linear-gradient(' + gradientColors[`${this.corClass}`] + ') !important; }';

      document.getElementsByTagName('head')[0].appendChild(style);

    },
    beforeUnmount() {
      delete window.editModal;
      delete window.PermissionAccess;
      delete window.Homologar;
      delete window.deleteModal;
      delete window.Reativar;
      delete window.detailModal;
      delete window.downloadArq;
      delete window.Vincular;
      delete window.exportArqPDF;

    },
};
</script>

<style>
.bg-danger{
  color: #fff;
}

.dataTable-container{
  overflow: auto !important;
}

</style>