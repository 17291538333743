<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="w-100"> 
                <div class="row">
                    <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 div-filter">
                        <SoftDropdown v-model="selected.competencia_ano" :placeholderText="'Competência'" :data="dataCompetencia"></SoftDropdown>
                      </div>
                    <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                        <SoftDropdown v-model="selected.origem" :placeholderText="'Origem'" :data="[{text: 'Estado (Sefaz)', value: 'Estado'}, {text: 'Município', value: 'Município'}]" />
                    </div>  
                    <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                        <SoftDropdown v-model="selected.status" :placeholderText="'Status'" :data="[{text: 'Ativo', value: 1}, {text: 'Inativo', value: '0'}]" />
                    </div>  
                  <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                    <div class="d-sm-block d-flex justify-content-center">
                      <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="filtrar">Filtrar</button>
                    </div>
                  </div>
                </div>
                <AddModal @update-dad="filtrar" :corClass="corDinamica"/>
              </div>
              <div class="pb-0 card-body position-relative">
                <div v-if="isLoading" class="loading_gif">
                  <img src="../assets/img/loading.gif" >
                </div>
                <TableComponent v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading" :tableName="tableName" :columns="columns" :data="data" :corClass="corDinamica"
                   :nome_filtros="nome_filtros" :filtros="filtros" :key="chave" :CanDetail="CanDetail" :idModalDetail="idModalDetail" :componentDetail="componentDetail" :preHiddenColumns="hiddenColumns"/>
                  <div v-if="data.length === 0" >
                    <h3 class="text-center">Buscando dados</h3>
                    <h3 class="text-center">Com esses filtros</h3>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
    
  <script>
  import axios from 'axios';
  import TableComponent from "./components/DataTables";
  import AddModal from "./components/ModalsAdd/acoesAdd";
  import SoftDropdown from "../components/SoftDropdown";
  import permissionsmixin from '../mixins/permissions.js';
  import verificacoesmixin from '../mixins/verificacoes.js';
  import makeTable from '../mixins/makeTable.js';
  
  export default {
    mixins: [permissionsmixin, verificacoesmixin, makeTable],
    name: "ListarEdipamsView",
    components: {
        TableComponent,
        AddModal,
        SoftDropdown,
    },
    data() {
      return {
        tableName: "EDIPAMS",
        columns: ['Id', 'Competência', 'Data Publicacao', 'Tipo', 'IPM', 'Origem', 'Cidade', 'Status'],
        getColumnsData: {
            "Id": "id", 
            "Competência": "competencia_ano",
            "Origem": "origem",
            "Data Publicacao": "data_publicacao",
            "Tipo": "tipo",
            "IPM": "ipm",
            "Cidade": "cidade",
            "Status": "status"

        },
        idModalDetail: '#edipamModalDetail',
        componentDetail: 'ModalsDetail/EdipamDetail',
        dataCompetencia: [],
        hiddenColumns: [0],
        filtros: {
          competencia_ano: null,
          origem: null,
          status: 1
        },
        nome_filtros: {
          competencia_ano: 'Competência',
          origem: 'Origem',
          status: 'Status'
        },
        selected: {
            competencia_ano: null,
            origem: null,
            status: {text: 'Ativo', value: 1}
        },
        chave: 0,
        isLoading: true,
      };
    },
    watch: {
      permissoes: {
        handler() {
          this.verificarPermissaoDaPagina('Listar eDIPAMs');
        },
        deep: true,
      },
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
      permissoes() {
        return this.$store.state.permissoesUser;
      },
    },
    methods: {
      stopLoading(){
        this.isLoading = false;
      },
      async filtrar() {
        this.isLoading = true;
  
        this.filtros.competencia_ano = this.obterDadosSelect(this.selected.competencia_ano, 'value');
        this.filtros.origem = this.obterDadosSelect(this.selected.origem, 'value');
        this.filtros.status = this.obterDadosSelect(this.selected.status, 'value');
        await this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/listar-edipams/tabela`, this.filtros, this.getColumnsData);
      },
      getSelectDataCompetencia() {
        const API_URL = process.env.VUE_APP_API_BASE_URL + `/consulta_competencia_ano/select2`;
        axios.get(API_URL).then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            this.dataCompetencia.push({text: response.data[i].competencia_ano, value: response.data[i].competencia_ano});
          }
        });
      }
    },
    created() {
      if (this.permissoes.length) {
        this.verificarPermissaoDaPagina('Listar eDIPAMs');
      }
      const cor = localStorage.getItem('corDinamica');
      this.$store.commit("atualizarCorDinamica", cor);
      this.verificarPermissoes('Listar eDIPAMs')
    },
    mounted() {
        this.getSelectDataCompetencia();
        this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/listar-edipams/tabela`, this.filtros, this.getColumnsData);
    }
  };
  </script>
  