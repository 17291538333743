
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="w-100"> 
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <SoftDropdown v-model="selected.importado" :placeholderText="'Importado CADESP'" :minCharacters="0" :uniqueOptions="true" :data="[{text: 'Sim', value: 1},  {text: 'Não', value: 0}]"/>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <SoftDropdown v-model="selected.exercicio" :placeholderText="'Exercício'" :minCharacters="0" :data="dataAno"/>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 div-filter">
                  <SoftDropdown2 v-model="selected.cnae" :placeholderText="'CNAE Contribuinte'" :minCharacters="2" :columnFilter="'cnae_completo'" :rota="'/cnae/select2_personalizado'" />
                </div>                
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 d-flex ">
                  <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="filtrar">Filtrar</button>
                </div>
              </div>
              <AddModal @update-dad="filtrar" :corClass="corDinamica" :tipo="'contribuinte'"/>
              <ImportModal :corClass="corDinamica"  :tipo="'Contribuinte'"/>
            </div>
            <div class="pb-0 card-body position-relative">
              <div v-if="isLoading" class="loading_gif">
                <img src="../assets/img/loading.gif" >
              </div>
              <TableComponent v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading"  :tableName="tableName" :CanAdd="CanAdd" :columns="columns" :data="data" :corClass="corDinamica" :CanDetail="CanDetail" :componentDetail="componentDetail" :idModalDetail="idModalDetail"
                :componentEdit="componentEdit" :routeDelete="routeDelete" :idModalAdd="idModalAdd" :idModalEdit="idModalEdit" :idButtonImport="idButtonImport"  :TextButtonAdd="TextButtonAdd" :TextButtonImport="TextButtonImport" :CanEdit="CanEdit" :CanDelete="CanDelete" :nome_filtros="nome_filtros" :filtros="filtros" :key="chave" :preHiddenColumns="hiddenColumns"/>
                <div v-if="data.length === 0">
                  <h3 class="text-center">Buscando dados</h3>
                  <h3 class="text-center">Com esses filtros</h3>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
<script>
import TableComponent from "./components/DataTables";
import AddModal from "./components/ModalsAdd/contadorContribuinteAdd";
import ImportModal from "./components/ModalsImport/ContribuinteImport.vue";
import SoftDropdown from "@/components/SoftDropdown";
import SoftDropdown2 from "@/components/SoftDropdown2";
import permissionsmixin from '../mixins/permissions.js';
import verificacoesmixin from '../mixins/verificacoes.js';
import makeTable from '../mixins/makeTable.js';

export default {
  mixins: [permissionsmixin, verificacoesmixin, makeTable],
  name: "CadastroContribuinteView",
  components: {
    TableComponent,
    AddModal,
    ImportModal,
    SoftDropdown,
    SoftDropdown2,
  },
  data() {
    return {
      tableName: "CONTRIBUINTES",
      columns: ["Id", "Inscrição Estadual", "CNPJ", "Razão Social", "Nome Fantasia", "CNAE" ,"Telefone", "Email", "Endereço", "Município", "UF", "CEP", "Data Abertura", "Ultima Atualização", "Natureza Jurídica", "Capital Social", "Porte", "Tipo", "Situação", "Importado", "Data Situação"],
      getColumnsData: {
        "Id": "id", 
        "Inscrição Estadual": "inscricao_estadual",
        "CNPJ": "cnpj",
        "Razão Social": "razao_social",
        "Nome Fantasia": "nome_fantasia",
        "CNAE": "cnae",
        "Telefone": "telefone",
        "Email": "email",
        "Endereço": "logradouro",
        "Município": "municipio",
        "UF": "uf",
        "CEP": "cep",
        "Data Abertura": "data_abertura",
        "Ultima Atualização": "ultima_atualizacao",
        "Natureza Jurídica": "natureza_juridica",
        "Capital Social": "capital_social",
        "Porte": "porte",
        "Tipo": "tipo",
        "Situação": "situacao_cadastral",
        "Importado": "importado",
        "Data Situação": "data_situacao_cadastral",
      },
      componentDetail: 'ModalsDetail/ContribuinteDetail',
      hiddenColumns: [11, 12, 13, 14, 15, 16, 17, 18, 20],
      componentEdit: 'ModalsEdit/contribuinteEdit',
      routeDelete: 'contribuinte/delete',
      idModalAdd: '#contadorContribuinteModalAdd',
      idModalEdit: '#contribuinteModalEdit',
      idButtonImport: '#contribuinteButtonImport',
      idModalDetail: '#contribuinteModalDetail',
      TextButtonImport: 'Importar Contribuinte',
      TextButtonAdd: '+ Adicionar Contribuinte',

      filtros: {
        importado: null,
        cnae: null,
        exercicio: null,
      },
      nome_filtros: {
        importado: 'Importado CADESP',
        cnae: 'CNAE Contribuinte',
        exercicio: 'Referência',
      },
      selected: {
        importado: null,
        cnae: null,
        exercicio: null,
      },
      chave: 0,
      isLoading: true,
      dataAno: [{text: '2024', value: 2024},  {text: '2023', value: 2023}, {text: '2022', value: 2022}, {text: '2021', value: 2021}, {text: '2020', value: 2020}, {text: '2019', value: 2019}, {text: '2018', value: 2018}, {text: '2017', value: 2017}, {text: '2016', value: 2016}, {text: '2015', value: 2015}, {text: '2014', value: 2014}, {text: '2013', value: 2013}, {text: '2012', value: 2012}, {text: '2011', value: 2011}, {text: '2010', value: 2010}]
    };
  },
  watch: {
    permissoes: {
      handler() {
        this.verificarPermissaoDaPagina('Contribuinte');
      },
      deep: true,
    },
  },
  computed: {
    corDinamica() {
      return this.$store.state.corDinamica;
    },
    permissoes() {
      return this.$store.state.permissoesUser;
    },
  },
  methods: {
    stopLoading(){
      this.isLoading = false;
    },
    async filtrar() {
      this.isLoading = true;
      this.filtros.importado = this.obterDadosSelect(this.selected.importado, 'text');
      this.filtros.cnae = this.obterDadosSelect(this.selected.cnae, 'value');
      this.filtros.exercicio = this.obterDadosSelect(this.selected.exercicio, 'value');
      await this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/contribuinte/tabela`, this.filtros, this.getColumnsData);
    },
  },
  created() {
    if (this.permissoes.length) {
      this.verificarPermissaoDaPagina('Contribuinte');
    }
    const cor = localStorage.getItem('corDinamica');
    this.$store.commit("atualizarCorDinamica", cor);
    this.verificarPermissoes('Contribuinte');
  },
  mounted() {
    this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/contribuinte/tabela`, this.filtros, this.getColumnsData);
  }
};
</script>
