<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="w-100">
              <div class="row">
                <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-5 div-filter">
                  <SoftDropdown2 v-model="object.cnae" :placeholderText="'CNAE'" :minCharacters="3" :columnFilter="'cnae_completo'" :rota="'/cnae/select2_personalizado'"/>
                </div> 
                <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-5 div-filter">
                  <SoftDropdown v-model="object.cnae_destacado" :placeholderText="'CNAE Destacado'" :data="[{text: 'D', value: 'D'}, {text: 'H', value: 'H'}, {text: 'J', value: 'J'}]"/>
                </div> 
                  <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-2 div-filter">
                  <SoftDropdown v-model="object.ano_referencia" :placeholderText="'Ano de Referência'" :data="[{text: '2024', value: 2024}]"/>
                </div>         
              </div>
              <div class="row">
                <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <div class="d-sm-block d-flex justify-content-center">
                    <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="gerarRelatorio">Gerar Relatório</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
  <script>
    import axios from 'axios';
    import DataTables from './components/DataTables';
    import permissionsmixin from '../mixins/permissions.js';
    import SoftDropdown2 from '../components/SoftDropdown2.vue';
    import SoftDropdown from '../components/SoftDropdown.vue';
    import verificacoesmixin from '../mixins/verificacoes.js';
  
    export default {
      mixins: [verificacoesmixin],
      name: "ValorAdicionadoCNAEView",
      components: {
        SoftDropdown2,
        SoftDropdown
      },
      data() {
        return {
          object: {
            cnae: null,
            cnae_destacado: null,
            ano_referencia: null,
          },
          selected: {
            cnae: null,
            cnae_destacado: null,
            ano_referencia: null,
          },
          text: {
            cnae: null,
            cnae_destacado: null,
            ano_referencia: null,
          },
        };
      },
      computed: {
        corDinamica() {
          return this.$store.state.corDinamica;
        },
      },
      methods: {
        async gerarRelatorio() {
          const user = localStorage.getItem('nome');
          const id_cidade = localStorage.getItem('id_cidade');
          const API_URL = process.env.VUE_APP_API_BASE_URL + `/gerar_relatorio_va_cnae`;
          this.selected.cnae = this.obterDadosSelect(this.object.cnae, 'value');
          this.selected.cnae_destacado = this.obterDadosSelect(this.object.cnae_destacado, 'value');
          this.selected.ano_referencia = this.obterDadosSelect(this.object.ano_referencia, 'value');
          this.text.cnae = this.obterDadosSelect(this.object.cnae, 'text');
          this.text.cnae_destacado = this.obterDadosSelect(this.object.cnae_destacado, 'text');
          
          try {
              const response = await axios.post(API_URL, {
                user: user,
                id_cidade: id_cidade,
                cnae: this.selected.cnae,
                cnae_destacado: this.selected.cnae_destacado,
                ano_referencia: this.selected.ano_referencia,
                text_cnae: this.text.cnae,
                text_cnae_destacado: this.text.cnae_destacado
                }, {
                  responseType: 'arraybuffer',
              });
              const blob = new Blob([response.data], { type: 'application/pdf' });
              const url = URL.createObjectURL(blob);
              window.open(url, 'filename');

          } catch (error) {
            this.showMessage('danger', 'Erro ao gerar relatório!');
          }
        },
      },
      created() {
        const cor = localStorage.getItem('corDinamica');
        this.$store.commit("atualizarCorDinamica", cor);
      },
    };
  </script>
