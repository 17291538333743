<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="w-100">
              <div class="row">
                <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <SoftDropdown2 v-model="object.titulo" :placeholderText="'Titulo da Notificação'" :uniqueOptions="true" :minCharacters="3" :columnFilter="'titulo'" :rota="'/notificacoes/select2'"/>
                </div>
                <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <SoftDropdown2 v-model="object.tipo_notificacao" :placeholderText="'Tipo da Notificação'" :uniqueOptions="true" :minCharacters="0" :columnFilter="'tipo_notificacao'" :rota="'/notificacoes/select2'" />
                </div>
                <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                  <input v-model="filtros.data_inicio" type="date" class="input-filter-dt form-control" id="filtro_data_inicio_logs" name="filtro_data_inicio_logs" placeholder="Data inicial">
                </div>
                <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                  <input v-model="filtros.data_fim" type="date" class="input-filter-dt form-control" id="filtro_data_fim_logs" name="filtro_data_fim_logs" placeholder="Data final">
                </div>
                <div class="col-12 col-xs-12 col-sm-6 col-md-2 col-lg-2 div-filter">
                  <div class="d-md-block d-flex justify-content-center">
                    <button class="btn-filter btn bg-gradient-primary" id="btn-success filtro" @click="filtrar" :class="['btn bg-gradient-' + $store.state.corDinamica]">Filtrar</button>
                  </div>
                </div>
              </div>
              <AddModal @update-dad="filtrar" :corClass="corDinamica"/>
            </div>
            <div class="pb-0 card-body position-relative">
              <div v-if="isLoading" class="loading_gif">
                <img src="../assets/img/loading.gif" >
              </div>
              <DataTables v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading" :tableName="tableName" :columns="columns" :data="data" :corClass="corDinamica" :routeDelete="routeDelete" :CanDelete="CanDelete"
               :filtros="filtros" :nome_filtros="nome_filtros" :key="chave"/>
              <div v-if="data.length === 0">
                <h3 class="text-center">Buscando dados</h3>
                <h3 class="text-center">Com esses filtros</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

import DataTables from "./components/DataTables";
import AddModal from "./components/ModalsAdd/modulosAdd";
import SoftDropdown2 from '../components/SoftDropdown2.vue';
import permissionsmixin from '../mixins/permissions.js';
import makeTable from '../mixins/makeTable.js';
import verificacoesmixin from '../mixins/verificacoes.js';

export default {
  mixins: [permissionsmixin, makeTable, verificacoesmixin],
  name: "NotificacoesView",
  components: {
    DataTables,
    AddModal,
    SoftDropdown2,
  },
  data() {
    return {
      tableName: "NOTIFICAÇÕES",
      columns: ["Id", "Titulo", "Tipo", "Descricão", "Data"],
      getColumnsData: {
          "Id": "id",
          "Titulo": "titulo",
          "Tipo": "tipo_notificacao",
          "Descricão": "descricao",
          "Data": "data",
      },
      routeDelete: 'notificacao/delete',
      filtros:{
          titulo: null,
          tipo_notificacao: null,
          data_inicio: null,
          data_fim: null,
      },
      nome_filtros:{
        titulo: 'Titulo da Notificação',
        tipo_notificacao: 'Tipo da Notificação',
      },
      object: {
          titulo: null,
          tipo_notificacao: null,
      },
      isLoading: true,
      id_usuario: null,
    };
  },
  watch: {
    permissoes: {
      handler() {
        this.verificarPermissaoDaPagina('Notificações');
      },
      deep: true,
    },
  },
  computed: {
    corDinamica() {
      return this.$store.state.corDinamica;
    },
    permissoes() {
      return this.$store.state.permissoesUser;
    },
  },
  methods: {
    stopLoading(){
      this.isLoading = false;
    },
    async filtrar() {
      this.isLoading = true;
      this.filtros.titulo = this.obterDadosSelect(this.object.titulo, 'value');
      this.filtros.tipo_notificacao = this.obterDadosSelect(this.object.tipo_notificacao, 'value');

      this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/notificacoes/tabela`, this.filtros, this.getColumnsData);
    },
  },
  created() {
    if (this.permissoes.length) {
      this.verificarPermissaoDaPagina('Notificações');
    }
    const cor = localStorage.getItem('corDinamica');
    this.$store.commit("atualizarCorDinamica", cor);
    const userId = localStorage.getItem("id_usuario");
    const apiUrl = process.env.VUE_APP_API_BASE_URL + '/notificacoes/vizualizar/' + userId ;
    axios.get(apiUrl)
    .then(() => {
      this.$store.commit("atualizarNewNotifications", []);
    })
    .catch(error => {
    });
    this.verificarPermissoes('Notificações');
  },
  mounted() {
    this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/notificacoes/tabela`, this.filtros, this.getColumnsData);
  },
};
</script>

<style scoped>
input[type=date] {
  text-align: right;
}

input[type="date"]:before {
  color: #999;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}
</style>
  