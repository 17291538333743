<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="w-100">
              <div class="row">
                <div class="col-7" style="margin-bottom: 2%;">
                  <div class="d-flex flex-column">
                    <strong class="text-center">DADOS DO CONTRIBUINTE</strong>
                  </div>
                  <hr style="margin-bottom: 5px; margin-top: 0px;">
                  <b>Nome</b><br>
                  {{ userName }} | {{ userLogin }}
                </div>
                <div class="col-5">
                  <div class="d-flex flex-column">
                    <strong class="text-center">Ano Atual - {{ new Date().getFullYear() }}</strong>
                    <hr style="margin-bottom: 5px; margin-top: 0px;">
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <div class="d-flex flex-column">
                        <span class="text-center">GIAs Enviadas</span>
                        <span class="text-success text-center">{{ meses_enviados }}</span>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="d-flex flex-column">
                        <span class="text-center">GIAs Omissas</span>
                        <span class="text-danger text-center">{{ meses_omissos }}</span>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="d-flex flex-column">
                        <span class="text-center">GIAs Não Geradas</span>
                        <span class="text-warning text-center">{{ meses_pendentes }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
              <div class="row">
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-2">
                      <SoftDropdown v-model="object.exercicio" :placeholderText="'Exercício'" :data="[{text: '2024', value: 2024}, {text: '2023', value: 2023}, {text: '2022', value: 2022}, {text: '2021', value: 2021}, {text: '2020', value: 2020}, {text: '2019', value: 2019}]" :showClear="false" />
                  </div>
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                      <div class="d-md-block d-flex justify-content-center">
                      <button :class="['btn-filter', 'btn bg-gradient-' + this.$store.state.corDinamica]" id="btn-success filtro" @click="filtrar">Filtrar</button>
                      </div>
                  </div>
              </div>
            <div class="pb-0 card-body position-relative">
              <div v-if="isLoading" class="loading_gif">
                <img src="../assets/img/loading.gif" >
              </div>
              <DataTables v-if="data.length > 0" @update-dad="filtrar()" @is-loading="stopLoading" :tableName="tableName" :columns="columns" :data="data" :corClass="corDinamica" :filtros="filtros" :nome_filtros="nome_filtros" :key="chave"/>
              <div v-if="data.length === 0">
                <h3 class="text-center">Buscando dados</h3>
                <h3 class="text-center">Com esses filtros</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
         
</template>
    
<script>
import axios from "axios";
import DataTables from "./components/DataTables";
import permissionsmixin from '../mixins/permissions.js';
import makeTable from '../mixins/makeTable.js';
import verificacoesmixin from '../mixins/verificacoes.js';
import SoftDropdown from '../components/SoftDropdown';

  export default {
    mixins: [permissionsmixin, makeTable, verificacoesmixin],
    name: "OmissoesContribuinteView",
    components: {
      DataTables,
      SoftDropdown
    },
    data() {
      return {
        rota: '1',
        chave: 0,
        userName: null,
        userLogin: null,
        meses_omissos: null,
        meses_pendentes: null,
        meses_enviados: null,
        Ano: 2024,
        tableName: "GIA",
        columns: ["Documento", "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        getColumnsData: {
          'Id': 'id',
          "Documento": "documento",
          "Jan": "jan" ,
          "Fev": "fev",
          "Mar": "mar",
          "Abr": "abr",
          "Mai": "mai",
          "Jun": "jun",
          "Jul": "jul",
          "Ago": "ago",
          "Set": "set",
          "Out": "out",
          "Nov": "nov",
          "Dez": "dez",
        },
        filtros:{
            exercicio: 2024,
        },
        nome_filtros:{
            exercicio: 'Exercício',
        },
        object: {
            exercicio: {
          text: '2024',
          value: 2024}
        },
        isLoading: true,
      };
    },
    watch: {
      permissoes: {
        handler() {
          this.verificarPermissaoDaPagina('Omissões de Entrega Contribuinte');
        },
        deep: true,
      },
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
      permissoes() {
        return this.$store.state.permissoesUser;
      },
    },
    methods: {
      stopLoading(){
        this.isLoading = false;
      },
      async filtrar() {
        this.isLoading = true;
        this.filtros.exercicio = this.obterDadosSelect(this.object.exercicio, 'value');
        this.Ano = this.filtros.exercicio;
        this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/gia/get_meses_contribuinte/`, this.filtros, this.getColumnsData, localStorage.getItem('login'), this.Ano);
      },
      getArquivos() {
        const API_URL = process.env.VUE_APP_API_BASE_URL + '/gia/check_ano_omissoes/';
        axios.get(API_URL, {params: {
        cnpj: localStorage.getItem('login'),
        id_cidade: localStorage.getItem('id_cidade'),
        parametro: 'cnpj',
        ano: 2024
        }
        }).then((response) => {
        this.meses_omissos = response.data.meses_omissos;
        this.meses_pendentes = response.data.meses_pendentes;
        this.meses_enviados = response.data.meses_enviados;
        });
      },
    },
    created() {
      if (this.permissoes.length) {
        this.verificarPermissaoDaPagina('Omissões de Entrega Contribuinte');
      }
      this.getArquivos();
      const cor = localStorage.getItem('corDinamica');
      this.$store.commit("atualizarCorDinamica", cor);
      this.userName = localStorage.getItem('nome');
      this.userLogin = localStorage.getItem('login');
      this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/gia/get_meses_contribuinte/`, this.filtros, this.getColumnsData, localStorage.getItem('login'), this.Ano);
      if (this.userLogin.length > 11){
        this.userLogin = this.userLogin.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }else{
        this.userLogin = this.userLogin.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
    },
    mounted() {
    },
  };
  </script>
  