<template>
  <div>
    <div class="modal fade" id="usuariosModalAdd" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row">
              <div class="col-11">
                <h5 class="modal-title-perfil">Cadastro de Usuário</h5>
              </div>
              <div class="col-1">
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Fechar">
                  <span aria-hidden="true">&times;</span>
                </button> 
              </div>
            </div>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                
                <div class="col-6 form-group">
                  <label>Nome do Usuário</label>
                  <input type="text" v-model="selected.nome_usuario" class="form-control" id="nome_usuarios_add" name="nome_usuarios_add" placeholder="Nome do Usuário">
                </div>
                <div class="col-6">
                  <label>CPF</label>
                  <input type="text" v-model="selected.login" class="form-control" id="cpf_usuarios_add" name="cpf_usuarios_add" v-maska data-maska="['###.###.###-##']" placeholder="CPF">
                </div>
              </div>

              <div class="row">
                <div class="col-6 form-group">
                  <label>Perfil</label>
                  <SoftDropdown2 v-model="object.id_perfil" :placeholder-text="'Perfil'" :column-filter="'titulo_perfil'" :customClass="'dropdown_no_hpercent'" :minCharacters="0" :rota="'/perfil-acesso/select2'" ref="perfilDropdown"/>
                </div>
                <div class="col-6 form-group">
                  <label for="email_usuarios_add">Email</label>
                  <input type="email" v-model="selected.email" class="form-control"  id="email_usuarios_add" name="email_usuarios_add" placeholder="Email">
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <label for="senha_usuarios_add">Senha</label>
                  <input type="password" class="form-control" v-model="selected.senha" id="senha_usuarios_add" name="senha_usuarios_add" placeholder="Senha" @input="checkPasswordStrength" @focus="isFirstTouch = true">
                </div>
                <div class="col-6">
                  <label for="senha_confirmacao_usuarios_add">Confirme sua senha</label>
                  <input type="password" class="form-control" v-model="selected.senha_confirmacao" id="senha_confirmacao_usuarios_add" name="senha_confirmacao_usuarios_add" placeholder="Confirme sua senha">
                </div>
              </div>
              <password-check-result :isFirstTouch="isFirstTouch" ref="PasswordChild" :key="chave1">

              </password-check-result>
              <div class="form-check form-switch" style="margin-top: 2%;">
                <input
                  type="checkbox"
                  id="checknewpassword"
                  name="checknewpassword"
                  class="mt-1 form-check-input"
                  @change="changepassword()"
                />
                <span style="font-size: 14px;">Alterar senha no primeiro login</span>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <soft-button class="my-4 mb-2" variant="gradient" color="secondary" data-bs-dismiss="modal">
              <span>Cancelar</span>
            </soft-button>
            <div style="width: 100px;">
              <soft-button class="my-4 mb-2" variant="gradient" :color="corClass" full-width @click="salvarUsuario"
                :is-disabled="loading ? true : false">
                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                <span v-else>Salvar</span>
              </soft-button>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SoftButton from '../../../components/SoftButton.vue';
import passwordCheckResult from '../../../components/PasswordStrengthChecker';
import SoftDropdown2 from '../../../components/SoftDropdown2';
import verificacoesmixin from '../../../mixins/verificacoes.js';

export default {
  mixins: [verificacoesmixin],
  props:{
    corClass: String
  },
  name: 'ButtonAddUsuarios',
  data() {
    return {
      dadosUser: [],
      isFirstTouch: false,
      checknewpassword: false,
      loading: false,
      chave1: 0,
      chave2: 0,
      rota: '',
      disabledUser: true,
      columnSelect: '',
      selected: {
        nome_usuario: null,
        login: null,
        id_perfil: null,
        email: null,
        senha: null,
        senha_confirmacao: null,
      },
      object: {
        id_perfil: null,
      },
    };
  },
  components: {
    SoftButton,
    passwordCheckResult,
    SoftDropdown2,
  },
  methods: {
    checkPasswordStrength(){
      this.$nextTick(() => {
          if (this.$refs.PasswordChild) {
            this.$refs.PasswordChild.checkPasswordStrength('senha_usuarios_add');
          }
      });
    },
    changepassword() {
      this.checknewpassword = !this.checknewpassword;
    },
    salvarUsuario() {
      this.loading = true;
      this.selected.id_perfil = this.obterDadosSelect(this.object.id_perfil, 'id');
      this.selected.login = this.selected.login.replace(/[^\d]/g, '');

      const user = localStorage.getItem('user');
      const id_cidade = localStorage.getItem('id_cidade');

      let checknewpassword = this.checknewpassword;
      if (checknewpassword){
        checknewpassword = 1;
      } else {
        checknewpassword = 0;
      }

      if (this.verificarCamposVazios(this.selected)) {
        this.loading = false;
        return;
      }

      if (this.selected.email && !this.validarEmail(this.selected.email)) {
        this.loading = false;
        this.showMessage('error', 'Email inválido, verifique o seu email.');
        return;
      }

      if (this.selected.senha !== this.selected.senha_confirmacao) {
        this.loading = false;
        this.showMessage('error', 'As senhas não conferem.');
        return;
      }

      
      const passwordCheckResult = this.$refs.PasswordChild.checkPassword(this.selected.senha);
      if (passwordCheckResult) {
        this.loading = false;
        this.showMessage('error', `A senha não atende ao critério: ${passwordCheckResult}.`);
        return;
      }
      
      const apiUrl = process.env.VUE_APP_API_BASE_URL + '/usuarios/adicionar';
      axios.post(apiUrl, {
        nome: this.selected.nome_usuario,
        login: this.selected.login,
        perfilId: this.selected.id_perfil,
        email: this.selected.email,
        password: this.selected.senha,
        checknewpassword,
        id_cidade,
        user,
      })
      .then(response => {
        if (response.data.success){
          this.clearUpData();
          $('#usuariosModalAdd').modal('hide');
          this.showMessage('success', 'Usuário salvo com sucesso!');
          this.$emit('update-dad');
        } else {
          this.showMessage('error', 'Erro ao salvar usuário. Por favor, tente novamente.');
        }
      })
      .catch(() => {
        this.showMessage('error', 'Erro ao salvar usuário. Por favor, tente novamente.');
      })
      .finally(() => {
        this.loading = false;
      });
    },
    validarEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    clearUpData() {
      this.selected = {
        login: null,
        id_perfil: null,
        nome_usuario: null,
        email: null,
        senha: null,
        senha_confirmacao: null,
      };
      this.object = {
        id_perfil: null,
      };
      this.columnSelect = '';
      this.rota = '';
      this.disabledUser = true;
      this.chave2 += 1;
      this.chave1 += 1;
      this.isFirstTouch = false,
      this.checknewpassword = false,
      $('#checknewpassword').prop('checked', false);
    },
  },
};
</script>

<style lang="scss" scoped>

.dad-add{
  top: 180px;
}
</style>