<template>
  <div>
    <div class="modal fade" id="contribuinteButtonImport">
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row">
              <div class="col-11">
                <h5 class="modal-title-perfil">Importar {{ tipo.charAt(0).toUpperCase() + tipo.slice(1) }}</h5>
              </div>
              <div class="col-1">
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Fechar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <input class="form-control" id="file" type="file" name="file" accept=".txt">
          </div>
          <div class="modal-footer">
            <soft-button class="my-4 mb-2" variant="gradient" color="secondary" data-bs-dismiss="modal">
              <span>Cancelar</span>
            </soft-button>
            <div style="width: 100px;">
              <soft-button class="my-4 mb-2" variant="gradient" :color="corClass" full-width @click="ImportarArquivo()"
                :is-disabled="loading ? true : false">
                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                <span v-else>Salvar</span>
              </soft-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from '../../../components/SoftButton.vue';
import axios from 'axios';
import verificacoesmixin from '../../../mixins/verificacoes.js';

export default {
  mixins: [verificacoesmixin],
  props: {
    corClass: String,
    tipo: String,
  },  
  components: {
      SoftButton,
  },
  data() {
      return {
        loading: false,
      };
  },
  methods: {
    ImportarArquivo() {
      this.loading = true;
      const fileInput = document.getElementById('file');
      const file = fileInput.files[0];

      if (file) {

        const formData = new FormData();
        const id_cidade = localStorage.getItem('id_cidade');
        const responsavel = localStorage.getItem('login');
        formData.append('file', file);
        formData.append('id_cidade', id_cidade);
        formData.append('responsavel', responsavel);
        const API_URL = process.env.VUE_APP_API_BASE_URL + `/contribuinte/saveFile/`;
  
        axios.post(API_URL, formData)
          .then(response => {
            this.showMessage('success', 'Arquivo importado com sucesso!');
            this.loading = false;
            $('#contribuinteButtonImport').modal('hide');
            location.reload();
          })
          .catch(error => {
            this.showMessage('danger', 'Erro ao  importar!');
            this.loading = false;
          });

      } else {
        this.showMessage('warning', 'Selecione um Arquivo.');
        this.loading = false;
      }
    },
  },
};
</script>
