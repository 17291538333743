<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="w-100">
                <div class="row">
                    <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 div-filter">
                        <label>Ano de Referência</label>
                        <SoftDropdown v-model="object.ano_referencia" :showClear="false" :placeholderText="'Ano de Referência'" :data="data"/>
                    </div>  
                    <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 div-filter">
                        <label>Considerar Negativos</label>
                        <SoftDropdown v-model="object.considerar_negativos" :placeholderText="'Considerar Valores Negativos'" :showClear="false" :data="[{text: 'Sim', value: 1}, {text: 'Não', value: 0}]"/>
                    </div>  
                </div>
                <div class="row">
                  <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                    <div class="d-sm-block d-flex justify-content-center">
                      <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="gerarRelatorio">Gerar Relatório</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
      
<script>
import axios from 'axios';
import DataTables from './components/DataTables';
import permissionsmixin from '../mixins/permissions.js';
import verificacoesmixin from '../mixins/verificacoes.js';
import SoftDropdown from '../components/SoftDropdown.vue';


export default {
    mixins: [verificacoesmixin],
    name: "RankingValorAdicionadoView",
    components: {
    SoftDropdown
    },
    data() {
    return {
        object: {
        ano_referencia: {text: '2024', value: 2024},
        considerar_negativos: {text: 'Sim', value: 1},
        },
        selected: {
        ano_referencia: null,
        considerar_negativos: null,
        },
        text: {
        ano_referencia: null,
        },
        data: [
            {text: '2024', value: 2024},
            {text: '2023', value: 2023},
            {text: '2022', value: 2022},
            {text: '2021', value: 2021},
            {text: '2020', value: 2020},
            {text: '2019', value: 2019},
            {text: '2018', value: 2018},
            {text: '2017', value: 2017},
            {text: '2016', value: 2016}
            ]
    };
    },
    computed: {
    corDinamica() {
        return this.$store.state.corDinamica;
    },
    },
    methods: {
    async gerarRelatorio() {
        const user = localStorage.getItem('nome');
        const id_cidade = localStorage.getItem('id_cidade');
        const API_URL = process.env.VUE_APP_API_BASE_URL + `/gerar_relatorio_ranking_va`;
        this.selected.ano_referencia = this.obterDadosSelect(this.object.ano_referencia, 'value');
        this.selected.considerar_negativos = this.obterDadosSelect(this.object.considerar_negativos, 'value');
        
        try {
            const response = await axios.post(API_URL, {
            user: user,
            id_cidade: id_cidade,
            ano_referencia: this.selected.ano_referencia,
            considerar_negativos: this.selected.considerar_negativos,
            }, {
                responseType: 'arraybuffer',
            });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            window.open(url, 'filename');

        } catch (error) {
        this.showMessage('danger', 'Erro ao gerar relatório!');
        }
    },
    },
    created() {
    const cor = localStorage.getItem('corDinamica');
    this.$store.commit("atualizarCorDinamica", cor);
    },
};
</script>