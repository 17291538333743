<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="w-100">
              <div class="row">
                <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <SoftDropdown2 v-model="object.titulo_pagina" :placeholderText="'Titulo da Página'" :minCharacters="2" :uniqueOptions="true" :columnFilter="'titulo_pagina'" :rota="'/paginas/select2'" ref="paginaDropdown"/>
                </div>  
                <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <input type="number" v-model="filtros.posicao_pagina" class="input-filter-dt form-control" id="filtro_posicao_pagina_paginas" name="filtro_posicao_pagina_paginas" placeholder="Posição">
              </div>
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <SoftDropdown2 v-model="object.titulo_modulo" :placeholderText="'Titulo do Módulo'" :minCharacters="2" :uniqueOptions="true" :columnFilter="'titulo_modulo'" :rota="'/modulos/select2'" ref="moduloDropdown"/>
                </div>
                <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <div class="d-md-block d-flex justify-content-center">
                    <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="filtrar">Filtrar</button>           
                  </div>
                </div>
            </div>
            <AddModal @update-dad="filtrar" :corClass="corDinamica"/>
            </div>
            <div class="pb-0 card-body position-relative">
              <div v-if="isLoading" class="loading_gif">
                <img src="../assets/img/loading.gif" >
              </div>
              <TableComponent v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading" :tableName="tableName" :columns="columns" :data="data " :corClass="corDinamica" :componentPermissionAccess="componentPermissionAccess" 
              :componentEdit="componentEdit" :routeDelete="routeDelete" :idModalAdd="idModalAdd" :idModalEdit="idModalEdit" :CanAdd="CanAdd" :TextButtonAdd="TextButtonAdd"
              :CanEdit="CanEdit" :CanDelete="CanDelete" :nome_filtros="nome_filtros" :filtros="filtros" :key="chave"/>
              <div v-if="data.length === 0">
                <h3 class="text-center">Buscando dados</h3>
                <h3 class="text-center">Com esses filtros</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import TableComponent from "./components/DataTables";
import AddModal from "./components/ModalsAdd/paginasAdd";
import SoftDropdown2 from '../components/SoftDropdown2.vue';
import permissionsmixin from '../mixins/permissions.js';
import makeTable from '../mixins/makeTable.js';
import verificacoesmixin from '../mixins/verificacoes.js';

export default {
  mixins: [permissionsmixin, makeTable, verificacoesmixin],
  name: "PaginasView",
  components: {
    TableComponent,
    AddModal,
    SoftDropdown2,
  },
  data() {
    return {
      tableName: "PÁGINAS",
      columns: ["Id", "Título da página", "Posição","Módulo"],
      getColumnsData: {
        "Id": "id", 
        "Título da página": "titulo_pagina",
        "Posição": "posicao_pagina",
        "Módulo": "titulo_modulo",
      },
      componentEdit: 'ModalsEdit/paginasEdit',
      routeDelete: 'paginas/delete', 
      componentPermissionAccess: 'ModalsActions/PermissionAccsessBtn',
      idModalAdd: '#paginaModalAdd',
      idModalEdit: '#paginaModalEdit',
      TextButtonAdd: '+ Adicionar Página',
      filtros: {
        titulo_pagina: null,
        titulo_modulo: null,
        posicao_pagina: null,
      },
      nome_filtros: {
        titulo_pagina: 'Título da Página',
        titulo_modulo: 'Título do Módulo',
        posicao_pagina: 'Posição da Página',
      },
      object: {
        titulo_pagina: null,
        titulo_modulo: null,
      },
      isLoading: true,
    };
  },
  watch: {
    permissoes: {
      handler() {
        this.verificarPermissaoDaPagina('Páginas');
      },
      deep: true,
    },
  },
  computed: {
    corDinamica() {
      return this.$store.state.corDinamica;
    }, 
    permissoes() {
      return this.$store.state.permissoesUser;
    },
  },
  methods: {
    stopLoading(){
      this.isLoading = false;
    },
    async filtrar() {
      this.isLoading = true;
      this.filtros.titulo_pagina = this.obterDadosSelect(this.object.titulo_pagina, 'value');
      this.filtros.titulo_modulo = this.obterDadosSelect(this.object.titulo_modulo, 'value');
      this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/paginas/tabela`, this.filtros, this.getColumnsData);
    },
  },
  created() {
    if (this.permissoes.length) {
      this.verificarPermissaoDaPagina('Páginas');
    }
    const cor = localStorage.getItem('corDinamica');
    this.$store.commit("atualizarCorDinamica", cor);
    this.verificarPermissoes('Páginas');
  },
  mounted() {
    this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/paginas/tabela`, this.filtros, this.getColumnsData);
  },
  
};
</script>
