<template>
  <div>
    <div class="modal fade" id="paginaModalAdd" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
              <div class="row">
                  <div class="col-11">
                      <h5 class="modal-title-perfil">Adicionar Página</h5>
                  </div>
                  <div class="col-1">
                      <button type="button" class="close" data-bs-dismiss="modal" aria-label="Fechar">
                      <span aria-hidden="true">&times;</span>
                      </button> 
                  </div>
              </div>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <div class="row">
                  <div class="col-6">
                      <label for="titulo_pagina_paginas_add">Título da Página</label>
                      <input type="text" v-model="selected.titulo_pagina" class="form-control" id="titulo_pagina_paginas_add" name="titulo_pagina_paginas_add" placeholder="Titulo da Página" autocomplete="off">
                  </div>
                    <div class="col-6 form-group" style="display: inline-grid;">
                      <label for="titulo_modulo_paginas_add">Titulo do Módulo</label>
                      <SoftDropdown2 v-model="object.titulo_modulo" :placeholderText="'Titulo do Módulo'" :minCharacters="2" :uniqueOptions="true" :columnFilter="'titulo_modulo'" :rota="'/modulos/select2'" ref="moduloDropdown"
                      :customClass="'dropdown_no_hpercent'" :key="chave"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                      <label for="posicao_pagina_paginas_add">Posição da Página</label>
                      <input type="number" v-model="selected.posicao_pagina" class="form-control" id="posicao_pagina_paginas_add" name="posicao_pagina_paginas_add" placeholder="Selecione uma posição">
                  </div>     
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <soft-button class="my-4 mb-2" variant="gradient" color="secondary" data-bs-dismiss="modal">
              <span>Cancelar</span>
            </soft-button>
            <div style="width: 100px;">
              <soft-button class="my-4 mb-2" variant="gradient" :color="corClass" full-width @click="SalvarPagina()"
                :is-disabled="loading ? true : false">
                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                <span v-else>Salvar</span>
              </soft-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SoftButton from '../../../components/SoftButton.vue';
import SoftDropdown2 from "../../../components/SoftDropdown2.vue";
import verificacoesmixin from '../../../mixins/verificacoes.js';

export default {
  mixins: [verificacoesmixin],
  name: 'paginasAdd',
  props:{
    corClass: String
  },

  data() {
    return {
      loading: false,
      object: {
        titulo_modulo: null,
      },
      selected: {
        titulo_pagina: null,
        posicao_pagina: null,
        id_modulo: null,
      },
      chave: 0,
    };
  },
  components: {
    SoftButton,
    SoftDropdown2,
  },
  methods: {
    SalvarPagina() {
      this.loading = true;
      this.selected.id_modulo = this.obterDadosSelect(this.object.titulo_modulo, 'id');

      const user = localStorage.getItem('user');
      const id_cidade = localStorage.getItem('id_cidade');

      if(this.verificarCamposVazios(this.selected)){
        this.loading = false;
        return;
      }

      const apiUrl = process.env.VUE_APP_API_BASE_URL + '/paginas/adicionar'; 
        axios.post(apiUrl,{
          pagina_title: this.selected.titulo_pagina,
          selectPosicao_pag: this.selected.posicao_pagina,
          selectModulo_pag: this.selected.id_modulo,
          user: user,
          id_cidade: id_cidade
      })
      .then(response => {
        if (response.data.success){
          this.clearUpData();
          $('#paginaModalAdd').modal('hide');
          this.$emit('update-dad');
          this.showMessage('success', 'Página adicionada com sucesso!')
        } else {
          this.showMessage('danger', 'Erro ao adicionar página!')
        }
      })
      .catch(() => {
        this.showMessage('danger', 'Erro ao adicionar página!')
      })
      .finally(() => {
        this.loading = false;
      });
    },
    clearUpData() {
      this.selected = {
        titulo_pagina: null,
        posicao_pagina: null,
        id_modulo: null,
      };
      this.object = {
        titulo_modulo: null,
      };
      this.chave++;
    },
  },
};
</script>
