<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="w-100">
              <div class="row">
                <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <SoftDropdown2 v-model="object.titulo_modulo" :placeholderText="'Nome do Módulo'" :minCharacters="3" :columnFilter="'titulo_modulo'" :rota="'/modulos/select2'"/>
                </div>
                <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <input v-model="filtros.posicao_modulo" type="number" class="form-control" id="filtro_posicao_modulo_modulos" name="filtro_posicao_modulo_modulos" placeholder="Posição do Módulo">
                </div>
                <div class="col-12 col-xs-12 col-sm-6 col-md-2 col-lg-2 div-filter">
                  <div class="d-md-block d-flex justify-content-center">
                    <button class="btn-filter btn bg-gradient-primary" id="btn-success filtro" @click="filtrar" :class="['btn bg-gradient-' + $store.state.corDinamica]">Filtrar</button>
                  </div>
                </div>
              </div>
              <AddModal @update-dad="filtrar" :corClass="corDinamica"/>
            </div>
            <div class="pb-0 card-body position-relative">
              <div v-if="isLoading" class="loading_gif">
                <img src="../assets/img/loading.gif" >
              </div>
              <DataTables v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading" :tableName="tableName" :columns="columns" :data="data" :corClass="corDinamica"
              :componentEdit="componentEdit" :routeDelete="routeDelete" :idModalAdd="idModalAdd" :idModalEdit="idModalEdit" :TextButtonAdd="TextButtonAdd" 
              :CanEdit="CanEdit" :CanDelete="CanDelete" :CanAdd="CanAdd" :filtros="filtros" :nome_filtros="nome_filtros" :key="chave"/>
              <div v-if="data.length === 0">
                <h3 class="text-center">Buscando dados</h3>
                <h3 class="text-center">Com esses filtros</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import DataTables from "./components/DataTables";
import AddModal from "./components/ModalsAdd/modulosAdd";
import SoftDropdown2 from '../components/SoftDropdown2.vue';
import permissionsmixin from '../mixins/permissions.js';
import makeTable from '../mixins/makeTable.js';
import verificacoesmixin from '../mixins/verificacoes.js';

export default {
  mixins: [permissionsmixin, makeTable, verificacoesmixin],
  name: "ModulosView",
  components: {
    DataTables,
    AddModal,
    SoftDropdown2,
  },
  data() {
    return {
      tableName: "MÓDULOS",
      columns: ["Id", "Nome do Módulo", "Posição do Módulo", "Ícone"],
      getColumnsData: {
        "Id": "id", 
        "Nome do Módulo": "titulo_modulo",
        "Posição do Módulo": "posicao_modulo",
        "Ícone": item => `<i class="${item.icone_pagina}"></i>`,
      },
      componentEdit: 'ModalsEdit/modulosEdit',
      routeDelete: 'modulos/delete',
      idModalAdd: '#modulosModalAdd',
      idModalEdit: '#modulosModalEdit',
      TextButtonAdd: '+ Adicionar Módulo',
      filtros:{
        titulo_modulo: null,
        posicao_modulo: null,
      },
      nome_filtros:{
        titulo_modulo: 'Nome do Módulo',
        posicao_modulo: 'Posição do Módulo',
      },
      object: {
        titulo_modulo: null,
      },
      isLoading: true,
    };
  },
  watch: {
    permissoes: {
      handler() {
        this.verificarPermissaoDaPagina('Módulos');
      },
      deep: true,
    },
  },
  computed: {
    corDinamica() {
      return this.$store.state.corDinamica;
    },
    permissoes() {
      return this.$store.state.permissoesUser;
    },
  },
  methods: {
    stopLoading(){
      this.isLoading = false;
    },
    async filtrar() {
      this.isLoading = true;
      this.filtros.titulo_modulo = this.obterDadosSelect(this.object.titulo_modulo, 'value');
      this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/modulos/tabela`, this.filtros, this.getColumnsData);
    },
    
  },
  created() {
    if (this.permissoes.length) {
      this.verificarPermissaoDaPagina('Módulos');
    }
    const cor = localStorage.getItem('corDinamica');
    this.$store.commit("atualizarCorDinamica", cor);
    this.verificarPermissoes('Módulos');
  },
  mounted() {
    this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/modulos/tabela`, this.filtros, this.getColumnsData);
  },

};
</script>

  
  