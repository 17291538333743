<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="pb-0 card-header">
              
              <div class="pb-0 card-body position-relative">
                <div v-if="isLoading" class="loading_gif">
                  <img src="../../../assets/img/loading.gif" >
                </div>
                <div class="row">
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    Situação
                    <SoftDropdown v-model="object.situacao" :placeholderText="'Situação'" :data="[{text: 'Sucesso', value: 1}, {text: 'Processando', value: 0}, {text: 'Com erro', value: 2}]"/>
                  </div>
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    Tipo
                    <SoftDropdown v-model="object.tipo" :placeholderText="'Tipo'" :data="[{text: 'Normal', value: 1}, {text: 'Substitutiva', value: 2}]"/>
                  </div>
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                    Data de envio inicial
                    <input v-model="filtros.data_inicio" type="date" class="input-filter-dt form-control" id="filtro_data_inicio_gia" name="filtro_data_inicio_gia" placeholder="Data inicial">
                  </div>
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                    Data de envio final
                    <input v-model="filtros.data_fim" type="date" class="input-filter-dt form-control" id="filtro_data_fim_gia" name="filtro_data_fim_gia" placeholder="Data final">
                  </div>
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                    Referência
                    <input v-model="filtros.referencia" type="month" class="input-filter-dt form-control" id="filtro_data_fim_gia" name="filtro_data_fim_gia" placeholder="Referência">
                  </div>
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                    <div class="d-md-block d-flex justify-content-center">
                      <button :class="['btn-filter', 'btn bg-gradient-' + this.$store.state.corDinamica, 'mt-4']" @click="filtrar">Filtrar</button>
                    </div>
                  </div>
                </div> 
                <DataTables v-if="data.length > 0" @update-dad="filtrar()" @is-loading="stopLoading" :tableName="tableName" :CanDowloadArq="CanDowloadArq" :columns="columns" :data="data" :corClass="corDinamica" :filtros="filtros" :nome_filtros="nome_filtros" :key="chave" :CanExportPDF="CanExportPDF"/>
                <div v-if="data.length === 0">
                  <h3 class="text-center">Buscando dados</h3>
                  <h3 class="text-center">Com esses filtros</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
    
<script>
  import DataTables from "../DataTables";
  import permissionsmixin from '../../../mixins/permissions.js';
  import makeTable from '../../../mixins/makeTable.js';
  import verificacoesmixin from '../../../mixins/verificacoes.js';
  import SoftDropdown from '../../../components/SoftDropdown';
  
  export default {
    props: {
      rotaPersonalizada: {
        type: String,
        default: '/gia/arquivos_enviados/tabela_contador'
      },
    },
    mixins: [permissionsmixin, makeTable, verificacoesmixin],
    name: "GiaView",
    components: {
      DataTables,
      SoftDropdown,
    },
    data() {
      return {
        tableName: "GIA",
        columns: ["Arquivo Enviado", "Referência", "Situação", "Tipo", "Data envio", "Hora Envio"],
        getColumnsData: {
          'Id': 'id',
          "Data envio": "data_envio",
          "Hora Envio": "hora_envio",
          "Situação": "situacao",
          "Tipo": "tipo",
          "Arquivo Enviado": "nome_arquivo",
          "Referência": "ref",
        },
        filtros:{
          data_fim: null,
          data_fim: null,
          situacao: null,
          tipo: null,
          referencia: null,
        },
        nome_filtros:{
          data_fim: 'Data de Início',
          data_fim: 'Data de Fim',
          situacao: 'Situação',
          tipo: 'Tipo',
          referencia: 'Referência',
        },
        object: {
          situacao: null,
          tipo: null,
        },
        isLoading: true,
        CanDowloadArq: true,
        CanExportPDF: true,
      };
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
    },
    methods: {
      stopLoading(){
        this.isLoading = false;
      },
      async filtrar() {
        this.isLoading = true;
        this.filtros.situacao = this.obterDadosSelect(this.object.situacao, 'value');
        this.filtros.tipo = this.obterDadosSelect(this.object.tipo, 'value');
        this.obterTabela(process.env.VUE_APP_API_BASE_URL + this.rotaPersonalizada, this.filtros, this.getColumnsData, localStorage.getItem('login'));
      },
    },
    created() {
      const cor = localStorage.getItem('corDinamica');
      this.$store.commit("atualizarCorDinamica", cor);
      this.verificarPermissoes('Módulos');
    },
    mounted() {
      this.obterTabela(process.env.VUE_APP_API_BASE_URL + this.rotaPersonalizada, this.filtros, this.getColumnsData, localStorage.getItem('login'));
    },
  
  };
</script>
  
<style scoped>

</style>