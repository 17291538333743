import axios from 'axios';
export default {
    data() {
      return {
        data: [],
        chave: 0,
      };
    },
    methods: {
      async obterTabela(url, filtros = {}, formatoColunas, cnpj = null, ano = null) {
        const id_cidade = localStorage.getItem('id_cidade');
        const id_perfil = localStorage.getItem('perfilId'); 
        const id_usuario = localStorage.getItem('id_usuario');
        try {
          const response = await axios.post(url, {filtros, id_cidade, id_usuario, id_perfil, cnpj, ano});
      
          const data = await this.formatarDados(response.data, formatoColunas);
          this.data = data;
          console.log('Data:', this.data);
          this.chave++;
        } catch (error) {
          console.error('Erro ao obter dados:', error);
        }
      },
  
      async formatarDados(dados, formatoColunas) {
        const meses = Object.keys(dados);
        const valores = Object.values(dados);
    
        return meses.map((mes, index) => {
            const item = {};
            for (const key in formatoColunas) {
                const field = formatoColunas[key];
                item[key] = typeof field === 'function' ? field(valores[index]) : valores[index][field];
            }
            return item;
        });
    },
    
    }
  }