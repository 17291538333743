import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/';

export default {
  async sendEmail(user) {
    await axios.post(API_URL + "password-forgot", {
      email: user.email,
      id_cidade: user.id_cidade,
      redirect_url: process.env.VUE_APP_BASE_URL + "resetpassword/",
    });
  },

  async resetPassword(newUser) {
    await axios.post(API_URL + "password-reset", newUser);
  },
};
