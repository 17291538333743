<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="w-100">
              <div class="row">
                <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-3 div-filter">
                  <SoftDropdown2 v-model="object.user" :placeholderText="'User'" :minCharacters="3" :columnFilter="'user'" :rota="'/logs/select2'" ref="loginDropdown"/>
                </div> 
                <div class="up col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                  <SoftDropdown2 v-model="object.titulo_acao" :placeholderText="'Titulo da Ação'" :minCharacters="2" :uniqueOptions="true" :columnFilter="'titulo_acao'" :rota="'/acoes/select2'" ref="acaoDropdown"/>
                </div>
                <div class="up col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                  <SoftDropdown2 v-model="object.titulo_pagina" :placeholderText="'Titulo da Página'" :minCharacters="2" :uniqueOptions="true" :columnFilter="'titulo_pagina'" :rota="'/paginas/select2'" ref="paginaDropdown"/>
                </div>
                <div class="up col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                  <SoftDropdown2 v-model="object.titulo_modulo" :placeholderText="'Nome do Módulo'" :minCharacters="3" :columnFilter="'titulo_modulo'" :rota="'/modulos/select2'"/>
                </div>  
              </div> 
              <div class="row">
                <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                  <input v-model="filtros.data_inicio" type="date" class="input-filter-dt form-control" id="filtro_data_inicio_logs" name="filtro_data_inicio_logs" placeholder="Data inicial">
                </div>
                <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                  <input v-model="filtros.data_fim" type="date" class="input-filter-dt form-control" id="filtro_data_fim_logs" name="filtro_data_fim_logs" placeholder="Data final">
                </div>
                <div v-if="idPerfil == 4" class="up col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                  <SoftDropdown2 v-model="object.nome_cidade" :placeholderText="'Nome da cidade'" :minCharacters="2" :columnFilter="'nome'" :rota="'/cidade/select2'"/> 
                </div>
                <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-3 div-filter">
                  <div class="d-md-block d-flex justify-content-center">
                    <button class="btn-filter btn bg-gradient-primary" id="btn-success filtro" @click="filtrar" :class="['btn bg-gradient-' + $store.state.corDinamica]">Filtrar</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="pb-0 card-body position-relative">
              <div v-if="isLoading" class="loading_gif">
                <img src="../assets/img/loading.gif" >
              </div>
              <TableComponent v-if="data.length > 0" @update-dad="filtrar" @is-loading="stopLoading" :tableName="tableName" :columns="columns" :data="data" :corClass="corDinamica"
              :componentDetail="componentDetail" :CanDetail="CanDetail" :nome_filtros="nome_filtros" :filtros="filtros" :key="chave" :idModalDetail="idModalDetail"/>
              <div v-if="data.length === 0">
                <h3 class="text-center">Buscando dados</h3>
                <h3 class="text-center">Com esses filtros</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
  import axios from 'axios';
  import TableComponent from "./components/DataTables";
  import showSwal from "/src/mixins/showSwal.js";
  import SoftDropdown2 from '../components/SoftDropdown2.vue';
  import permissionsmixin from '../mixins/permissions.js';
  import makeTable from '../mixins/makeTable.js';
  import verificacoesmixin from '../mixins/verificacoes.js';

  export default {
    mixins: [permissionsmixin, makeTable, verificacoesmixin, showSwal],
    name: "logsView",
    components: {
      TableComponent,
      SoftDropdown2,
    },
    data() {
      return {
        idPerfil: null,
        tableName: "LOGS",
        columns: ["Id", "User", "Ação", "Página", "Módulo", "Cidade", "Data"],
        getColumnsData: {
          "Id": "id", 
          "User": "user",
          "Ação": "acao",
          "Página": "pagina",
          "Módulo": "modulo",
          "Cidade": "cidade",
          "Data": "data",
        },
        componentDetail: 'ModalsDetail/LogDetail',
        idModalDetail: '#logsModalDetail',
        filtros:{
          user: null,
          titulo_acao: null,
          titulo_pagina: null,
          titulo_modulo: null,
          nome_cidade: null,
          data_inicio: null,
          data_fim: null,
        },
        nome_filtros: {
          titulo_acao: "Ação",
          titulo_pagina: "Página",
          titulo_modulo: "Módulo", 
          nome_cidade: "Cidade",
          data_inicio: "Data Inicial",
          data_fim: "Data Final",
        },
        object: {
          user: null,
          titulo_acao: null,
          titulo_pagina: null,
          titulo_modulo: null,
          nome_cidade: null,
        },
        isLoading: true,
      };
    },
    watch: {
      permissoes: {
        handler() {
          this.verificarPermissaoDaPagina('Logs');
        },
        deep: true,
      },
    },
    computed: {
      corDinamica() {
        return this.$store.state.corDinamica;
      },
      permissoes() {
      return this.$store.state.permissoesUser;
    },
    },
    methods: {
      stopLoading(){
        this.isLoading = false;
      },
      async filtrar() {
        this.isLoading = true;
        this.filtros.user = this.obterDadosSelect(this.object.user, 'value');
        this.filtros.titulo_acao = this.obterDadosSelect(this.object.titulo_acao, 'value');
        this.filtros.titulo_pagina = this.obterDadosSelect(this.object.titulo_pagina, 'value');
        this.filtros.titulo_modulo = this.obterDadosSelect(this.object.titulo_modulo, 'value');
        this.filtros.nome_cidade = this.obterDadosSelect(this.object.nome_cidade, 'value');
        
        this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/logs/tabela`, this.filtros, this.getColumnsData);
      },
    },
    created() {
      if (this.permissoes.length) {
        this.verificarPermissaoDaPagina('Logs');
      }
      this.idPerfil = localStorage.getItem('perfilId'); 
      if (this.idPerfil!=4){
        this.filtros.nome_cidade = localStorage.getItem('nome_cidade') 
      };
      const cor = localStorage.getItem('corDinamica');
      this.$store.commit("atualizarCorDinamica", cor);
      this.verificarPermissoes('Logs')
       
    },
    mounted() {
      this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/logs/tabela`, this.filtros, this.getColumnsData);
    },
  };
</script>
<style scoped>
  input[type=date] {
    text-align: right;
  }

  input[type="date"]:before {
    color: #999;
    content: attr(placeholder) !important;
    margin-right: 0.5em;
  }

  .up{
    height: 53px;
    align-content: center;
  }
</style>