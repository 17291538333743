<template>
  <aside
    id="sidenav-main"
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'"
  >
    <div class="sidenav-header">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/">
        <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" style="width: 15%;"/>
        <span class="ms-1 font-weight-bold"> Ascendant</span>
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :card-bg="customClass" />
  </aside>
</template>
<script>

import SidenavList from "./SidenavList";
import logo from "@/assets/img/iiVAF.png";
import { mapState } from "vuex";
import axios from 'axios';
export default {
  name: "IndexComponent",
  components: {
    SidenavList,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      logo,
    };
  },
  computed: {
    ...mapState(["isRTL"]),
  },
  mounted() {
    var login = localStorage.getItem('user');
    const id_cidade = localStorage.getItem('id_cidade');
    if (login){
      const API_URL = process.env.VUE_APP_API_BASE_URL + `/preferencias/${login}`;
      axios.get(API_URL, { params: { id_cidade: id_cidade } })
        .then(response => {
          
          this.color = response.data.cor
          try{
            document.querySelector("#sidenav-main").setAttribute("data-color", this.color);
          }catch{
            
          }
          this.$store.state.mcolor = `card-background-mask-${this.color}`;
        })
        .catch(error => {
          console.error('Erro ao buscar os dados:', error);
        });  
      }
}
};
</script>
