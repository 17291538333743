<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
              <div class="w-100">
                <div class="row">
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-4 div-filter">
                    <input v-model="filtros.nome" class="input-filter-dt form-control" id="filtro_nome_usuarios" name="filtro_nome_usuarios" placeholder="Nome">
                  </div>
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                    <SoftDropdown2 v-model="object.login" :placeholderText="'CPF ou CNPJ'" :minCharacters="3" :columnFilter="'login'" :rota="'/usuarios/select2'" ref="loginDropdown"/>
                  </div> 
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                    <input v-model="filtros.email" class="input-filter-dt form-control" id="filtro_email_usuarios" name="filtro_email_usuarios" placeholder="E-mail">
                  </div>
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-2">
                    <SoftDropdown v-model="object.status" :placeholderText="'Status'" :data="[{text: 'Ativo', value: 1}, {text: 'Inativo', value: 0}]"/>
                  </div> 
                </div>
                <div class="row">
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                    <SoftDropdown2 v-model="object.perfil" :placeholder-text="'Perfil'" :column-filter="'titulo_perfil'" :rota="'/perfil-acesso/select2'" :minCharacters="0"/>
                  </div> 
                  
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                    <SoftDropdown v-model="object.homologado" :placeholderText="'Homologado'" :data="[{text: 'Sim', value: 'Sim'}, {text: 'Não', value: 'Não'}]"/>
                  </div>
                  
                  <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 div-filter">
                    <div class="d-md-block d-flex justify-content-center">
                      <button :class="['btn-filter', 'btn bg-gradient-' + this.$store.state.corDinamica]" id="btn-success filtro" @click="filtrar">Filtrar</button>
                    </div>
                  </div>
                </div>
              </div> 
              <AddModal @update-dad="filtrar" :corClass="corDinamica"/>
              <div class="pb-0 card-body position-relative">
                <div v-if="isLoading" class="loading_gif">
                  <img src="../assets/img/loading.gif" >
                </div>
                <TableComponent @update-dad="filtrar" @is-loading="stopLoading" :tableName="tableName" :columns="columns" :data="data" :corClass="corDinamica"
                :componentEdit="componentEdit" :routeDelete="routeDelete" :idModalAdd="idModalAdd" :idModalEdit="idModalEdit" :CanReativar="CanReativar" :CanAdd="CanAdd" :TextButtonAdd="TextButtonAdd" :CanHomologar="CanHomologar" :CanEditUser="CanEdit" :CanDeleteUser="CanDelete" :filtros="filtros" :nome_filtros="nome_filtros" :key="chave"/>
                <div v-if="data.length === 0">
                  <h3 class="text-center">Buscando dados</h3>
                  <h3 class="text-center">Com esses filtros</h3>
                </div>
              </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
  
</template>
  
<script>
import TableComponent from "./components/DataTables";
import AddModal from "./components/ModalsAdd/usuariosAdd";
import SoftDropdown2 from '../components/SoftDropdown2.vue';
import SoftDropdown from '../components/SoftDropdown';
import permissionsmixin from '../mixins/permissions.js';
import makeTable from '../mixins/makeTable.js';
import verificacoesmixin from '../mixins/verificacoes.js';

export default {
  mixins: [permissionsmixin, makeTable, verificacoesmixin],
  name: "UsuariosView",
  components: {
    TableComponent,
    AddModal,
    SoftDropdown2,
    SoftDropdown
  },
  data() {
    return {
      valorLogin: null,
      valorPerfil: null,
      tableName: "USUÁRIOS",
      columns: ["Id", "Nome", "Cpf / Cnpj", "Email", "Perfil", "Homologado", "Status"],
      getColumnsData: {
        'Id': 'id',
        'Nome': 'nome',
        'Cpf / Cnpj': 'login',
        'Email': 'email',
        'Perfil': 'perfil',
        'Homologado': 'homologado',
        'Status': item => item.status == 1 ? "Ativo" : (item.status == 0 ? "Inativo" : item.status),
      },
      componentEdit: 'ModalsEdit/usuariosEdit',
      routeDelete: 'usuarios/delete',
      idModalAdd: '#usuariosModalAdd',
      idModalEdit: '#usuariosModalEdit',
      TextButtonAdd: '+ Adicionar Usuário',
      chave: 0,
      filtros: {
        nome: null,
        login: null,
        perfil: null,
        email: null,
        homologado: null,
        status: 1
      },
      nome_filtros: {
        nome: "Nome",
        login: "CPF ou CNPJ",
        perfil: "Perfil",
        email: "E-mail",
        homologado: "Homologado",
        status: "Status"
      },
      object: {
        login: null,
        perfil: null,
        homologado: null,
        status: {
          text: 'Ativo',
          value: 1}
      },
      isLoading: true,

      
    };
  },
  computed: {
    corDinamica() {
      return this.$store.state.corDinamica;
    },
  },
  methods: {
    stopLoading(){
      this.isLoading = false;
    },
    async filtrar() {
      this.isLoading = true;

      this.filtros.login = this.obterDadosSelect(this.object.login, 'text');
      this.filtros.perfil = this.obterDadosSelect(this.object.perfil, 'value');
      this.filtros.homologado = this.obterDadosSelect(this.object.homologado, 'value');
      this.filtros.status = this.obterDadosSelect(this.object.status, 'value');

      this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/usuarios/tabela`, this.filtros, this.getColumnsData);
    },
  },
  created() {
    const cor = localStorage.getItem('corDinamica');
    this.$store.commit("atualizarCorDinamica", cor);
    this.verificarPermissoes('Usuários');
  },
  mounted() {
    this.obterTabela(process.env.VUE_APP_API_BASE_URL + `/usuarios/tabela`, this.filtros, this.getColumnsData);
    
  },
};
</script>


<style>

@media screen and (max-width: 768px) {
  .div-filter{
    margin-bottom: 2% !important;
  }
}


</style>