<template>
  <div>
      <div class="modal fade" id="acoesModalAdd">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="row">
                <div class="col-11">
                  <h5 class="modal-title-perfil">Cadastrar Ação</h5>
                </div>
                <div class="col-1">
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Fechar">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>    
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-6">
                    <label>Titulo da Ação</label>
                    <input type="text" v-model="selected.titulo_acao" class="form-control" id="titulo_acao_acoes_add" name="titulo_acao_acoes_add" placeholder="Titulo da Ação">
                  </div>
                  <div class="col-6" style="display: inline-grid;">
                    <label>Titulo da Página</label>
                    <SoftDropdown2 
                      v-model="object.titulo_pagina" 
                      :placeholderText="'Titulo da Página'" 
                      :minCharacters="2" 
                      :columnFilter="'titulo_pagina'" 
                      :rota="'/paginas/select2'"
                      ref="paginaDropdown" 
                      :customClass="'dropdown_no_hpercent'"
                      :key="chave"
                    />
                  </div>
                </div> 
                           
              </form> 
            </div>
            <div class="modal-footer">
              <soft-button class="my-4 mb-2" variant="gradient" color="secondary" data-bs-dismiss="modal">
                <span>Cancelar</span>
              </soft-button>
              <div style="width: 100px;">
                <soft-button class="my-4 mb-2" variant="gradient" :color="corClass" full-width @click="SalvarAcao()"
                  :is-disabled="loading ? true : false">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <span v-else>Salvar</span>
                </soft-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import axios from 'axios';
import SoftButton from '../../../components/SoftButton.vue';
import SoftDropdown2 from "../../../components/SoftDropdown2";
import verificacoesmixin from '../../../mixins/verificacoes.js';

export default {
  mixins: [verificacoesmixin],
  props:{
    corClass: String
  },
  name: 'AddPerfilAcesso',
  data() {
    return {
      loading: false,
      selected: {
        titulo_acao: null,
        id_pagina: null,
      },
      object: {
        titulo_pagina: null,
      },
      chave: 0,
    };
  },
  components: {
    SoftButton,
    SoftDropdown2,
  },
  methods: {
    SalvarAcao() {
      this.loading = true;
      this.selected.id_pagina = this.obterDadosSelect(this.object.titulo_pagina, 'id');
      
      if (this.verificarCamposVazios(this.selected)) {
        this.loading = false;
        return;
      }

      const user = localStorage.getItem('user');
      const id_cidade = localStorage.getItem('id_cidade');

      const apiUrl = process.env.VUE_APP_API_BASE_URL + '/acoes/adicionar';
        axios.post(apiUrl,{
          acao_title: this.selected.titulo_acao,
          id_pagina: this.selected.id_pagina,
          id_cidade: id_cidade,
          user: user,
      })
      .then(response => {
        if (response.data.success){
          this.clearUpData();
          $('#acoesModalAdd').modal('hide');
          this.showMessage('success', 'Ação adicionada com sucesso!');
          this.$emit('update-dad');
        } else {
          this.showMessage('danger', 'Erro ao adicionar ação!');
        }
      })
      .catch(error => {
        this.showMessage('danger', 'Erro ao adicionar ação!');
      })
      .finally(() => {
        this.loading = false;
      });
    },
    clearUpData() {
      this.selected = {
        titulo_acao: null,
        id_pagina: null,
      };
      this.object = {
        titulo_pagina: null,
      };
      this.chave++;
    },
  }
};
</script>


