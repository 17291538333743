<template>
    <div>
      <dynamic-chart :chart-option="option" :chart-width="'600px'" :chart-height="'500px'" ></dynamic-chart>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import DynamicChart from '../../../components/charts/DynamicChart.vue';
  
  export default {
    components: {
      DynamicChart
    },
    data() {
      return {
        option: {},
      }
    },
    created() {
      this.fetchData();
    },
    methods: {
      fetchData() {
        const API_URL = process.env.VUE_APP_API_BASE_URL + `/dashboard/grafico-va-relacao-ano-anterior`;
        axios.get(API_URL)
          .then(response => {
            this.createLineChart(response.data);
          })
          .catch(error => {
            console.error('Erro ao obter os dados:', error);
          });
      },
      createLineChart(dados) {

        const colors = ['blue', 'purple']; 
        const totalAnoAnterior = dados['total_ano_anterior'];
        const totalAnoAtual = dados['total_ano_atual'];
        const anoAnterior = dados['ano_anterior'];
        const anoAtual = dados['ano_atual'];
        
        const linha1 = dados['valoradicionado_ano_anterior'];

        // const linha1 = [
        //   { value: 552343, name: 'Janeiro', ano: '2023' },
        //   { value: 244424, name: 'Fevereiro', ano: '2023' },
        //   { value: 3233334, name: 'Março', ano: '2023' },
        //   { value: 556652, name: 'Abril', ano: '2023' },
        //   { value: 123334, name: 'Maio', ano: '2023' },
        //   { value: 15456, name: 'Junho', ano: '2023' },
        //   { value: 2355240, name: 'Julho', ano: '2023' },
        //   { value: 34322, name: 'Agosto', ano: '2023' },
        //   { value: 14343342, name: 'Setembro', ano: '2023' },
        //   { value: 13422, name: 'Outubro', ano: '2023' },
        //   { value: 145322, name: 'Novembro', ano: '2023' },
        //   { value: 1253442, name: 'Dezembro', ano: '2023' },
        // ];

        const linha2 = dados['valoradicionado_ano_atual'];
        const newData = [linha1, linha2];

        const seriesData = newData.map((linha, index)=> ({
          name: linha[0].ano,
          data: linha.map(item => item.value),
          type: 'line',
          lineStyle: {
            color: colors[index % colors.length]
          },
          itemStyle: {
            color: colors[index % colors.length]
          }
        }));
  
        const xAxisData = newData[0].map(item => item.name);
  
        this.option = {
          title: {
            text: 'Valor Adicionado',
            subtext: 'Mensalmente entre 2023 e 2024',
            left: 'center'
          },
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            right: 'right',
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxisData
          },
          yAxis: {
            type: 'value',
            axisLabel: {
                formatter: 'R$ {value}',
                fontSize: 10
            }
          },
          series: seriesData,
          graphic: [
            {
              type: 'text',
              left: 'left',
              top: 'top',
              style: {
                text: `Total ${anoAnterior}: R$ ${totalAnoAnterior}`,
                fill: '#333',
                fontSize: 13,
                fontWeight: 'bold'
              }
            },
            {
              type: 'text',
              left: 'left',
              top: '5%',
              style: {
                text: `Total ${anoAtual}: R$ ${totalAnoAtual}`,
                fill: '#333',
                fontSize: 13,
                fontWeight: 'bold'
              }
            }
          ]
        };
      }
    }
  };
  </script>
  