<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div
              class="px-5 col-xl-5 col-lg-6 col-md-8 col-12 d-flex flex-column"
            >
              <div class="mt-8 card card-plain">
                <div class="pb-0 text-left card-header">
                  <h3 class="text-primary text-gradient">Redefinir Senha</h3>
                  <p class="mb-0">Escolha uma Nova Senha</p>
                </div>
                <div class="pb-3 card-body">
                  <FormA
                    role="form"
                    class="text-start"
                    :validation-schema="schema"
                    @submit="handleResetPassword"
                  >
                    <label>Senha</label>
                    <soft-field
                      id="password"
                      v-model="user.password"
                      type="password"
                      placeholder="Senha"
                      name="password"
                    />
                    <label>Confirme sua Senha</label>
                    <soft-field
                      id="password_confirmation"
                      v-model="user.password_confirmation"
                      type="password"
                      placeholder="Confirme sua Senha"
                      name="password_confirmation"
                    />
                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="primary"
                        full-width
                        :is-disabled="loading ? true : false"
                      >
                        <span
                          v-if="loading"
                          class="spinner-border spinner-border-sm"
                        ></span>
                        <span v-else>Redefinir</span>
                      </soft-button>
                    </div>
                  </FormA>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="m-3 position-relative bg-gradient-primary h-100 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                  src="@/assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0"
                />
                <div class="position-relative">
                  <img
                    class="max-width-500 w-100 position-relative z-index-2"
                    src="@/assets/img/illustrations/lock.png"
                    alt="lock-svg"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Soft UI Design
                </h4>
                <p class="text-white">
                  Just as it takes a company to sustain a product, it takes a
                  community to sustain a protocol.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import axios from 'axios';
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftField from "@/components/SoftField.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import { Form as FormA } from "vee-validate";
import * as yup from "yup";
import showSwal from "/src/mixins/showSwal.js";

import { mapMutations } from "vuex";
export default {
  name: "ResetPassword",
  components: {
    Navbar,
    AppFooter,
    SoftField,
    SoftButton,
    FormA,
  },

  data() {
    const schema = yup.object().shape({
      password: yup
        .string()
        .required("Senha é obrigatória!")
        .min(8, "Senha deve ter no mínimo 8 caracteres!"),
      password_confirmation: yup
        .string()
        .test("passwords-match", "Senhas não coincidem", function (value) {
          return this.parent.password === value;
        }),
    });
    const user = {
      email: this.$route.query.email,
      token: this.$route.query.token,
      id_cidade: "",
      password: "",
      password_confirmation: "",
    };
    return {
      loading: false,
      user,
      schema,
      path_cidade: null,
      cidade: [],
    };
  },

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
    this.path_cidade = this.$route.params.path_cidade;
    if ((localStorage.getItem('path_cidade') == this.path_cidade) && (localStorage.getItem('id_cidade') != null) && (localStorage.getItem('nome_cidade') != null)){
      this.cidade['id'] = localStorage.getItem('id_cidade');
      this.cidade['path'] = localStorage.getItem('path_cidade');
      this.cidade['nome'] = localStorage.getItem('nome_cidade');
    } else {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/cidades/${this.path_cidade}`)
        .then(response => {
          try {
            if (response.data['id'] == null || response.data['id'] == undefined) {
              this.$router.push("/error");
            }
            if (response.data['path'] == null || response.data['path'] == undefined) {
              this.$router.push("/error");
            }
            this.cidade = response.data;
            localStorage.setItem('id_cidade', response.data['id']);
            localStorage.setItem('path_cidade', response.data['path']);
            localStorage.setItem('nome_cidade', response.data['nome']);
          } catch (error) {
            
            this.$router.push("/error");
          }
        })
        .catch(error => {
          
          this.$router.push("/error");
        });
    }
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async handleResetPassword() {
      this.loading = true;
      this.user.id_cidade = this.cidade.id;
      try {
        await this.$store.dispatch("reset/resetPassword", this.user);
        delete this.user.token;
        delete this.user.password_confirmation;
        this.loading = false;
        showSwal.methods.showSwal({
          type: "success",
          message:
            "Senha alterada com sucesso! Você está sendo redirecionado para a página de login.",
          width: 850,
        });
        this.$router.push("/saosebastiao/login");
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Algo deu errado! Por favor, reenvie o e-mail de redefinição.",
          width: 650,
          timer: 4000,
        });
        this.loading = false;
      }
    },
  },
};
</script>
