<template>
  <div>
    <div class="modal fade" id="giaImportModalAdd" ref="giaImportModalAdd">
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row">
              <div class="col-11">
                <h5 class="modal-title-perfil">Importar GIA</h5>
              </div>
              <div class="col-1">
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Fechar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <div v-show="layout1" class="modal-body">
            <div class="mb-3">
              <input @change="handleFileChange" type="file" class="form-control" id="inputGroupFile03" name="inputGroupFile03" aria-describedby="inputGroupFileAddon03" aria-label="Upload">
            </div>
            <div class="form-check form-switch" style="margin-top: 2%;">
              <input
                type="checkbox"
                id="isSefaz"
                name="isSefaz"
                class="mt-1 form-check-input"
                @change="changeSefaz()"
              />
              <span style="font-size: 14px;">GIA Transmitida no SEFAZ?</span>
            </div>
          </div>
          <div v-show="layout1">
            <div class="modal-footer">
              <soft-button class="my-4 mb-2" variant="gradient" color="secondary" data-bs-dismiss="modal">
                <span>Cancelar</span>
              </soft-button>
              <div style="width: 110px;">
                <soft-button class="my-4 mb-2" variant="gradient" :color="corClass" full-width @click="importgia('N')"
                    :is-disabled="loading ? true : false">
                    <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                    <span v-else>Importar</span>
                </soft-button>
              </div>
            </div>
          </div>
          <div v-show="!layout1">
            <div class="modal-body">
              <span>Já existe um arquivo importado para a referência {{ ref }}, deseja substituir?</span>
            </div>
            <div class="modal-footer">
              <soft-button class="my-4 mb-2" variant="gradient" color="secondary" @click="back()">
                <span>Voltar</span>
              </soft-button>
              <div style="width: 120px;">
                <soft-button class="my-4 mb-2" variant="gradient" :color="corClass" full-width @click="importgia('S')" :is-disabled="loading ? true : false">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <span v-else>Substituir</span>
                </soft-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import SoftButton from '../../../components/SoftButton.vue';
import axios from "axios";
import verificacoesmixin from '../../../mixins/verificacoes.js';

export default {
    mixins: [verificacoesmixin],
    name: 'GiaView',
    props:{
        corClass: String
    },
    data() {
      return {
        file: null,
        layout1: true,
        loading: false,
        ref: null,
        isSefaz: false,
      };
    },
    components: {
        SoftButton,
    },
    methods: {
      changeSefaz(){
        this.isSefaz = !this.isSefaz;
      },
      back(){
        this.layout1 = true;
      },
      handleFileChange(event) {
        this.file = event.target.files[0];
      },
      importgia(substituir){
        this.loading = true;
        let formData = new FormData();
        formData.append('data', this.file);
        formData.append('is_substituir', substituir);
        formData.append('is_sefaz', this.isSefaz);
        formData.append('id_usuario', localStorage.getItem('id_usuario'));
        formData.append('user', localStorage.getItem('login'));
        formData.append('nome', localStorage.getItem('nome'));
        formData.append('id_cidade', localStorage.getItem('id_cidade'));
        formData.append('id_perfil', localStorage.getItem('perfilId'));
        let API_URL = process.env.VUE_APP_API_BASE_URL + `/import-gia-prf`;
  
        axios.post(API_URL, formData)
          .then(response => {
            if (response.data.status == true) {
              if (response.data.msg == "substituir"){
                this.ref = response.data.ref;
                this.layout1 = false;
              } else {
                this.layout1 = true;
                $('#giaImportModalAdd').modal('hide');
                this.showMessage('success', response.data.msg);
                this.$emit('update-dad');
                this.file = null;
              }
            } else {
              this.showMessage('danger', response.data.msg);
            }
          })
          .catch(error => {
            console.error('Erro ao buscar os dados:', error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  };
</script>
    
    