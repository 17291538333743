<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="w-100">
              <div class="row">
                <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 div-filter">
                  <SoftDropdown v-model="object.ano_base" :placeholderText="'Ano base'" :data="dataCompetencia" ></SoftDropdown>
                </div>
                <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 div-filter">
                  <SoftDropdown2 :key="chave" v-model="object.ipm_1" id="ipm_1" :placeholderText="'1° IPM'" :minCharacters="0" :columnFilter="'ipm'" :rota="'/consulta_comparativo/select2/'+ano_base" :disabled="isDisabled"></SoftDropdown2>
                </div>
                <div class="up col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 div-filter">
                  <SoftDropdown2  :key="chave" v-model="object.ipm_2" id="ipm_2" :placeholderText="'2° IPM'" :minCharacters="0" :columnFilter="'ipm'" :rota="'/consulta_comparativo/select2/'+ano_base" :disabled="isDisabled"></SoftDropdown2>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 div-filter">
                  <div class="d-sm-block d-flex justify-content-center">
                    <button :class="'btn-filter btn bg-gradient-' + this.$store.state.corDinamica" id="btn-success filtro" @click="gerarRelatorio">Gerar Relatório</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
  <script>
    import axios from 'axios';
    import permissionsmixin from '../mixins/permissions.js';
    import verificacoesmixin from '../mixins/verificacoes.js';   
    import SoftDropdown2 from '../components/SoftDropdown2.vue';
    import SoftDropdown from '../components/SoftDropdown.vue';

  
    export default {
      mixins: [verificacoesmixin],
      name: "ComparativoValorAdicionadoView",
      components: {     
        SoftDropdown2,
        SoftDropdown,
      },
      data() {
        return {
          isDisabled: true,
          chave: 0,
          ano_base: 0,
          object: {
            ipm_1: null,
            ipm_2: null,
            ano_base: null,
          },
          dataCompetencia: [],
          selected: {
            ipm_1: null,
            ipm_2: null,
            ano_base: null,
          },
          text: {
            ipm_1: null,
            ipm_2: null,
            ano_base: null,
          },
        };
      },
      watch: {
        'object.ano_base'(newValue) {
          if (newValue) {
            this.ano_base = this.obterDadosSelect(newValue, 'value');
            this.isDisabled = false;
            this.chave++ 
          } else {
            this.isDisabled = true;
            this.chave++
          }
        }
      },
      computed: {
        corDinamica() {
          return this.$store.state.corDinamica;
        },
      },
      methods: {
        async gerarRelatorio() {
          const user = localStorage.getItem('nome');
          const id_cidade = localStorage.getItem('id_cidade');
          const API_URL = process.env.VUE_APP_API_BASE_URL + `/gerar_relatorio_comparativo`;
          this.selected.ano_base = this.obterDadosSelect(this.object.ano_base, 'value');
          this.selected.ipm_1 = this.obterDadosSelect(this.object.ipm_1, 'value');
          this.selected.ipm_2 = this.obterDadosSelect(this.object.ipm_2, 'value');
          this.text.ano_base = this.obterDadosSelect(this.object.ano_base, 'text');
          this.text.ipm_1 = this.obterDadosSelect(this.object.ipm_1, 'text');
          this.text.ipm_2 = this.obterDadosSelect(this.object.ipm_2, 'text');
          
          try {
            const response = await axios.post(API_URL, {
              user: user,
              id_cidade: id_cidade,
              ano_base: this.selected.ano_base,
              ipm_1: this.selected.ipm_1,
              ipm_2: this.selected.ipm_2,
              text_ano_base: this.text.text_ano_base,
              text_ipm_1: this.text.ipm_1,
              text_ipm_2: this.text.ipm_2,
              }, {
                responseType: 'arraybuffer',
            });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            window.open(url, 'filename');

          } catch (error) {
            this.showMessage('danger', 'Erro ao gerar relatório!');
          }
        },
        getSelectDataCompetencia() {
        const API_URL = process.env.VUE_APP_API_BASE_URL + `/consulta_competencia_ano/select2`;
        axios.get(API_URL).then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            this.dataCompetencia.push({text: response.data[i].competencia_ano, value: response.data[i].competencia_ano});
          }
        });
      }
      },
      created() {
        const cor = localStorage.getItem('corDinamica');
        this.$store.commit("atualizarCorDinamica", cor);
      },
      mounted() {
        this.getSelectDataCompetencia();
      },
    };
  </script>