<template>
  <div>
    <div class="modal fade" id="modulosModalAdd">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row">
              <div class="col-11">
                <h5 class="modal-title">Cadastro de Módulo</h5>
              </div>
              <div class="col-1">
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Fechar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-6 form-group">
                  <label for="titulo_modulo_modulos_add">Nome do Módulo</label>
                  <input type="text" v-model="selected.titulo_modulo" class="form-control" id="titulo_modulo_modulos_add" name="titulo_modulo_modulos_add" placeholder="Nome do Módulo">
                </div>
                <div class="col-6 form-group">
                  <label for="posicao_modulo_modulos_add">Posição do Módulo</label>
                  <input type="number" v-model="selected.posicao_modulo" class="form-control" id="posicao_modulo_modulos_add" name="posicao_modulo_modulos_add" placeholder="Digite a posição">
                </div>
              </div> 
              <div class="row">
                <div class="col-6" style="display: inline-grid;">
                  <label for="nome">Ícone</label>
                  <icon-picker ref="meuIconPicker"></icon-picker>
                </div>
              </div>         
            </form> 
          </div>
          <div class="modal-footer">
            <soft-button class="my-4 mb-2" variant="gradient" color="secondary" data-bs-dismiss="modal">
              <span>Cancelar</span>
            </soft-button>
            <div style="width: 100px;">
              <soft-button class="my-4 mb-2" variant="gradient" :color="corClass" full-width @click="SalvarModulo()"
                :is-disabled="loading ? true : false">
                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                <span v-else>Salvar</span>
              </soft-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import iconPicker from "./iconPicker";
import SoftButton from '../../../components/SoftButton.vue';
import verificacoesmixin from '../../../mixins/verificacoes.js';

export default {
  mixins: [verificacoesmixin],
  name: 'modulosAdd',
  props:{
    corClass: String
  },
  components: {
    iconPicker,
    SoftButton
  },
  data() {
    return {
      loading: false,
      selected: {
        titulo_modulo: null,
        posicao_modulo: null,
        icon_modulo: null,
      },
    };
  },
  methods: {
    SalvarModulo() {
      this.loading = true;
      this.selected.icon_modulo = this.$refs.meuIconPicker.$refs.iconpicker.value;

      const user = localStorage.getItem('user');
      const id_cidade = localStorage.getItem('id_cidade');

      var exit = this.verificarCamposVazios(this.selected);
      if(exit == true){
        this.loading = false;
        return;
      }
      
      const apiUrl = process.env.VUE_APP_API_BASE_URL + '/modulos/adicionar';
      axios.post(apiUrl,{
        modulo_title: this.selected.titulo_modulo,
        modulo_position: this.selected.posicao_modulo,
        selectIcon_pag: this.selected.icon_modulo,
        user: user,
        id_cidade: id_cidade
      })

      .then(response => {
        if (response.data.success){
          this.clearUpData();
          this.showMessage('success', 'Módulo adicionado com sucesso!');
          $('#modulosModalAdd').modal('hide');
          this.$emit('update-dad');
        } else {
          this.showMessage('danger', 'Erro ao adicionar módulo!')
        }
      })
      .catch(() => {
        this.showMessage('danger', 'Erro ao adicionar módulo!')
      })
      .finally(() => {
        this.loading = false;
      });
    },
    clearUpData() {
      this.selected = {
        titulo_modulo: null,
        posicao_modulo: null,
        icon_modulo: null,
      };
      this.$refs.meuIconPicker.$refs.iconpicker.value = null;
    },
  },
}
</script>

<style scoped>
 .form-group{
  font-size: 20px !important;
 }
</style>
  
  