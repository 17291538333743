function pop(value){
  if (value === undefined || value === null) return value;
    value = value.replace(/\D/g,""); 
    return value;
}   

function removeAccents(str) {
    const accentMap = {
      'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
      'à': 'a', 'è': 'e', 'ì': 'i', 'ò': 'o', 'ù': 'u',
      'â': 'a', 'ê': 'e', 'î': 'i', 'ô': 'o', 'û': 'u',
      'ä': 'a', 'ë': 'e', 'ï': 'i', 'ö': 'o', 'ü': 'u',
      'ã': 'a', 'õ': 'o',
      'ç': 'c',
      'ñ': 'n',
    };
    try{
          return str.replace(/[áéíóúàèìòùâêîôûäëïöüãõçñ]/g, char => accentMap[char] || char);
    }catch{
      return str
    }
  }

export default { pop, removeAccents };