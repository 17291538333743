<template>
  <DropdownSoft
    v-model="selectedOption"
    showClear
    
    appendTo="self"
    :options="filteredOptions"
    optionLabel="text"
    :placeholder="placeholderText"
    :class="'w-full md:w-14rem dropdownsoft '+ customClass"
    :disabled="isDisabled"
  >
    <template #empty>
      {{ loading ? 'Buscando...' : emptyMessage }}
    </template>
    <template #header>
      <div class="p-dropdown-filter-container">
        <input
          id="search_dropdown2"
          name="search_dropdown2"
          type="text"
          v-model="optionFiltro"
          class="form-control "
          role="searchbox"
          autocomplete="off"
          data-pc-section="filterinput"
          placeholder="Digite aqui"
        />
      </div>
    </template>
  </DropdownSoft>

</template>

<script>
import axios from 'axios';
import maskCpfCnpj from "@/assets/js/maskCpfCnpj.js";

export default {
  name: 'SoftDropdown2',
  props: {
    placeholderText: {
      type: String,
      default: 'Selecione uma opção',
    },
    minCharacters: {
      type: Number,
      default: 3,
    },
    columnFilter: {
      type: String,
      required: true,
    },
    rota: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    emptyCustomEdit: {
      type: Boolean,
      default: false,
    },
    uniqueOptions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: null,
      optionFiltro: null,
      options: [],
      filteredOptions: [],
      emptyMessage: `Mínimo ${this.minCharacters} caracteres`,
      searchTimer: null,
      loading: false,
      isDisabled: this.disabled,
      caches: {},
      oldValue: null,
      vazio: false,
      zeroValue: false,
    };
  },
  watch: {
    selectedOption(newValue) {
      if (newValue) {
        this.$emit('input', newValue);
      } else {
        this.$emit('input', null);
      }
    },
    optionFiltro(newValue) {
      
      if(newValue){
        this.vazio = false;
          if (newValue.length < this.minCharacters){
              this.filteredOptions = [];
              this.options = [];
              let number = this.minCharacters - newValue.length;
              this.emptyMessage = `Digite mais ${number} caracteres`;
              return;
          }
      } else if (this.minCharacters !== 0) {
          this.vazio = true;
          this.filteredOptions = [];
          this.options = [];
          this.emptyMessage =  `Mínimo ${this.minCharacters} caracteres`;
          return;
      } else {
        if (this.caches['']){
          setTimeout(() => {
            this.zeroValue = true;
            this.filteredOptions = this.caches[''];
            this.emptyMessage = 'Não Encontrado';
            
            return;
          }, 600);
        } else {
          this.fetchOptions('');
        }
      }
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
          if(newValue){
            
              if (newValue.length >= this.minCharacters){
                if (this.caches[newValue]){
                  this.filteredOptions = this.caches[newValue];
                  this.emptyMessage = 'Não Encontrado';

                  return;
                }
                
                if(this.oldValue){
                  if (this.oldValue.length > newValue.length) {
                    this.oldValue = newValue;
                    this.fetchOptions(newValue);
                    return;
                  }
                }
                

                if (this.options.length < 100 && this.options.length > 0 && !this.zeroValue && this.columnFilter != 'empresa') {
                  this.filteredOptions = this.filterOptionsLocally(newValue);
                  this.emptyMessage = 'Não Encontrado';
                  if (this.vazio){
                    this.filteredOptions = [];
                    this.options = [];
                    this.emptyMessage =  `Mínimo ${this.minCharacters} caracteres`;
                    this.loading = false;
                    return;
                  }
                  return;
                }
                this.oldValue = newValue;
                this.fetchOptions(newValue);
              }
          } else {
            this.zeroValue = false;
          } 
      }, 600);
    },
  },

  methods: {
    fetchOptions(query) {
      this.loading = true;
      this.emptyMessage = 'Buscando...';
      if (typeof query === 'string') {
        if (this.vazio){
          this.filteredOptions = [];
          this.options = [];
          this.emptyMessage =  `Mínimo ${this.minCharacters} caracteres`;
          this.loading = false;
          return;
        }
        var API_URL = process.env.VUE_APP_API_BASE_URL + this.rota;
        axios
          .get(API_URL, {
            params: {
              q: query,
              column: this.columnFilter,
              id_cidade: localStorage.getItem('id_cidade'),
              id_usuario: localStorage.getItem('id_usuario'),
            },
          })
          .then((response) => {
            this.options = response.data.map((option) => {
              let cleanValue;
              if (this.columnFilter == 'login' || this.columnFilter == 'cnpj' || this.columnFilter == 'cpf'){
                cleanValue = option[this.columnFilter].replace(/[^\d]/g, '');
              } else if (this.columnFilter == 'user') {
                cleanValue = option[this.columnFilter].replace(/[^\d]/g, '');
              } else if (this.columnFilter == 'empresa') {
                cleanValue = option['cnpj'].replace(/[^\w\s]/gi, '');
              } else if (this.columnFilter == 'cfop_entrada' || this.columnFilter == 'cfop_saida') {
                cleanValue = option['cfop'];
              } else if (this.columnFilter == 'cnae_completo') {
                cleanValue = option['cnae'].replace(/[^\w\s]/gi, '');
              } else if (this.columnFilter == 'ipm') {
                cleanValue = option['id'];
              } else {
                cleanValue = option[this.columnFilter];
              }

              let customValue;
              if (this.columnFilter == 'user'){
                let temp = option[this.columnFilter].split('|');
                
                customValue = temp[0] + temp[1].replace(/[^\d]/g, '');
              } else {
                customValue = option[this.columnFilter];
              }

              return {
                custom: customValue,
                value: cleanValue,
                id: option.id,
                text: option[this.columnFilter],
              };
            });

            if (this.uniqueOptions) {
              const uniqueValues = new Set(this.options.map(option => option.value));
              this.options = Array.from(uniqueValues).map(value => {
                  const option = this.options.find(opt => opt.value === value);
                  return {
                      custom: option.custom,
                      value: option.value,
                      id: option.id,
                      text: option.text,
                  };
              });
            }
            
            this.caches[query] = this.options;
            this.filteredOptions = this.options;
            this.emptyMessage =
              this.filteredOptions.length === 0
                ? 'Não Encontrado'
                : 'Selecione uma opção';

            if (this.vazio){
              this.filteredOptions = [];
              this.options = [];
              this.emptyMessage =  `Mínimo ${this.minCharacters} caracteres`;
              this.loading = false;
              return;
            }
          })
          .catch((error) => {
            console.error('Erro ao buscar os dados:', error);
            this.emptyMessage = 'Erro ao buscar os dados';

          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.filteredOptions = this.options;

        this.emptyMessage =
          this.filteredOptions.length === 0
            ? 'Não Encontrado'
            : 'Selecione uma opção';

          this.loading = false;
          if (this.vazio){
            this.filteredOptions = [];
            this.options = [];
            this.emptyMessage =  `Mínimo ${this.minCharacters} caracteres`;
            this.loading = false;
            return;
          }
      }
    },
    filterOptionsLocally(query) {
      if (this.columnFilter == 'user' || this.columnFilter == 'ipm'){
        query = maskCpfCnpj.removeAccents(query)
        return this.options.filter((option) => {
          const normalizedOptionValue = maskCpfCnpj.removeAccents(option.custom).toLowerCase();
          return normalizedOptionValue.includes(query);
        });
      } else {
        query = maskCpfCnpj.removeAccents(query)
        return this.options.filter((option) => {
          const normalizedOptionValue = maskCpfCnpj.removeAccents(option.value).toLowerCase();
          return normalizedOptionValue.includes(query);
        });
      }
      
    },
  },

  mounted() {
    if (this.emptyCustomEdit) {
      this.emptyMessage = 'Aperte no X para limpar';
    }
    if (this.minCharacters == 0) {
      this.fetchOptions('');
    }
    this.filteredOptions = this.options;
    if (this.data.length > 0) {
      this.options = this.data;
      this.filteredOptions = this.data;
      this.selectedOption = this.data;
      this.$emit('input', this.selectedOption);
      
    }
  },
};
</script>

<style>
.dropdownsoft {
/* height: 75%; */
width: 100%;
border-radius: 0.5rem;;
background-color: #fff;
font-size: 0.875rem;
border-color: #DDE0E3 !important;

}

.dropdownsoft .p-dropdown-label.p-inputtext.p-placeholder {
  margin-top: -5px; 
  font-size: 0.875rem;
}

.dropdownsoft .p-dropdown-label {
  margin-top: -4px;
  font-size: 0.875rem; 
}

.p-dropdown-panel {
z-index: 2000 !important;
max-width: 100% !important;
}
.p-dropdown-filter-container {
display: flex;
align-items: center;
padding: 0.5rem;
border-bottom: 1px solid #DDE0E3;
z-index: 3300 !important;

}

.p-dropdown-items {
    display: inline-block;
    min-width: 100%;
}

.ol,
ul {
padding-left: 0 !important;
}


</style>
