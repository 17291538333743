<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main v-if="layout" class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-80">
        <div class="container" style="margin-left: 10%;">
          <div >
            <div
              class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto"
            >
              <div>
                <div style=" width: 500px !important; margin-top: 25%;">
                  <div class="d-flex align-items-center justify-content-center">
                    <h4 class="text-primary"> ESCOLHA O TIPO DE CADASTRO </h4>
                  </div>
                  
                  <div class="row">
                    <div class="card-register col-6">
                      <soft-button
                        color="primary"
                        variant="gradient"
                        full-width
                        @click="Contribuinte"
                        class="button-pf"
                        >
                        <span>
                          <i class="fa-solid fa-user-tie text-white" style="font-size: 80px;"></i>
                          <br>
                          Contribuinte
                        </span>
                      </soft-button>
                    </div>
                    
                    <div class="card-register col-6">
                      <soft-button
                        color="primary"
                        variant="gradient"
                        full-width
                        @click="Contador"
                        class="button-pf"
                        >
                        <span>
                          <i class="fa-solid fa-user-group text-white" style="font-size: 80px;"></i>
                          <br>
                          Contador
                        </span>
                      </soft-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                  src="../../../assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0"
                />
                <div class="position-relative">
                  <img
                    class="max-width-500 w-100 position-relative z-index-2"
                    src="../../../assets/img/illustrations/rocket-white.png"
                    alt="rocket"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Sua jornada começa aqui
                </h4>
                <p class="text-white">
                  Assim como é preciso uma empresa para sustentar um produto, é preciso uma
                  comunidade para sustentar um protocolo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <main v-else>
    <section>
      <div class="container" style="margin-top: 6%;">
        <div class="d-flex align-items-center" style="margin-bottom: 2%;">
          <button @click="back" class="mr-auto btn bg-gradient-primary" style="margin: 0;">Voltar</button>
          <h3 class="mx-auto" >Cadastro de {{ titulo }}</h3>
        </div>
          <div>
            <div class="row">
              <div class="col-4">
                <label>Inscrição Estadual</label>
                <input
                  id="inscrição_estadual_register"
                  v-model="user.inscrição_estadual"
                  class="form-control input-register"
                  type="text"
                  placeholder="Inscrição Estadual"
                  name="inscrição_estadual_register"
                />
              </div>
              <div class="col-4">
                <label> 
                  CNPJ 
                  <span style="color: red;">*</span>
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    id="cnpj_register"
                    name="cnpj_register"
                    class="form-control"
                    placeholder="CNPJ"
                    v-model="user.cnpj"
                    v-maska
                    data-maska="##.###.###/####-##"
                  />
                  <button @click="buscarCnpj" class="input-group-text text-white bg-gradient-primary" type="button"
                    ><i class="fas fa-search" aria-hidden="true"></i
                  ></button>
                </div>
                
              </div>
              <div class="col-4">
                <label>Razão Social <span style="color: red;">*</span></label>
                <input
                  id="razao_social_register"
                  v-model="user.razao_social"
                  class="form-control input-register"
                  type="text"
                  placeholder="Razão Social"
                  name="razao_social_register"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label>Nome Fantasia</label>
                <input
                  id="nome_fantasia_register"
                  v-model="user.nome_fantasia"
                  class="form-control input-register"
                  type="text"
                  placeholder="Nome Fantasia"
                  name="nome_fantasia_register"
                />
              </div>
             
              <div class="col-4">
                <label>Telefone </label>
                <input
                  id="telefone_register"
                  v-model="user.telefone"
                  class="form-control input-register"
                  type="text"
                  placeholder="Telefone"
                  name="telefone_register"
                />
              </div>
              <div class="col-4">
                <label>Email <span style="color: red;">*</span></label>
                <input
                  id="email_register"
                  v-model="user.email"
                  class="form-control input-register"
                  type="email"
                  placeholder="Email"
                  name="email_register"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label>CEP</label>
                <div class="input-group">
                  <input
                    id="cep_register"
                    name="cep_register"
                    type="text"
                    class="form-control"
                    placeholder="CEP"
                    v-model="user.cep"
                    v-maska
                    data-maska="#####-###"
                    
                  />
                  <button @click="buscarCep" class="input-group-text text-white bg-gradient-primary" type="button"
                    ><i class="fas fa-search" aria-hidden="true"></i
                  ></button>
                </div>
              </div>
              <div class="col-5">
                <label>Endereço</label>
                <input
                  id="endereco_register"
                  v-model="user.logradouro"
                  @input="converterParaMaiusculas('logradouro')"
                  class="form-control input-register"
                  type="text"
                  placeholder="Endereço"
                  name="endereco_register"
                />
              </div>
              <div class="col-1">
                <label>Número</label>
                <input
                  id="numero_register"
                  v-model="user.numero_logradouro"
                  class="form-control input-register"
                  type="number"
                  placeholder="Nº"
                  name="numero_register"
                />
              </div>
              <div class="col-3">
                <label>Complemento</label>
                <input
                  id="complemento_register"
                  v-model="user.complemento_logradouro"
                  @input="converterParaMaiusculas('complemento')"
                  class="form-control input-register"
                  type="text"
                  placeholder="Complemento"
                  name="complemento_register"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label>Bairro</label>
                <input
                  id="bairro_register"
                  v-model="user.bairro"
                  @input="converterParaMaiusculas('bairro')"
                  class="form-control input-register"
                  type="text"
                  placeholder="Bairro"
                  name="bairro_register"
                />
              </div>
              <div class="col-3">
                <label>Município</label>
                <input
                  id="municipio_register"
                  v-model="user.municipio"
                  @input="converterParaMaiusculas('municipio')"
                  class="form-control input-register"
                  type="text"
                  placeholder="Município"
                  name="municipio_register"
                />
              </div>
              <div class="col-1">
                <label>UF</label>
                <input
                  id="uf_register"
                  v-model="user.uf"
                  @input="converterParaMaiusculas('uf')"
                  class="form-control input-register"
                  type="text"
                  placeholder="UF"
                  name="uf_register"
                />
              </div>
              <div class="col-3">
                <label>Capital Social</label>
                <input
                  id="capital_social_register"
                  v-model="user.capital_social"
                  class="form-control input-register"
                  type="text"
                  placeholder="Capital Social"
                  name="capital_social_register"
                />
              </div>
              <div class="col-2">
                <label>Data Situação</label>
                <input
                  id="data_situacao_register"
                  v-model="user.data_situacao_cadastral"
                  class="form-control input-register"
                  type="date"
                  placeholder="Data Situação"
                  name="data_situacao_register"
                />
              </div>
            </div>
            <div class="row">
              
              <div class="col-4">
                <label>Natureza Jurídica</label>
                <input
                  id="natureza_juridica_register"
                  v-model="user.natureza_juridica"
                  class="form-control input-register"
                  type="text"
                  placeholder="Natureza Jurídica"
                  name="natureza_juridica_register"
                />
              </div>
              <div class="col-2">
                <label>Porte</label>
                <input
                  id="porte_register"
                  v-model="user.porte"
                  class="form-control input-register"
                  type="text"
                  placeholder="Porte"
                  name="porte_register"
                />
              </div>
            
              <div class="col-3">
                <label>Abertura</label>
                <input
                  id="abertura_register"
                  v-model="user.abertura"
                  class="form-control input-register"
                  type="date"
                  placeholder="Abertura"
                  name="abertura_register"
                />
              </div>
              <div class="col-3">
                <label>Última Atualização</label>
                <input
                  id="ultima_atualizacao_register"
                  v-model="user.ultima_atualizacao"
                  class="form-control input-register"
                  type="date"
                  placeholder="Última Atualização"
                  name="ultima_atualizacao_register"
                />
              </div>
              
            </div>
            <div class="row">
              <div class="col-3">
                <label>Situação</label>
                <input
                  id="situacao_register"
                  v-model="user.situacao_cadastral"
                  class="form-control input-register"
                  type="text"
                  placeholder="Situação"
                  name="situacao_register"
                />
              </div>
              <div class="col-3">
                <label>Tipo</label>
                <input
                  id="tipo_register"
                  v-model="user.tipo"
                  class="form-control input-register"
                  type="text"
                  placeholder="Tipo"
                  name="tipo_register"
                />
              </div>
              
            
              <div class="col-6">
                <label>Atividade Principal</label>
                <div class="input-group">
                  <input
                    id="atividade_principal_cod_register"
                    v-model="user.atividade_principal_cod"
                    class="form-control input-register"
                    type="text"
                    placeholder="Código"
                    name="atividade_principal_cod_register"
                    v-maska
                    data-maska="####-#/##"
                  />
                  <input
                    id="atividade_principal_register"
                    v-model="user.atividade_principal"
                    class="form-control input-register w-60"
                    type="text"
                    placeholder="Atividade Principal"
                    name="atividade_principal_register"
                  />
                  
                </div>
              </div>
              
            </div>
            <button class="btn bg-gradient-primary" @click="addInputAtividade" style="word-spacing: 3px;">
              Adicionar Atividade Secundária
            </button>
            <div class="row">
              <div v-for="(input, index) in user.inputsAtividades" :key="index" class="col-6">

                <label>Atividade Secundária {{ index + 1 }}</label>
                <br>
                <div class="input-group">
                  <input
                  id="codigo_register"
                  name="codigo_register"
                  v-model="user.inputsAtividadesCod[index]"
                  type="text"
                  class="form-control"
                  placeholder='Código '
                  v-maska
                  data-maska="####-#/##"
                  >
                  <input
                  v-model="user.inputsAtividades[index]"
                  type="text"
                  class="form-control w-60"
                  placeholder=" Descrição "
                  >
                <button @click="removeInputAtividade(index)" class="input-group-text text-white bg-gradient-primary" type="button"
                ><i class="fas fa-close" aria-hidden="true"></i
                ></button>

                </div>
                
              </div>
            </div>
            <button class="btn bg-gradient-primary" @click="addInputQSA" style="word-spacing: 3px; margin-top: 1%;">
              Adicionar Sócio/Administrador
            </button>
            <div class="row">
              <div v-for="(input, index) in user.inputQsaNome" :key="index" class="col-6">

                <label>Sócio / Administrador {{ index + 1 }}</label>
                <br>
                <div class="input-group">
                  <input
                  v-model="user.inputQsaNome[index]"
                  type="text"
                  class="form-control w-50"
                  placeholder="Nome"
                >
                  <input
                  v-model="user.inputQsaQual[index]"
                  type="text"
                  class="form-control"
                  placeholder=" Qualificação"
                  >
                <button @click="removeInputQSA(index)" class="input-group-text text-white bg-gradient-primary" type="button"
                ><i class="fas fa-close" aria-hidden="true"></i
                ></button>

                </div>

              </div>
            </div>
            <div class="row" style="margin-top: 2%">
              <div class="col-6">
                <label>Senha <span style="color: red;">*</span> </label>
                <div class="input-group">
                  <input
                    id="password"
                    v-model="user.password"
                    :type="showPassword ? 'text' : 'password'"
                    @input="checkPasswordStrength('password')" 
                    placeholder="Senha"
                    class="form-control"
                    @click="isFirstTouch = true"
                    name="password"
                  />
                  <button @click="showPassword=!showPassword" class="input-group-text text-white bg-gradient-primary" type="button"
                  ><i class="fas fa-eye" aria-hidden="true"></i
                  ></button>
                  </div>
                <password-check-result :isFirstTouch="isFirstTouch" ref="PasswordChild">

                </password-check-result>
              </div>
              <div class="col-6">
                <label>Digite novamente a senha <span style="color: red;">*</span></label>
                <div class="input-group">
                  <input
                    id="password_confirmation"
                    v-model="user.password_confirmation"
                    :type="showPasswordConfirmation ? 'text' : 'password'"
                    class="form-control"
                    placeholder="Digite novamente a senha"
                    name="password_confirmation"
                  />
                  <button @click="showPasswordConfirmation=!showPasswordConfirmation" class="input-group-text text-white bg-gradient-primary" type="button"
                  ><i class="fas fa-eye" aria-hidden="true"></i
                  ></button>
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 2%">
              <soft-check-model
                id="agreed_register"
                v-model="user.agreed"
                @click="user.agreed = !user.agreed"
                name="agreed_register"
                class="font-weight-light terms"
              >
              Eu concordo com os
                <a href="#" class="text-primary font-weight-bolder"
                  >Termos e Condições!</a
                ><span style="color: red; "><b> *</b></span>
              </soft-check-model>
              <div>
                <span
                  v-if="showError"
                  role="alert"
                  class="error-feedback text-xs text-danger"
                  >Por favor, aceite os Termos e Condições!</span
                >
                <span v-else> &nbsp;</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex align-items-center justify-content-center h-100">
                <soft-button
                  color="primary"
                  variant="gradient"
                  class="mt-2 mb-2"
                  style="width: 30%;"
                  :is-disabled="loading ? true : false"
                  @click="Register"
                >
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <span v-else>Registrar</span>
                </soft-button>
              </div>
            </div>
          </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>

import axios from 'axios';
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftCheckModel from "@/components/SoftCheckModel.vue";
import SoftButton from "@/components/SoftButton.vue";
import showSwal from "/src/mixins/showSwal.js";
import maskCpfCnpj from "@/assets/js/maskCpfCnpj.js";
import consultaCNPJ from '@/assets/js/consulta-cnpj.js';
import consultaCEP from '@/assets/js/consulta-cep.js';
const body = document.getElementsByTagName("body")[0];
import passwordCheckResult from '../../../components/PasswordStrengthChecker.vue';

import { mapMutations } from "vuex";
export default {
  name: "RegisterComponent",
  components: {
    Navbar,
    AppFooter,
    SoftCheckModel,
    SoftButton,
    passwordCheckResult,
  },
  data() {
    const user = {
      razao_social: "",
      cnpj: "59546515000134",
      nome_fantasia: "",
      telefone: "",
      email: "",
      cep: "",
      logradouro: "",
      numero_logradouro: "",
      complemento_logradouro: "",
      bairro: "",
      municipio: "",
      uf: "",
      data_situacao_cadastral: "",
      atividade_principal: "",
      atividade_principal_cod: "",
      natureza_juridica: "",
      porte: "",
      abertura: "",
      ultima_atualizacao: "",
      situacao_cadastral: "",
      tipo: "",
      inscrição_estadual: "34886525000198",
      password: "dfafsa232###ssdddAA",
      password_confirmation: "dfafsa232###ssdddAA",
      agreed: false,
      tipo_user: null,
      id_cidade: null,
      inputsAtividades: [],
      inputsAtividadesCod: [],
      inputQsaNome: [],
      inputQsaQual: []
    };
    return {
      loading: false,
      layout: true,
      user,
      showError: false,
      isFirstTouch: false,
      path_cidade: null,
      cidade: [],
      
      titulo: "",
      showPassword: false,
      showPasswordConfirmation: false,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
    this.path_cidade = this.$route.params.path_cidade;
    if ((localStorage.getItem('path_cidade') == this.path_cidade) && (localStorage.getItem('id_cidade') != null) && (localStorage.getItem('nome_cidade') != null)){
      this.cidade['id'] = localStorage.getItem('id_cidade');
      this.cidade['path'] = localStorage.getItem('path_cidade');
      this.cidade['nome'] = localStorage.getItem('nome_cidade');
      this.user.id_cidade = this.cidade['id'];
    } else {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/cidades/${this.path_cidade}`)
        .then(response => {
          try {
            if (response.data['id'] == null || response.data['id'] == undefined) {
              this.$router.push("/error");
            }
            if (response.data['path'] == null || response.data['path'] == undefined) {
              this.$router.push("/error");
            }
            this.cidade = response.data;
            this.user.id_cidade = this.cidade['id'];
            localStorage.setItem('id_cidade', response.data['id']);
            localStorage.setItem('path_cidade', response.data['path']);
            localStorage.setItem('nome_cidade', response.data['nome']);
          } catch (error) {     
            this.$router.push("/error");
          }
        })
        .catch(() => {   
          this.$router.push("/error");
        });
    }
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async Register() {
      const validateField = (field, errorMessage, validationFunction = null) => {
        if (this.user[field] == null || this.user[field] == undefined || this.user[field] == "") {
          showSwal.methods.showSwal({
            type: "error",
            message: errorMessage,
            width: 500,
          });
          return false;
        }

        if (validationFunction && !validationFunction(this.user[field])) {
          showSwal.methods.showSwal({
            type: "error",
            message: errorMessage,
            width: 500,
          });
          return false;
        }

        return true;
      };

      if (
        !validateField("cnpj", "Por favor, informe um CNPJ para buscar.")
        || !validateField("razao_social", "Por favor, informe a Razão Social.")
        || !validateField("email", "Por favor, informe o Email.")
        || !validateField("password", "Por favor, informe a Senha.")
        || !validateField("password_confirmation", "Por favor, informe a Confirmação da Senha.")
        || !validateField("agreed", "Por favor, Concorde com os termos e condições.")
      ) {
        return;
      }

      if (this.user.password !== this.user.password_confirmation) {
        showSwal.methods.showSwal({
          type: "error",
          message: "A Senha e a Confirmação da Senha não conferem.",
          width: 500,
        });
        return;
      }
      this.user.cnpj = maskCpfCnpj.pop(this.user.cnpj)
      if (this.user.cnpj.length != 14) {
        showSwal.methods.showSwal({
          type: "error",
          message: "O CNPJ informado não é válido.",
          width: 500,
        });
        return;
      }

      if (this.user.email.indexOf('@') == -1 || this.user.email.indexOf('.') == -1){
        showSwal.methods.showSwal({
          type: "error",
          message: "'O e-mail informado não é válido.",
          width: 500,
        });
        return;
      }

      this.user.atividade_principal_cod = maskCpfCnpj.pop(this.user.atividade_principal_cod)
      this.user.cep = maskCpfCnpj.pop(this.user.cep)
      this.user.inscrição_estadual = maskCpfCnpj.pop(this.user.inscrição_estadual)
      this.user.telefone = maskCpfCnpj.pop(this.user.telefone)
      for (let index = 0; index < this.user.inputsAtividadesCod.length; index++) {
        this.user.inputsAtividadesCod[index] = maskCpfCnpj.pop(this.user.inputsAtividadesCod[index])
      }
      const passwordCheckResult = this.$refs.PasswordChild.checkPassword(this.user.password);
      if (passwordCheckResult) {
        this.loading = false;
        showSwal.methods.showSwal({
          type: "error",
          message: `A senha não atende ao critério: ${passwordCheckResult}.`,
          width: 500,
        });
        return;
      }
      this.loading = true;
      this.showError = false;
      
      try {
        await this.$store.dispatch("auth/register", this.user);
        const response = JSON.parse(localStorage.getItem("register"));
        localStorage.removeItem("register");
        if (response.status == 'success'){
          showSwal.methods.showSwal({
            type: "success",
            message: "Cadastro realizado com sucesso! Espere seu cadastro ser homologado.",
            width: 700,
            timer: 5000,
          });
          let path_cidade = localStorage.getItem("path_cidade");
          this.$router.push(`/${path_cidade}/login`);
        } else {
          showSwal.methods.showSwal({
            type: "error",
            message: response.message,
            width: 500,
          });
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    }, 
    converterParaMaiusculas(fieldName) {
      this.user[fieldName] = this.user[fieldName].toUpperCase();
    },
    addInputAtividade() {
      this.user.inputsAtividades.push('');  
      this.user.inputsAtividadesCod.push('');
    },
    addInputQSA(){
      this.user.inputQsaNome.push('');
      this.user.inputQsaQual.push('');
    },
    removeInputQSA(index) {
      this.user.inputQsaNome.splice(index, 1);
      this.user.inputQsaQual.splice(index, 1);
      
    },
    removeInputAtividade(index) {
      this.user.inputsAtividades.splice(index, 1);
      this.user.inputsAtividadesCod.splice(index, 1);
    },
    formatDate2(inputDate) {
      const date = new Date(inputDate);
      if (isNaN(date.getTime())) {
        return null; 
      }
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    formatDate(inputDate) {
      const parts = inputDate.split('/');

      const year = parts[2];  
      const month = parts[1]; 
      const day = parts[0];  

      const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      return formattedDate;
    },
    Contador(){
      this.titulo = 'Contador'
      this.user.tipo_user = 2
      this.layout = false
    },
    Contribuinte(){
      this.titulo = 'Contribuinte'
      this.user.tipo_user = 1
      this.layout = false
    },
    buscarCep(){
      consultaCEP(this.user.cep.replace(/\D/g, ''))
      .then(dados => {
        if (dados['cep']) {
          this.user.logradouro = dados['logradouro'].toUpperCase();
          this.user.bairro = dados['bairro'].toUpperCase();
          this.user.municipio = dados['localidade'].toUpperCase();
          this.user.uf = dados['uf'].toUpperCase();
        } else {
          showSwal.methods.showSwal({
            type: "error",
            message: `CEP não encontrado.`,
            width: 500,
          });
        }
        
      })
    },
    buscarCnpj(){
      if (this.user.cnpj == null || this.user.cnpj == undefined || this.user.cnpj == "") {
        showSwal.methods.showSwal({
          type: "error",
          message: `Por favor, informe um CNPJ para buscar.`,
          width: 500,
        });
        return;
      }
      consultaCNPJ(this.user.cnpj.replace(/\D/g, ''))
      .then(dados => {
        if (dados['status'] == 'ERROR') {
          showSwal.methods.showSwal({
              type: "error",
              message: `CNPJ não encontrado.`,
              width: 500,
          });
        
        return;
        }
        this.user.razao_social = dados['nome'];
        this.user.nome_fantasia = dados['fantasia'];
        this.user.telefone = dados['telefone'];
        this.user.email = dados['email'];
        this.user.cep = dados['cep'].replace(/\D/g, '');
        this.user.logradouro = dados['logradouro'];
        this.user.numero_logradouro = dados['numero_logradouro'];
        this.user.complemento_logradouro = dados['complemento_logradouro'];
        this.user.bairro = dados['bairro'];
        this.user.municipio = dados['municipio'];
        this.user.uf = dados['uf'];
        this.user.capital_social = dados['capital_social'];
        this.user.data_situacao_cadastral = this.formatDate(dados['data_situacao_cadastral']); 
        this.user.atividade_principal = dados['atividade_principal'][0]['text'];
        this.user.atividade_principal_cod = dados['atividade_principal'][0]['code'].replace(/\D/g, '');
        this.user.natureza_juridica = dados['natureza_juridica'];
        this.user.porte = dados['porte'];
        this.user.abertura = this.formatDate(dados['abertura']);
        this.user.ultima_atualizacao = this.formatDate2(dados['ultima_atualizacao']);
        this.user.situacao_cadastral = dados['situacao_cadastral'];
        this.user.tipo = dados['tipo'];
        const atividades = dados['atividades_secundarias'];
        this.user.inputsAtividades=[];
        this.user.inputsAtividadesCod=[];
        for (let index = 0; index < atividades.length; index++) {
          this.user.inputsAtividades.push(atividades[index]['text']);
        }
        for (let index = 0; index < atividades.length; index++) {
          this.user.inputsAtividadesCod.push(atividades[index]['code'].replace(/\D/g, ''));
        }
        const QSAs = dados['qsa'];
        this.user.inputQsaNome=[];
        for (let index = 0; index < QSAs.length; index++) {
          this.user.inputQsaNome.push(QSAs[index]['nome']);
        }
        this.user.inputQsaQual=[];
        for (let index = 0; index < QSAs.length; index++) {
          this.user.inputQsaQual.push(QSAs[index]['qual']);
        }
        
      })
      .catch(error => {
        console.error(error);
      });
    },
    back(){
      this.layout = true
      this.user = {
        razao_social: "",
        cnpj: "",
        nome_fantasia: "",
        telefone: "",
        email: "",
        cep: "",
        logradouro: "",
        numero_logradouro: "",
        complemento_logradouro: "",
        bairro: "",
        municipio: "",
        uf: "",
        data_situacao_cadastral: "",
        atividade_principal: "",
        atividade_principal_cod: "",
        natureza_juridica: "",
        porte: "",
        abertura: "",
        ultima_atualizacao: "",
        situacao_cadastral: "",
        tipo: "",
        inscrição_estadual: "",
        password: "",
        password_confirmation: "",
        agreed: false,
        tipo_user: null,
        id_cidade: null,
        inputsAtividades: [],
        inputsAtividadesCod: [],
        inputQsaNome: [],
        inputQsaQual: []
      };
      
      this.loading = false;
      this.showError = false;
      this.isFirstTouch = false;
      this.showPassword = false;
      this.showPasswordConfirmation = false;

    },
    checkPasswordStrength(input_id) {
      this.$nextTick(() => {
          if (this.$refs.PasswordChild) {
            this.$refs.PasswordChild.checkPasswordStrength(input_id);
          }
      });
    },
  },
};
</script>
<style >
.terms {
  font-size: 14px;
}

.button-pf{
  height: 220px;
  width: 100% !important;
  margin-top: 10% !important;

}

.card-register{
  padding-left: 10% !important;
  margin-top: 5% !important;
  
}

.input-group{
  width: 100% !important;
  
}
.input-register{
  margin-bottom: 5% !important;
}


</style>
